import { Box, Breadcrumbs, Button, Chip, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import Loader from "../Reusable/Loader";
import { GET_TRAINING_SESSION } from '../../constants';
import TrainingSessionTable from './TrainingSessionTable';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';

const TrainingSessionComponent = ({}) => {

    const [loading ,setLoading] = useState(false);
    const [sessionList, setSessionList] = useState([]);

    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }

      const toast = React.useRef(null);

      const showSuccess = (content) => {
          toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
      }
  
      const showError = (content) => {
          toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
      }

      const content = (
        <div className="flex align-items-center">
            {/* <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" width="32" /> */}
            <div className="ml-2">No Sessions Yet.</div>
        </div>
    );

    const [runEffect, setRunEffect] = useState(false);
      useEffect(()=>{
        setLoading(true);
        axios.get(GET_TRAINING_SESSION)
        .then(response => {
               const {data} = response.data;
               handleRowData(data);
               setLoading(false);
        })
        .catch(error => {
            setLoading(false);
        });
    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": rd.id ,
            "SNo": index + 1,
            "title":rd.title === '' ? '-' : rd.title,
            "trainerName": rd.trainerName,
            "trainingDate": rd.trainingDate == '' ? '-' : formatDate(rd.trainingDate),
            "locationID": rd.locationID == '' ? '-' : rd.locationID,
            "venueID": rd.venueID == '' ? '-' : rd.venueID,
            "isMandatory": rd.isMandatory == true ? 'Yes' : 'No',
            "certificateID": rd.certificateID,
            "courseID": rd.courseID,
            "createdBy": rd.createdBy,
            "duration": rd.duration,
            "journeyID": rd.journeyID,
            "lastUpdatedBy": rd.lastUpdatedBy,
            "lastUpdatedOn": rd.lastUpdatedOn,
            "launchOn": rd.launchOn,
            "maxParticipants": rd.maxParticipants,
            "minParticipants": rd.minParticipants,
            "objective": rd.objective,
            "participantsEnrolledCount": rd.participantsEnrolledCount,
            "postAssessmentID": rd.postAssessmentID,
            "preAssessmentID": rd.preAssessmentID,
            "programID": rd.programID,
            "selfNominationEnabled": rd.selfNominationEnabled,
            "selfNominationEndDate": rd.selfNominationEndDate,
            "toTime": rd.toTime,
            "type": rd.type,
        }
        transformedRows.push(a);
        });
        setSessionList(transformedRows);
    };

    const formatDate = (dateString) => moment(dateString).format('MMMM D, YYYY');

    const handleAddClick = () => {
        navigate('/trainingSession/add');
    };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                    Home
                </Link>
                <Typography key="2" color="text.primary">
                    Training Session
                </Typography>
            </Breadcrumbs>

            <div>
                <Button variant='contained' startIcon={<AddIcon></AddIcon>} onClick={handleAddClick}>Add Training Session</Button>
            </div>
            </Box>

            {sessionList.length === 0 ? (
            <div className="mt-2">
                <Message
                    style={{
                        border: 'solid #696cff',
                        borderWidth: '0 0 0 6px',
                        color: '#696cff',
                        justifyContent: 'flex-start'
                    }}
                    className="border-primary w-full"
                    severity="info"
                    content={content}
                />
            </div>
            ):
            (
                <div className={`mt-2`}>
                <TrainingSessionTable sessionList ={sessionList} showSuccess={showSuccess} showError={showError} setRunEffect={setRunEffect} userId={user.employeeID}  ></TrainingSessionTable>
                </div>
            )}
            </>
    );
}

export default TrainingSessionComponent;