import { Box, Breadcrumbs, Chip, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import courseStyles from './courseStyles.module.css';
import UploadedContentCardDesignComponent from './UplodedContentCardDesign';
import empty_pic from '../../Logo/empty_pic.png';


const UploadedContentCardViewComponent = ({ content }) => {
    
    return(
        <>
        {content.length === 0 ? (
        <Box sx={{width: '100%', height: '100%', border: '1px solid', borderStyle: 'groove', borderRadius: '5px'}}>
            <img
            src={empty_pic}
            loading="lazy"
            alt='empty'
            
            ></img>
            </Box>
        ) : (
            <UploadedContentCardDesignComponent content={content} type={'add'}></UploadedContentCardDesignComponent>
            
        )} 
        </>
    );
}

export default UploadedContentCardViewComponent;
