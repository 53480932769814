import React from 'react';
import './loader.css';
import { CircularProgress, Box ,Backdrop} from '@mui/material';

const Loader = ({open }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 1200 }}
      open={open}
    >
    <CircularProgress color="primary"/>
  </Backdrop>
  );
};

export default Loader;