import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginComponent from '../LoginComponent/LoginComponent';
import MainComponent from '../MainComponent';


const routes = createBrowserRouter ([
                  {   
                    path: '/', 
                    element: <LoginComponent />
                  },
                  {
                    path: '/*', 
                    element: <MainComponent />
                  }
                ]);

function Body() {
  return (
    <>
 
    <RouterProvider router={routes}></RouterProvider> 

    </>
  );
}

export default Body;