import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

const LongTextComponent = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  return (
    <Box>
      <Typography variant="body2" component="span" sx={{fontSize: '0.85rem'}}>
        {isExpanded ? text : truncateText(text, maxLength)}
      </Typography>
      {text.length > maxLength && (
        <Button variant="text" onClick={toggleText} size="small" sx={{fontSize: '0.65rem'}}>
          {isExpanded ? 'Show Less' : 'Read More'}
        </Button>
      )}
    </Box>
  );
};

export default LongTextComponent;