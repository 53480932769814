import React from "react";
import { useSelector } from 'react-redux';
import SidebarMenu from "./Sidebar";
import DashboardComponent from "./Dashboard/DashboardComponent";
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Typography } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import JourneyComponent from "./Journey/JourneyComponent";
import AddJourneyComponent from "./Journey/AddJourneyComponent";
import ProgramComponent from "./Program/ProgramComponent";
import AddProgramComponent from "./Program/AddProgramComponent";
import CourseComponent from "./Courses/CourseComponent";
import EditProgramComponent from "./Program/EditProgramComponent";
import UserProfileMappingComponent from "./UserProfileMapping/UserProfileMappingComponent";
import CreateMappingComponent from "./UserProfileMapping/CreateMappingComponent";
import EditMappingComponent from "./UserProfileMapping/EditMappingComponent";
import AddCourseComponent from "./Courses/AddCourseComponent";
import EditCourseComponent from "./Courses/EditCourseComponent";
import CoursePreviewComponent from "./Courses/CoursePreviewComponent";
import TrainingSessionComponent from "./TrainingSession/TrainingSessionComponent";
import AddTrainingSessionComponent from "./TrainingSession/AddTrainingSession";
import EditTrainingSessionComponent from "./TrainingSession/EditTrainingSession";
import AssessmentComponent from "./AssessmentModue/AssessmentComponent";
import AddAssessmentComponent from "./AssessmentModue/AddAssessmentComponent";

function MainComponent() {
    const user = useSelector((state) => state.auth.user);
    return (
      <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
            <SidebarMenu />
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
        {/* <DrawerHeader /> */}
        <Toolbar />
        <Routes>
              <Route path="/dashboard" element={<DashboardComponent />} />
              <Route path="/journey" element={<JourneyComponent />} />
              <Route path="/program" element={<ProgramComponent />} />
              <Route path="/program/add" element={<AddProgramComponent />} />
              <Route path="/program/edit" element={<EditProgramComponent />} />
              <Route path="/courses" element={<CourseComponent />} />
              <Route path="/courses/add" element={<AddCourseComponent />} />
              <Route path="/courses/edit" element={<EditCourseComponent />} />
              <Route path="/courses/view" element={<CoursePreviewComponent />} />
              <Route path="/configuration/userMapping" element={<UserProfileMappingComponent />} />
              <Route path="/configuration/userMapping/add" element={<CreateMappingComponent />} />
              <Route path="/configuration/userMapping/edit" element={<EditMappingComponent />} />
              <Route path="/trainingSession" element={<TrainingSessionComponent />} />
              <Route path="/trainingSession/add" element={<AddTrainingSessionComponent />} />
              <Route path="/trainingSession/edit" element={<EditTrainingSessionComponent />} />
              <Route path="/assessment" element={<AssessmentComponent />} />
              <Route path="/assessment/add" element={<AddAssessmentComponent />} />
        </Routes>
        </Box>
     </Box>
      </>
    );
}

export default MainComponent;