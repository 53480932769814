import React, { useEffect, useState } from "react";
import Loader from "../Reusable/Loader";
import { Toast } from "primereact/toast";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { Message } from 'primereact/message';
import { useSelector } from "react-redux";
import FormCardComponent from "./FormCardComponent";


function AddAssessmentComponent() {

    const [loading ,setLoading] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }

      const toast = React.useRef(null);

      const showSuccess = (content) => {
          toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
      }
  
      const showError = (content) => {
          toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
      }

    

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
           
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                    Home
                </Link>
                <Link underline="hover" key="1" color="inherit" to="/assessment" onClick={handleClick}>
                    Assessment
                </Link>
                <Typography key="2" color="primary">
                    Add Assessment
                </Typography>
            </Breadcrumbs>

            <FormCardComponent showSuccess={showSuccess} showError={showError} setLoading={setLoading} ></FormCardComponent>
           
        </>
    );

}

export default AddAssessmentComponent;