import './App.css';
import Body from './Components/Routes/Body';
import { store, persistor } from './store';
import { styled, useTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';

function App() {
  
  return(
    <>
     <Provider store={store}>
      <Body />
      </Provider>
    </>
  );
}

export default App;
