import { Box, Breadcrumbs, Button, Chip, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { GET_USER_PROFILE_MAPPING } from '../../constants';
import UserMappingTable from './UserMappingTable';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Reusable/Loader';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

const UserProfileMappingComponent = ({}) => {

    const [mappingList, setMappingList] = useState([]);

    const user = useSelector((state) => state.auth.user);

    const navigate = useNavigate();

    const [loading ,setLoading] = useState(false);

    const toast = React.useRef(null);
 
     const showSuccess = (content) => {
         toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
     }
 
     const showError = (content) => {
         toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
     }
    
     const [runEffect, setRunEffect] = useState(false);

    useEffect(()=>{
        setLoading(true);
        axios.get(GET_USER_PROFILE_MAPPING)
        .then(response => {
               const {data} = response.data;
               handleRowData(data);

               setLoading(false);
        })
        .catch(error => {
        });
    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": rd.id ,
            "SNo": index + 1,
            "mappingId":'',
            "employeeID": rd.employeeID,
            "name": rd.name == '' ? '-' : rd.name,
            "profile": rd.profile == '' ? '-' : rd.profile,
            "location": rd.location == '' ? '-' : rd.location,
            "lastUpdatedOn": rd.lastUpdatedOn == '' ? '-' : formatDate(rd.lastUpdatedOn),
        }
        transformedRows.push(a);
        });
        setMappingList(transformedRows);
    };

    const formatDate = (dateString) => moment(dateString).format('MMMM D, YYYY, h:mm:ss A');

    const handleClickOpen = () =>{
        navigate('/configuration/userMapping/add');
    };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <Box component="section" className={`mb-2`}>
            <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            >
            <h5 className={`text-2xl font-bold`}>User Profile Mapping</h5>
            <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
            fontSize: '1rem',
            fontWeight: '700',
            borderWidth: '1.5px',
            marginBottom: '16px',
            float: 'right'
            }}
            >
            + Create Mapping
        </Button>

        </Stack>
        </Box>
        

        <UserMappingTable mappingList={mappingList} userId={user.employeeID} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} ></UserMappingTable>

        
        </>
    );
}

export default UserProfileMappingComponent;