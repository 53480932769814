import React, { useEffect, useState } from "react";
import Loader from "../Reusable/Loader";
import { Toast } from "primereact/toast";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { Message } from 'primereact/message';
import { useSelector } from "react-redux";
import { GET_ASSESSMENT_LIST } from "../../constants";
import moment from "moment";
import AssessmentTable from "./AssessmentTable";
import axios from "axios";


function AssessmentComponent() {

    const [loading ,setLoading] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }

      const toast = React.useRef(null);

      const showSuccess = (content) => {
          toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
      }
  
      const showError = (content) => {
          toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
      }

      const content = (
        <div className="flex align-items-center">
            {/* <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" width="32" /> */}
            <div className="ml-2">No Sessions Yet.</div>
        </div>
    );

    const handleAddClick = () => {
        navigate('/assessment/add');
    };

    const [assessmentList, setAssessmentList] = useState([]);

    const [runEffect, setRunEffect] = useState(false);
      useEffect(()=>{
        setLoading(true);
        axios.get(GET_ASSESSMENT_LIST)
        .then(response => {
               const {data} = response.data;
               handleRowData(data);
               setLoading(false);
        })
        .catch(error => {
            setLoading(false);
        });
    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": rd.id ,
            "SNo": index + 1,
            "name":rd.name === '' ? '-' : rd.name,
        }
        transformedRows.push(a);
        });
        setAssessmentList(transformedRows);
    };

    const formatDate = (dateString) => moment(dateString).format('MMMM D, YYYY');

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
            <Box sx={{display:'flex', justifyContent:'space-between', marginBottom: '1rem'}}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                    Home
                </Link>
                <Typography key="2" color="primary">
                    Assessment
                </Typography>
            </Breadcrumbs>

            <div>
                <Button variant='contained' startIcon={<AddIcon></AddIcon>} onClick={handleAddClick}>Add Assessment</Button>
            </div>
            </Box>

            <AssessmentTable assessmentList={assessmentList} showSuccess={showSuccess} showError={showError} ></AssessmentTable>
        </>
    );

}

export default AssessmentComponent;