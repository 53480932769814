import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editData: null,
  successData: null,
  previewData: null
};

const courseDataSlice = createSlice({
  name: 'editData',
  initialState,
  reducers: {
    setEditData(state, action) {
      state.editData = action.payload;
    },
    setSuccessData(state, action) {
      state.successData = action.payload;
    },
    setpreviewData(state, action) {
      state.previewData = action.payload;
    },
    clearEditData(state) {
      state.editData = null;
    },
    clearSuccessData(state) {
      state.successData = null;
    },
    clearPreviewData(state) {
      state.previewData = null;
    },
  },
});

export const { setEditData, clearEditData, setSuccessData, clearSuccessData, setpreviewData, clearPreviewData } = courseDataSlice.actions;

export default courseDataSlice.reducer;