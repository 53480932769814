import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import dashboardStyles from './dashboardStyle.module.css';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

function DashboardComponent() {
    const user = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [hasShownSuccess, setHasShownSuccess] = useState(false);

    const toast = React.useRef(null);

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    

    useEffect(() => {
      const hasShownSuccess = sessionStorage.getItem('hasShownSuccess');
      
      if (isAuthenticated && !hasShownSuccess) {
          showSuccess('Login Successful');
          sessionStorage.setItem('hasShownSuccess', 'true');
      }
  }, [isAuthenticated]);

    return (
      <>
        <Toast ref={toast} />
        User: {user.employeeID}
      </>
    );
}

export default DashboardComponent;