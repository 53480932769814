import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ib_logo from '../Logo/IB_logo.png';
import { Collapse, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RouteIcon from '@mui/icons-material/Route';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AccountCircle, AccountCircleTwoTone, HistoryEdu } from '@mui/icons-material';
import MoreIcon from '@mui/icons-material/MoreVert';
import ArticleIcon from '@mui/icons-material/Article';
import SchoolIcon from '@mui/icons-material/School';
import { logout } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import './Sidebar.css';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#00A36C',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const pages = [
  {page: 'Home', icon: <HomeIcon sx={{fontSize: '1.2rem', marginRight: '5px', marginLeft: '5px'}}/> }, 
  {page: 'About IB Group', icon: <InfoIcon sx={{fontSize: '1.2rem', marginRight: '5px', marginLeft: '5px'}}/>}, 
  {page: 'Search', icon: <SearchIcon sx={{fontSize: '1.2rem', marginRight: '5px', marginLeft: '5px'}}/>}, 
  {page: 'Contact Us', icon: <PhoneIcon sx={{fontSize: '1.2rem', marginRight: '5px', marginLeft: '5px'}}/>}];


const SidebarMenu = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const path = window.location.pathname;

    const [submenuOpen, setSubmenuOpen] = useState(false);

    const handleSubmenuClick = () => {
        setSubmenuOpen(!submenuOpen);
    };
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const DrawerList = (
      <>
      {/* <Toolbar /> */}
      <Box sx={{ overflow: open ? 'auto' : 'hidden' ,   backgroundColor: '#fff', height: '100%'}}>
      <List>
          <ListItem key={'dashboard'} 
          button component={RouterLink} 
          to="/dashboard" 
          disablePadding 
          className={path == '/dashboard' ? `active_back active` : ``}
          sx={{ display: 'flex',
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
           }}>  
              <ListItemIcon
              className={ (path == '/dashboard' ? `active` : ``)}
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Dashboard" arrow>
                <SpeedIcon color='primary' />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
              </ListItem>


              <ListItem key={'journey'} 
                button component={RouterLink} 
                to="/journey" 
                disablePadding 
                className={path == '/journey' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>  
                    <ListItemIcon
                    className={ (path == '/journey' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Journey" arrow>
                      <RouteIcon sx={{transform: 'rotate(90deg)'}}  color='primary'/>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Journey'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                </ListItem>
  
                <ListItem key={'program'} 
                button component={RouterLink} 
                to="/program" 
                disablePadding 
                className={path == '/program' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>  
                    <ListItemIcon
                    className={ (path == '/program' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Program" arrow>
                      <ArticleIcon color='primary' />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Program'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                </ListItem>

                <ListItem key={'courses'} 
                button component={RouterLink} 
                to="/courses" 
                disablePadding 
                className={path == '/courses' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>  
                    <ListItemIcon
                    className={ (path == '/courses' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Courses" arrow>
                      <SchoolIcon color='primary' />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Courses'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                </ListItem>

                <ListItem key={'training_session'} 
                button component={RouterLink} 
                to="/trainingSession" 
                disablePadding 
                className={path == '/trainingSession' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>  
                    <ListItemIcon
                    className={ (path == '/trainingSession' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Training Session" arrow>
                      <EventIcon color='primary' />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Training Session'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                </ListItem>

                <ListItem key={'assessment_module'} 
                button component={RouterLink} 
                to="/assessment" 
                disablePadding 
                className={path == '/assessment' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>  
                    <ListItemIcon
                    className={ (path == '/assessment' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Assessment" arrow>
                      <AssessmentIcon color='primary' />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Assessment'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                </ListItem>

                <ListItem key={'settings'} 
                onClick={handleSubmenuClick}
                // button component={RouterLink} 
                // to="/courses" 
                disablePadding 
                // className={path == '/courses' ? `active_back active` : ``}
                sx={{ display: 'flex',
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  paddingRight: open ? '20px' : '5px'
                }}>  
                    <ListItemIcon
                    // className={ (path == '/courses' ? `active` : ``)}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Configuration" arrow>
                      <SettingsOutlinedIcon color='primary' />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Configuration'} sx={{ opacity: open ? 1 : 0 }} className={``}/>
                    {submenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
                    <List>
                        <ListItem button component={RouterLink} 
                        to="/configuration/userMapping" 
                        disablePadding
                        className={path == '/configuration/userMapping' ? `active_back active` : ``}
                         sx={{ display: 'flex',
                          minHeight: 48,
                          justifyContent: submenuOpen ? 'initial' : 'center',
                          px: open ? 4.5 : 3.5,
                        }}>
                        <ListItemIcon
                            className={ (path == '/configuration/userMapping' ? `active` : ``)}
                            sx={{
                              minWidth: 0,
                              mr: open ? 4 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <Tooltip title="User Mapping" arrow> 
                            <PersonPinCircleIcon color='primary'></PersonPinCircleIcon>
                            </Tooltip> 
                            <ListItemText primary="User Profile Mapping"  sx={{ opacity: open ? 1 : 0 , paddingLeft: '5px'}}/>
                        </ListItemIcon>
                        </ListItem>
                        {/* <ListItem button 
                        sx={{
                          minWidth: 0,
                          mr: submenuOpen ? 3 : 'auto',
                          justifyContent: 'center',
                        }}>
                            <ListItemText primary="Submenu Item 2" />
                            <CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon>
                        </ListItem> */}
                    </List>
                </Collapse>

               
          </List>
      </Box>
      </>
    );


    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const isLangMenuOpen = Boolean(langAnchorEl);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleLangMenuOpen = (event) => {
      console.log('here')
      setLangAnchorEl(event.currentTarget);
    };

    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleLangMenuClose = () => {
      setLangAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () =>{
        dispatch(logout());
        navigate('/');
        sessionStorage.removeItem('hasShownSuccess');
    };

    const langMenuId = 'language-menu';
    const renderLangMenu = (
      <Menu
        anchorEl={langAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={langMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isLangMenuOpen}
        onClose={handleLangMenuClose}
      >
        <MenuItem onClick={handleLangMenuClose}>English</MenuItem>
        <MenuItem onClick={handleLangMenuClose}>Hindi</MenuItem>
      </Menu>
    );

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleLangMenuOpen}>
          <IconButton
              size="large"
              aria-label="Language Icon"
              color="inherit"
              aria-controls='language-menu'
              aria-haspopup="true"
            >
                <LanguageIcon />
            </IconButton>
          <p>Language</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

  return (
        <>
      
        <AppBar position="fixed" open={open} >
        <Toolbar>

            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          
          {open == false && <img
              src={ib_logo}
              loading="lazy"
              alt='onCall'
              className={`w-10`}
          />}

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, marginLeft: '10px', fontWeight: '700' }}
        >
          IB Group
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.page}
                // onClick={handleCloseNavMenu}
                sx={{  color: 'white', backgroundColor: 'rgba(255, 255, 255, .4)',borderRadius: '20px', margin: '5px' }}
              >
                {page.icon}
                <Typography sx={{marginRight: '5px', marginLeft: '5px'}}>{page.page}</Typography>
              </Button>
            ))}
          </Box>

{/* <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            size="large"
            aria-label="Language Icon"
            color="inherit"
            aria-controls={langMenuId}
            aria-haspopup="true"
            onClick={handleLangMenuOpen}
          >
              <LanguageIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>

        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      {renderLangMenu}
        
        <Drawer variant={"permanent"} open={open}>
        <DrawerHeader sx={{placeContent: 'space-between' }}>
        {open == true && <img
              src={ib_logo}
              loading="lazy"
              alt='onCall'
              className={`w-10`}
          />}
          <IconButton onClick={handleDrawerClose} sx={{color: '#fff'}}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
          {DrawerList}
          
        </Drawer>
        </>
  );
};

export default SidebarMenu;