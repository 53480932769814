import { Box, Breadcrumbs, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, Tab, Tabs, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import Loader from '../Reusable/Loader';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import EditFormComponent from './EditFormComponent';
import { GET_JOURNEY_LIST, GET_LOCATION_LIST, GET_RECORDED_SESSION_LIST,  GET_PROGRAM_LIST } from '../../constants';
import { useSelector } from 'react-redux';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useTheme } from '@emotion/react';
import EditContentComponent from './EditContentComponent';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={isMobile ? 1 : 3}>
                    {children}
                </Box>
            )}
        </div>
    );
};


const EditCourseComponent = ({}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);

    const [loading ,setLoading] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [journeyList, setJourneyList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [recordedSessionList, setRecordedSessionList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const toast = useRef(null);


    useEffect(()=>{
        axios.get(GET_JOURNEY_LIST)
        .then(response => {
         const {data} = response.data;
         setJourneyList(data);
        })
        .catch(error => {
        });

        axios.get(`${GET_RECORDED_SESSION_LIST}?UserID=${user.employeeID}`)
        .then(response => {
         const {data} = response.data;
         setRecordedSessionList(data);
        })
        .catch(error => {
        });

        axios.get(GET_LOCATION_LIST)
        .then(response => {
               const {data} = response.data;
               setLocationList(data);
        })
        .catch(error => {
        });
        
        axios.get(GET_PROGRAM_LIST)
        .then(response => {
                const {data} = response.data;         
                setProgramList(data);            
        })
        .catch(error => {
        
        });
    },[]);

    
    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      };
      
    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Link underline="hover" key="3" color="inherit" to="/courses" onClick={handleClick}>
                Courses
            </Link>
            <Typography key="4" color="primary">
                Edit Course
            </Typography>
        </Breadcrumbs>

        <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant={isMobile ? 'scrollable' : 'standard'} // Scrollable tabs on mobile
        scrollButtons="auto"
        sx={{
          flexGrow: 1,
          '& .MuiTab-root': {
            minWidth: isMobile ? 80 : 100, // Adjust min-width for tabs on mobile
            fontSize: '0.875rem',
            padding: '6px 12px',
          },
          
        }}
      >
        <Tab label={`Course Info`} id="tab-0" icon={<InfoIcon />} iconPosition="start" aria-controls="tabpanel-1" />
        <Tab label={`Content`} id="tab-1" icon={<FilePresentIcon />} iconPosition="start" aria-controls="tabpanel-2" />
      </Tabs>

    <TabPanel value={value} index={0}>
    <EditFormComponent journeyList={journeyList} locationList={locationList} recordedSessionList={recordedSessionList} programList={programList} setValue={setValue} showError={showError} showSuccess={showSuccess} />
    </TabPanel>

    <TabPanel value={value} index={1}>
        <EditContentComponent showSuccess={showSuccess} showError={showError}></EditContentComponent>
    </TabPanel>
        
           
        
        </>
);
}

export default EditCourseComponent;