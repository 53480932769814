import React, { useState } from 'react';
import { Box, Card, CardMedia, Chip, IconButton, Typography, Divider, CardContent, CardActions, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import SideMenuComponent from './SideMenuComponent';
import moment from 'moment';
import card_img from '../../Logo/card_img.jpg';
import axios from 'axios';
import { DELETE_COURSE, DELETE_JOURNEY, EDIT_JOURNEY_GET, GET_EDIT_COURSE } from '../../constants';
import LongTextComponent from '../Reusable/LongTextComponent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch } from 'react-redux';
import { setEditData, setpreviewData } from '../../features/courseData/courseSlice';
import { useNavigate } from 'react-router-dom';

const CardComponent = ({ courseList, setRunEffect, setOpenEdit, setEditDetails, userId, showSuccess, showError,  }) => {

    const [currentCardId, setCurrentCardId] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleEditClick = () => {
        axios.get(`${GET_EDIT_COURSE}?courseID=${currentCardId}`)
            .then(response => {
                const { data } = response.data;
                setEditDetails(data);
                handleData(data);
                
            })
            .catch(error => {
                console.error("Error fetching journey details:", error);
            });
    };

    const handleData = (data) =>{
        dispatch(setEditData(data));
        navigate('/courses/edit');
    };

    const handleDeleteClick = () => {
        axios.post(`${DELETE_COURSE}?courseID=${currentCardId}&UserID=${userId}`)
            .then(response => {
                const { data, message } = response.data;
                showSuccess(message);
                
                setRunEffect(prev => !prev);  // Toggle the state
                
            })
            .catch(error => {
                console.error("Error fetching journey details:", error);
                
            });
    };

    const handleViewClick = () => {
        axios.get(`${GET_EDIT_COURSE}?courseID=${currentCardId}`)
        .then(response => {
            const { data } = response.data;
            dispatch(setpreviewData(data))
            navigate("/courses/view");
            
        })
        .catch(error => {
            console.error("Error fetching journey details:", error);
        });
        
    };

    const formatDate = (dateString) => moment(dateString).format('MMMM D, YYYY, h:mm:ss A');

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event,id) => {
        setAnchorEl(event.currentTarget);
        setCurrentCardId(id);
      };

    const handleMenuClose = () =>{
        setAnchorEl(null)
    };

    return (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        {courseList.map((eq, index) => (
            <Grid item xs={12} sm={6} md={3} key={`card_${index}`}>
                <Card sx={{ position: 'relative' }} >
                    <CardMedia sx={{ height: 140, position: 'relative' }} image={eq.imageUrl === '' ? card_img : eq.imageUrl} title={eq.name}>
                        {/* <Chip label={eq.category} color="warning" sx={chipStyle('top', 'left')} /> */}
                        <Chip label={eq.status} color="primary" sx={chipStyle('bottom', 'right')} />
                    </CardMedia>
                    <CardContent sx={{ paddingBottom: '16px' }}>
                        <IconButton
                            aria-label="settings"
                            sx={iconButtonStyle}
                            color="primary"
                            onClick={(event) => handleMenuOpen(event, eq.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <SideMenuComponent 
                            anchorEl={anchorEl} 
                            handleMenuClose={handleMenuClose} 
                            onEditClick={() =>{ 
                                handleEditClick(eq.id);
                                handleMenuClose();
                            }} 
                            onDeleteClick={()=>{
                                handleDeleteClick();
                                handleMenuClose();
                            }}
                            onViewClick={()=>{handleViewClick()}}
                        />
                        <Typography gutterBottom variant="h5" component="div"  
                            sx={{ 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis' 
                            }}>
                            {eq.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <LongTextComponent text={eq.objective} maxLength={20} />
                        </Typography>
                        <Box sx={infoBoxStyle}>
                            <CalendarMonthIcon sx={iconStyle} color="primary" />
                            <Typography sx={dateStyle}>{eq.publishedOn ? formatDate(eq.publishedOn) : '-'}</Typography>
                        </Box>
                        <Box sx={infoBoxStyle}>
                            <PersonIcon sx={iconStyle} color="primary" />
                            <Typography sx={dateStyle}>{eq.publishedBy}</Typography>
                        </Box>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ justifyContent: 'end' }}>
                        <Box sx={infoBoxStyle}>
                            <AccessTimeIcon sx={iconStyle} color="primary" />
                            <Typography> {eq.duration} Mins</Typography>
                        </Box>
                    </CardActions>
                </Card>
        </Grid>
    ))}
        </Grid>
    );
};

const chipStyle = (vertical, horizontal) => ({
    position: 'absolute',
    [vertical]: 8,
    [horizontal]: 8,
    zIndex: 10,
    borderRadius: '5px',
    height: '27px',
    textTransform: 'capitalize',
});

const iconButtonStyle = {
    position: 'absolute',
    right: 0,
    zIndex: 10,
    padding: '2px',
};

const iconStyle = {
    fontSize: '18px',
    marginRight: '5px',
};

const infoBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
};

const dateStyle = {
    fontSize: '14px',
};

export default CardComponent;
