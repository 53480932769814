import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransferListComponent from "./TransferList";
import { useDispatch, useSelector } from "react-redux";
import { clearBatchData, clearSessionSuccessData, clearTrainingEditData } from "../../features/trainingData/trainingSlice";
import SelectableUserListWithSearch from "./SelectableUserListWithSearch";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { GET_BATCH_PARTICIPANT_LIST, UPDATE_BATCH_PARTICIPANTS } from "../../constants";
import axios from "axios";
import Loader from "../Reusable/Loader";


const BatchParticipantsComponent = ({ TrainingBatchData, onSubmit,  showSuccess, showError, }) => {

    const [expandedAccordion, setExpandedAccordion] = useState(null);  // State to manage accordion expansion

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(false);

    
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]); // State to store selected users

    const [selectedOtherUsers, setSelectedOtherUsers] = useState([]); // State to store selected users

    const dispatch = useDispatch();

    useEffect(()=>{
        const validParticipants = TrainingBatchData
            .filter(item => item.trainingSessionParticipants !== null && item.trainingSessionParticipants.length > 0)
            .flatMap(item => item.trainingSessionParticipants);
        
        setLeft(validParticipants);
        
    },[TrainingBatchData]);

    useEffect(() => {
        // Set initial values for duration and scheduleDate if available from TrainingBatchData
        if (TrainingBatchData.length > 0) {
            setDuration(TrainingBatchData[0].duration || '');
            setScheduleDate(TrainingBatchData[0].trainingDate || '');
        }
    }, [TrainingBatchData]);

    const handleNext = () => {
        onSubmit();
        dispatch(clearSessionSuccessData());
        dispatch(clearBatchData());
    };

    console.log(left)

    const formatDate = (dateString) => moment(dateString).format('MMMM D, YYYY');

    const [scheduleDate, setScheduleDate] = useState(''); // Assign a dummy value in the correct format
    const [scheduleDateError, setScheduleDateError] = useState('');
    const today = dayjs().startOf('day'); // Get today's date
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Function to validate the date field
    const validateDate = (value) => {
        if (!value || value === 'Invalid Date') {
            setScheduleDateError('Validity is required');
        } else if (dayjs(value).isBefore(dayjs().startOf('day'))) {
            setScheduleDateError('Enter a valid date'); // Error for dates before today
        } else {
            setScheduleDateError('');
        }
    };

    const [duration, setDuration] = useState('');
    const [durationError, setDurationError] = useState('');

      const handleDurationChange = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setDuration('');
            setDurationError('Duration is required.');
        } else {
            if (/^\d*$/.test(value)) {
                setDuration(value);
                setDurationError('');
            } else{
                setDuration('');
                setDurationError('Only Numeric Value is allowed.');
            }
        }
      };

      const handleFirstBatchAddClick = (e,batchId) => {
        e.preventDefault();
        setFormSubmitted(true);
        setLoading(true);

        if (!scheduleDate || scheduleDateError || !duration || durationError) {
            return;
        }

        axios.post(`${UPDATE_BATCH_PARTICIPANTS}?userId=${user.employeeID}&existingBatchId=${batchId}&newBatchId=0&employeeIDs=${selectedUsers}&trainingDate=${scheduleDate}&duration=${duration}`)
            .then(response => {  
                const { data } = response.data;
                // After successful submission, close the dialog
                showSuccess(response.data.message);
                setLoading(false);
                setSelectedUsers([]);
                // handleNext();
            }).catch(error => {
                handleError(error);
            }); 
      };

      const [participantList , setParticipantList] = useState([]);

      const handleGetBatchParticipantListClick = (e,batchId, batchCode) => {
        
        e.preventDefault();
        setLoading(true);
        axios.get(`${GET_BATCH_PARTICIPANT_LIST}?batchCode=${batchCode}&batchId=${batchId}`)
            .then(response => {
                const { data } = response.data;
                setParticipantList(data);
                setLoading(false);
                setSelectedUsers([]);
            })
            .catch(error => {
                setLoading(false);
            });
      }

      const handleAddParticipantClick = (e, batchId) =>{
        e.preventDefault();
        setFormSubmitted(true);
        setLoading(true);

        if (!scheduleDate || scheduleDateError || !duration || durationError) {
            return;
        }

        axios.post(`${UPDATE_BATCH_PARTICIPANTS}?userId=${user.employeeID}&existingBatchId=${batchId}&newBatchId=0&employeeIDs=${selectedUsers}&trainingDate=${scheduleDate}&duration=${duration}`)
            .then(response => {  
                const { data } = response.data;
                // After successful submission, close the dialog
                showSuccess(response.data.message);
                setLoading(false);
                setSelectedUsers([]);
                setParticipantList([]);
                // handleNext();
            }).catch(error => {
                handleError(error);
            }); 
      }

      const handleError = (error) => {
        if (error.response) {
            const { data } = error.response;
            if (data.errors) {
                const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                    `${key}: ${messages.join(', ')}`
                ));
                showError(formattedErrors);
            } else {
                showError(data.title || 'An error occurred.');
            }
        } else {
            showError('Internal Server Error.');
        }
        setLoading(false);
    }

      const handleAccordionToggle = (index) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? index : null);  // Toggle the accordion
      };
    
      const handleCloseAccordion = (index) => {
        setExpandedAccordion(null);  // Close specific accordion by setting the state to null
      };
    
      const handleOpenNextAccordion = (nextIndex) => {
        setExpandedAccordion(nextIndex);  // Open the next accordion by updating the state
      };

    return(
        <>
         {loading && <Loader open={loading} />}
               {TrainingBatchData.map((item, index) => (
               <Accordion key={`accordion_${index}`} expanded={expandedAccordion === index}  // Control expansion by matching the index
               onChange={handleAccordionToggle(index)}  // Toggle accordion on click
               >
               <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#D9D9DC' }}>
                   {item.batchName} ({item.batchCode})
               </AccordionSummary>
               <AccordionDetails>
                   <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{ alignItems: 'center' }}>
                       <Grid item xs={12} md={6}>
                           <DatePicker
                               label="Schedule Date"
                               sx={{ width: '100%', '& .MuiInputBase-root': { height: '40px', '& input': { height: '100%', padding: '0 16px' } } }}
                               value={scheduleDate ? dayjs(scheduleDate) : dayjs(item.trainingDate)}
                               onChange={(date) => {
                                   const formattedDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : '';
                                   setScheduleDate(formattedDate);
                                   if (formSubmitted) validateDate(formattedDate);
                               }}
                               minDate={today}
                               slotProps={{
                                   field: { clearable: true },
                                   textField: { helperText: scheduleDateError, error: formSubmitted && !!scheduleDateError }
                               }}
                           />
                       </Grid>
                       <Grid item xs={12} md={6}>
                           <TextField
                               label="Duration"
                               size="small"
                               fullWidth
                               value={duration}
                               onChange={handleDurationChange}
                               error={!!durationError}
                               helperText={durationError}
                           />
                       </Grid>

                       <Grid item xs={12}>
                           {item.trainingSessionParticipants.length > 0 ? (
                               <>
                                   <SelectableUserListWithSearch
                                       users={item.trainingSessionParticipants}
                                       selectedUsers={selectedUsers}
                                       setSelectedUsers={setSelectedUsers}
                                   />
                                   <Button variant="contained" onClick={(e) => {handleFirstBatchAddClick(e, item.id);
                                    handleCloseAccordion(index);  // Close this accordion
                                    handleOpenNextAccordion(index + 1);  // Open the next accordion
                                   }}>+ Add Selected Participants</Button>
                               </>
                           ) : (
                               <>
                                   <Button variant="contained" onClick={(e) => handleGetBatchParticipantListClick(e, item.id, item.batchCode)}>Get Participants for this Batch</Button>
                                   {participantList.length > 0 ? (
                                       <SelectableUserListWithSearch
                                           users={participantList}
                                           selectedUsers={selectedUsers}
                                           setSelectedUsers={setSelectedUsers}
                                       />
                                   ) : <p>No users available for selection yet.</p>}
                                   <Button variant="contained" onClick={(e) => {handleAddParticipantClick(e, item.id)
                                    handleCloseAccordion(index);  // Close this accordion
                                    handleOpenNextAccordion(index + 1);  // Open the next accordion
                                   }}>+ Add Selected Participant</Button>
                               </>
                           )}
                       </Grid>
                   </Grid>
               </AccordionDetails>
           </Accordion>
               )
            )} 

            <div className={`float-right m-2`}>
                <Button variant="contained" onClick={handleNext}>Next</Button>
            </div>
        </>
    );
}

export default BatchParticipantsComponent;