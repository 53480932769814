import React, { useState } from 'react';
import moment from 'moment';
import { Box, Card, CardMedia, Chip, IconButton, Typography, Divider, CardContent, CardActions, Grid, Tooltip, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const AssessmentTable = ({ assessmentList,  showSuccess, showError  }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const columns = [
        { field: 'sno', headerName: 'SNo',width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 , sortable: false, headerAlign: 'center', type: 'number', responsive: true, disableColumnFilter: true, },
        { field: 'name', headerName: 'Assessment Name', flex: 1,  headerAlign: 'center' , type: 'string', responsive: true},
        { field: 'action', headerName: 'Action', flex: 1,  headerAlign: 'center', type: 'string', responsive: true ,
            sortable: false, disableColumnFilter: true,
            renderCell: (params) => <IconCell {...params}/>
         },
    ];

    const IconCell = ({ row }) => (
        <>
        <Tooltip title="Edit" arrow>
        <IconButton
          style={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            width: '28px',
            height: '28px',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '5px',
            backgroundColor: '#00A36C'
          }}
          color='primary'
          onClick={() => handleEditClick(row)}
        >
          <EditIcon sx={{fontSize: '1.5rem', color: '#fff'}}/>
        </IconButton>
        </Tooltip>

        <Tooltip title="Delete" arrow>
        <IconButton
          style={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            width: '28px',
            height: '28px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#00A36C'
          }}
          color='primary'
          onClick={() => handleDeleteClick(row)}
        >
          <DeleteIcon sx={{fontSize: '1.5rem', color: '#fff'}}/>
        </IconButton>
        </Tooltip>
        </>
      );

      const handleEditClick = (row) => {
      }

      const handleDeleteClick = (row) => {};

    const theme = useTheme();
    const isXs = useMediaQuery('(max-width:600px)'); // 0px - 600px
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px - 900px
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900px - 1200px
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl')); // 1200px - 1536px
    const isXl = useMediaQuery(theme.breakpoints.up('xl')); // 1536px and up
    
    
      const getHeight = () => {
        if (isXs) return 400;
        if (isSm) return 450;
        if (isMd) return 500;
        if (isLg) return 500;
        if (isXl) return '100vh';
        return 'auto';
      };
    
      // Set page size based on screen size
      const getPageSize = () => {
      let pageSize;
      if (isLg) {
        pageSize = 50; // 1200px and up
      } else if (isMd) {
        pageSize = 15; // 900px - 1200px
      } else if (isSm) {
        pageSize = 15; // 600px - 900px
      } else if (isXs) {
        pageSize = 10; // 0px - 600px
      } else {
        pageSize = 25;  
      }
    
      return pageSize;
    }

    return(
        <>
        <DataGrid 
            columnHeaderHeight={50} 
            // getRowHeight={() => 'auto'} 
            rowHeight={30}
            getRowId= {(row) => row.id}
            autoHeight={assessmentList.length < 10}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true } //Disable the print option in export
                },
            }}  
            pageSizeOptions={[10, 15, 25, 50, 100]}
            initialState={{
                pagination: {
                paginationModel: { pageSize: getPageSize(), page: 0 },
                },
                density: 'comfortable'
            }} 
            rows={assessmentList} 
            columns={columns}
            sx={{
                boxShadow: 2,
                height: assessmentList.length > 10 ? getHeight() : 'auto', // here when a fixed height for the table was set the issue with the scroll was fixed
                // width: '80%',
                margin: 'auto',
                '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
                },
                '& .MuiDataGrid-cell': {
                textAlign: 'center',
                placeContent: 'center'
                },
                '& .MuiDataGrid-columnHeader':{
                    backgroundColor: '#EEF0F4',
                },
                '& .super-app-theme--header':{
                    // width: isMobile == true ? '200px !important' : '250px !important',
                    // maxWidth: isMobile == true ? '200px !important' : '250px !important'
                },
                
            }}
            disableExtendRowFullWidth
            disableDensitySelector
            
            />
        </>
    );

}

export default AssessmentTable;