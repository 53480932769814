import React, { useEffect, useState } from 'react';
import { Box, Card, CardMedia, Chip, IconButton, Typography, Divider, CardContent, CardActions, Grid, Button, useMediaQuery, MenuItem } from '@mui/material';
import mappingStyles from './mappingStyles.module.css';
import { useTheme } from '@emotion/react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { CREATE_EMPLOYEE_MAPPING, POST_EDIT_PROFILE_MAPPING } from '../../constants';
import Loader from '../Reusable/Loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditFormComponent = ({ employeeList, locationList, profileList, showSuccess, showError}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const editDetails = useSelector((state) => state.mapData.mappingData);
    const user = useSelector((state) => state.auth.user);

    const [selectedProfiles, setSelectedProfiles] = useState(editDetails.length > 0 ? editDetails[0].profile : []);
    const [selectedLocation, setSelectedLocation] = useState(editDetails.length > 0 ? editDetails[0].location : []);
    const [employeeId, setEmployeeId] = useState(editDetails.length > 0 ? editDetails[0].employeeID : '');
    const [empId , setEmpId] = useState('');
    const [profileId, setProfileId] = useState([]);
    const [locationId, setLocationId] = useState([]);
    const [loading ,setLoading] = useState(false);

    useEffect(() => {
        // Sync profileId and locationId with selectedProfiles and selectedLocation
        setProfileId(selectedProfiles.map(profile => profile.id));
        setLocationId(selectedLocation.map(location => location.id));
    }, [selectedProfiles, selectedLocation]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            "userID": user.employeeID,
            "employeeID": empId === '' ? employeeList.find(emp => emp.employeeID === editDetails[0].employeeID).id : empId,
            "applicationProfileID": profileId,
            "locationID": locationId
        }

        axios.post(`${POST_EDIT_PROFILE_MAPPING}?id=${editDetails[0].id}`,formData)
        .then(response => {
              showSuccess(response.data.message);
              setLoading(false);
              setTimeout(() => {
                navigate('/configuration/userMapping');
            }, 1000); // Delay for 1 seconds
        })
        .catch(error => {
            showError(error.response.data.message);
            setLoading(false);
        });
    };

    const handleReset = () => {
        setSelectedLocation([]);
        setSelectedProfiles([]);
        setEmployeeId('');
        setEmpId('');
        setProfileId([]);
        setLocationId([]);
    };

    return(
        <>
        {loading && <Loader open={loading} />}
         <Card className={`${mappingStyles.form_card_style} mt-2`}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={isMobile? 0 : 2} p={isMobile ? 0 : 3}>
                        <Grid item xs={12} md={6} xl={6}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={profileList}
                                size='small'
                                getOptionLabel={(option) => option.name}
                                value={selectedProfiles}
                                onChange={(event, newValue) => {
                                    // Update the state with the new selected values
                                    setSelectedProfiles(newValue)
                                    const selectedIds = newValue.map(profile => profile.id);
                                    setProfileId(selectedIds)
                                    // handleProfileID(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Application Profile" placeholder="Profiles" />
                                )}
                                filterOptions={(options, { inputValue }) =>
                                    options.filter(option => 
                                        !selectedProfiles.some(selected => selected.id === option.id) &&
                                        !editDetails[0]?.profile.some(defaultProfile => defaultProfile.id === option.id)
                                    )
                                }
                                
                                />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={locationList}
                                value={selectedLocation}
                                size='small'
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    // Update the state with the new selected values
                                    setSelectedLocation(newValue);
                                    const selectedIds = newValue.map(location => location.id);
                                    setLocationId(selectedIds)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Application Location" placeholder="Location" />
                                )}
                                filterOptions={(options, { inputValue }) =>
                                    options.filter(option => 
                                        !selectedLocation.some(selected => selected.id === option.id) &&
                                        !editDetails[0]?.location.some(defaultLocation => defaultLocation.id === option.id)
                                    )
                                }
                                
                                />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                            id="outlined-select-currency"
                            label="Employee"
                            name='employee'
                            value={employeeId}
                            select
                            // helperText="Please select your currency"
                            size='small'
                            fullWidth
                            // required
                            onChange={(e) => {
                                setEmployeeId(e.target.value)
                                const ids = employeeList.find(emp => emp.employeeID === e.target.value)
                                setEmpId(ids.id);
                            }}
                            >
                            {employeeList.map((jL) => (
                                <MenuItem value={jL.employeeID} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{jL.name}</MenuItem>
                            ))}
                            </TextField>
                        </Grid>
                        
                        
                    </Grid>
                   
                    <div className={`float-right m-2`}>
                        <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                        <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
                    </div>
                </Box>
                </Card>
        </>
    );
}

export default EditFormComponent;