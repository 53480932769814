import { Box, Breadcrumbs, CardHeader, Chip, Collapse, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CATEGORY, PROGRAM_CATEGORY, STATUS } from '../../constants';
import { DatePicker } from '@mui/x-date-pickers';
import programStyles from './programStyles.module.css';
import SearchIcon from '@mui/icons-material/Search';
dayjs.extend(customParseFormat);

const FilterComponent = ({filters, setFilters, handleApplyFilters, handleFilterReset}) => {

    return(
        <>
        <Divider sx={{marginTop: '5px', marginBottom: '5px'}}></Divider>
            <Box className="mt-2 mb-2">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} xl={6}>
                        <TextField
                            id="outlined-select-currency"
                            label="Category"
                            name="category"
                            value={filters.category}
                            select
                            fullWidth
                            onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                        >
                            {PROGRAM_CATEGORY.map((c) => (
                                <MenuItem
                                    key={c.label}
                                    value={c.label}
                                    sx={{
                                        borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
                                        marginBottom: '8px',
                                        marginTop: '8px',
                                    }}
                                >
                                    {c.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={3} xl={6}>
                        <TextField
                            id="outlined-select-currency"
                            label="Status"
                            name="status"
                            value={filters.status}
                            select
                            fullWidth
                            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                        >
                            {STATUS.map((c) => (
                                <MenuItem
                                    key={c.label}
                                    value={c.label}
                                    sx={{
                                        borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
                                        marginBottom: '8px',
                                        marginTop: '8px',
                                    }}
                                >
                                    {c.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>


                    <Grid item xs={12} md={3} xl={6}>
                        <DatePicker
                            label="Start Date"
                            sx={{ width: '100%' }}
                            value={filters.FromDate ? dayjs(filters.FromDate) : null}
                            onChange={(e) => {
                                const date = new Date(e.$d);
                                const year = date.getFullYear();
                                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                const day = ('0' + date.getDate()).slice(-2);
                                const formattedDate = `${year}-${month}-${day}`;
                                setFilters({ ...filters, FromDate: formattedDate });
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={3} xl={6}>
                        <DatePicker
                            label="To Date"
                            sx={{ width: '100%' }}
                            value={filters.ToDate ? dayjs(filters.ToDate) : null}
                            onChange={(e) => {
                                const date = new Date(e.$d);
                                const year = date.getFullYear();
                                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                const day = ('0' + date.getDate()).slice(-2);
                                const formattedDate = `${year}-${month}-${day}`;
                                setFilters({ ...filters, ToDate: formattedDate });
                            }}
                        />
                    </Grid>
                </Grid>

                <div className={`mt-2 float-right`}>
                    <button onClick={handleApplyFilters} className={`${programStyles.filter_button} mr-2`}>
                        <SearchIcon />
                        Apply Filters
                    </button>

                    <button onClick={handleFilterReset} className={`${programStyles.filter_button}`}>
                        <AutorenewIcon />
                            Reset
                    </button>
                </div>
            </Box>
        </>
    );

}

export default FilterComponent;