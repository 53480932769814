import { Box, Breadcrumbs, Button, Chip, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import courseStyles from './courseStyles.module.css';
import UploadedContentCardDesignComponent from './UplodedContentCardDesign';
import { useDispatch, useSelector } from 'react-redux';
import { clearSuccessData, setEditData } from '../../features/courseData/courseSlice';
import { Toast } from 'primereact/toast';
import Loader from '../Reusable/Loader';
import AddContentDialog from './AddContentDialog';
import axios from 'axios';
import { GET_COURSE_CONTENT } from '../../constants';
import EditContentDialog from './EditContentDialog';
import empty_pic from '../../Logo/empty_pic.png';
import EditContentDetailsDialog from './EditContentDetailsDialog';

const EditContentComponent = ({ }) => {

    const editDetails = useSelector((state) => state.courseData.editData);

    const user = useSelector((state) => state.auth.user);
    const successData =  useSelector((state) => state.courseData.successData);

    const [open, setOpen] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    const [loading ,setLoading] = useState(false);

    const [pickedType, setPickedType] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toast = React.useRef(null);

      const showSuccess = (content) => {
          toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
      }
  
      const showError = (content) => {
          toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
      }

    const handleAddContentClick = () =>{
        setOpen(true);
    };

    const handleSubmit = () =>{
        dispatch(clearSuccessData());
        // setValue(2);
        setTimeout(() => {
            navigate('/courses');
        }, 1000); // Delay for 1 seconds
    };

    const [runEffect, setRunEffect] = useState(false);
    const [showForm, SetshowForm] = useState(false);
    const [editContentData, setEditContentData] = useState([]);

    // useEffect(()=>{
    //     axios.get(`${GET_COURSE_CONTENT}?courseID=${editDetails.id}&UserID=${user.employeeID}`)
    //     .then(response => {
    //            const {data} = response.data;
    //            dispatch(setEditData(data));
    //             setLoading(false);
    //     })
    //     .catch(error => {
    //         setLoading(false);
    //     });
    
    // },[runEffect]);

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <div className={`float-right`}>
            <Button variant='contained' onClick={()=>{handleAddContentClick()}}>+ Add Content</Button>
            <Button variant='contained' style={{marginLeft: '5px'}} onClick={()=>{handleSubmit()}}>Submit</Button>
        </div>
        {editDetails?.courseContents.length === 0 ? (
        <Box sx={{width: '100%', height: '100%', border: '1px solid', borderStyle: 'groove', borderRadius: '5px'}}>
            <img
            src={empty_pic}
            loading="lazy"
            alt='empty'
            
            ></img>
            </Box>
        ) : (
            <UploadedContentCardDesignComponent content={editDetails?.courseContents.length === 0 ? [] : editDetails?.courseContents} userId={user.employeeID} showSuccess={showSuccess} showError={showError} type={'edit'} openDetails={openDetails} setOpenDetails={setOpenDetails} setPickedType={setPickedType} SetshowForm={SetshowForm} setEditContentData={setEditContentData} setLoading={setLoading}  ></UploadedContentCardDesignComponent>
            
        )} 
            

            <EditContentDialog open={open} setOpen={setOpen} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} setLoading={setLoading} courseId={editDetails.id} ></EditContentDialog>

            <EditContentDetailsDialog openDetails={openDetails} setOpenDetails={setOpenDetails} setRunEffect={setRunEffect} pickedType={pickedType} showSuccess={showSuccess} showError={showError} setLoading={setLoading} courseId={editDetails.id} editContentData={editContentData} ></EditContentDetailsDialog>
        </>
    );

}

export default EditContentComponent;