import { useTheme } from '@emotion/react';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CATEGORY, CREATE_JOURNEY, EDIT_JOURNEY_POST } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import journeyStyles from './journeyStyles.module.css';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const EditJourneyComponent = ({openEdit, setOpenEdit, editDetails , setRunEffect, showSuccess, showError}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);
    
    const [journeyName, setJourneyName] = useState('');
    const [journeyDescription, setJourneyDescription] = useState('');
    const [category, setCategory] = useState('');
    const [draftStatus, setDraftStatus] = useState('');
    const [publishedStatus, setPublishedStatus] = useState('');
    const [images, setImages] = useState(null);
    const [uploadedImage, setUploadedImages] = useState(null);
    const [finalStatus, setFinalStatus] = useState('');
    const [publishedOn, setPublishedOn] = useState(null);
    const [publishedBy, setPubilshedBy] = useState(null);

    // Use useEffect to set the initial values when editDetails is available
        useEffect(() => {
            if (editDetails) {
            setJourneyName(editDetails.name || '');
            setJourneyDescription(editDetails.description || '');
            setCategory(editDetails.category || '');
            setDraftStatus(editDetails.status === 'Draft' ? 'Draft' : '');
            setPublishedStatus(editDetails.status === 'Published' ? 'Published' : '');
            setFinalStatus(editDetails.status || '');
            setImages(editDetails.imageUrl || null);
            setUploadedImages(editDetails.imageUrl || null);
            setPubilshedBy(editDetails.publishedBy || null);
            setPublishedOn(editDetails.publishedOn || null);
            }
        }, [editDetails]);
    
    const handleClose = () =>{
        setOpenEdit(false)
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Process only the first file
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setImages(imageURL);
            setUploadedImages(file);

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        }
    };
    
    const handleRemoveImage = (index) => {
        setImages(null);
        setUploadedImages(null);
      };

      const handleStatusChange = (e) =>{
        const value = e.target.value;
        if (value === 'Draft') {
            setDraftStatus('Draft');
            setPublishedStatus('');
            setFinalStatus('Draft')
        } else {
            setPublishedStatus('Published');
            setDraftStatus('');
            setFinalStatus('Published');
            setPublishedOn(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
            setPubilshedBy(user.employeeID)
        }
      }
    

      const handleSubmit = () =>{

          const formData = new FormData();
            formData.append("name", journeyName);
            formData.append("description", journeyDescription);
            formData.append("category", category);
            formData.append("imageUrl", ''); // If you have a URL to send
            formData.append("file", uploadedImage); // Append the file
            formData.append("updatedBy", user.employeeID);
            formData.append("status", finalStatus);
            formData.append("publishedOn", publishedOn === null ? '' : publishedOn);
            formData.append("publishedBy", publishedBy === null ? '' : publishedBy);


        axios.post(`${EDIT_JOURNEY_POST}?id=${editDetails.id}`, formData)
            .then(response => {         
                // After successful submission, close the dialog
                showSuccess(response.data.message);
                handleClose();

                setRunEffect(prev => !prev);
                
            }).catch(error => {
                showError(error.message);
                handleClose();
            });
        
      };


      const handleReset =() =>{
        setImages(null);
        setJourneyName('');
        setJourneyDescription('');
        setDraftStatus('');
        setPublishedStatus('');
        setCategory('');
      };

    return(
        <>
        <Dialog
            open={openEdit}
            onClose={handleClose}
            fullScreen={fullScreen} // Adjusts dialog size based on screen width
            maxWidth="sm" // Limits maximum width of the dialog
            fullWidth // Ensures dialog takes full width of the screen
        >
            <DialogTitle sx={{
                background: (theme) => theme.palette.primary.main,
                color: '#fff',
            }} >Edit Journey
            </DialogTitle>

            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff',
            }}
            >
            <CloseIcon />
            </IconButton>

            <DialogContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Journey Name"
                        // helperText="Please select your currency"
                        size='small'
                        name='name'
                        fullWidth
                        value={journeyName}
                        onChange={(e) => {
                            // if (/^\d*$/.test(e.target.value)) {
                            setJourneyName(e.target.value)
                            // }
                        }}
                        >
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Journey Description"
                        // helperText="Please select your currency"
                        size='small'
                        name='description'
                        multiline
                        rows={4}
                        fullWidth
                        value={journeyDescription}
                        inputProps={{ 
                            inputProps: {
                                maxLength: '500'
                            }
                        }}
                        style={{ resize: 'both', overflow: 'auto' }}
                        onChange={(e)=>{ 
                            if (e.target.value.length <= 500) {
                                setJourneyDescription(e.target.value)
                            }
                        }}
                        
                        />
                        {/* <Typography variant="body2" color={journeyDescription.length > 500 ? 'error' : 'text.secondary'} sx={{marginLeft: '7px'}}>
                            {journeyDescription.length}/500
                        </Typography> */}
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Category"
                        name='category'
                        value={category}
                        select
                        // helperText="Please select your currency"
                        size='small'
                        fullWidth
                        onChange={(e) => {setCategory(e.target.value)}}
                        >
                        {CATEGORY.map((c) => (
                            <MenuItem value={c.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                        ))}
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={4} xl={6}>
                            <div className={`px-2`}>
                                <Typography variant="body2">Upload Images</Typography>

                                {!images ? (
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                    >
                                        Upload Image
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                ) : (
                                    <div>
                                        <img src={images} alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <IconButton
                                            onClick={handleRemoveImage}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4} xl={6}>
                            
                        <RadioGroup
                            value={draftStatus === 'Draft' ? 'Draft' : publishedStatus}
                            onChange={(e) => {
                               handleStatusChange(e)
                            }}
                            name="radio-buttons-group"
                            >
                            <FormControlLabel
                                value="Draft"
                                control={<Radio />}
                                label="Save As Draft"
                                inputProps={{ 'aria-label': 'Save As Draft' }}
                            />
                            <FormControlLabel
                                value="Published"
                                control={<Radio />}
                                label="Publish Now"
                                inputProps={{ 'aria-label': 'Publish Now' }}
                            />
                            </RadioGroup>
                        </Grid>

                    </Grid>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default EditJourneyComponent;