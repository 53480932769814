import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mappingData: null,
};

const mappingdataSlice = createSlice({
  name: 'mappingData',
  initialState,
  reducers: {
    setMappingData(state, action) {
      state.mappingData = action.payload;
    },
    clearData(state) {
      state.mappingData = null;
    },
  },
});

export const { setMappingData, clearData } = mappingdataSlice.actions;

export default mappingdataSlice.reducer;