import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trainingEditData: null,
  sessionSuccessData: null,
  batchData: null,
};

const trainingDataSlice = createSlice({
  name: 'trainingEditData',
  initialState,
  reducers: {
    setTrainingEditData(state, action) {
      state.trainingEditData = action.payload;
    },
    setSessionSuccessData(state, action) {
      state.sessionSuccessData = action.payload;
    },
    setBatchData(state, action) {
      state.batchData = action.payload;
    },
    clearTrainingEditData(state) {
      state.trainingEditData = null;
    },
    clearSessionSuccessData(state) {
      state.sessionSuccessData = null;
    },
    clearBatchData(state) {
      state.batchData = null;
    },
  },
});

export const { setTrainingEditData, clearTrainingEditData, setSessionSuccessData, clearSessionSuccessData, setBatchData, clearBatchData } = trainingDataSlice.actions;

export default trainingDataSlice.reducer;