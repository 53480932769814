import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Reusable/Loader';
import { Autocomplete, Box, Button, Card, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import sessionStyle from './sessionStyles.module.css';
import axios from 'axios';
import { CREATE_TRAINING_SESSION, GET_COURSE_LIST } from '../../constants';
import { DatePicker } from '@mui/x-date-pickers';
import { setSessionSuccessData } from '../../features/trainingData/trainingSlice';


const GeneralInfoFormComponent = ({onSubmit, onBack, onReset, showSuccess, showError }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading ,setLoading] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    
    useEffect(()=>{

        axios.get(GET_COURSE_LIST)
        .then(response => {
            const {data} = response.data;
            setCourseList(data);
        })
        .catch(error => {
        });

      },[]);

      const [courseList, setCourseList] = useState([]);
      const [courseId,setCourseId] = useState('');

      const [modeOfTraining, setModeOFTraining] = useState('');
      const [modeOfTrainingError, setModeOFTrainingError] = useState('');
      const handleModeOfTrainingChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                setModeOFTraining('');
                setModeOFTrainingError('Objective is required.');
            } else {
                setModeOFTraining(value);
                setModeOFTrainingError('');
            
            }
      };

    const [sessionTitle, setSessionTitle] = useState('');
    const [sessionTitleError, setSessionTitleError] = useState('');

    const handleSessionTitleChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                setSessionTitle('');
                setSessionTitleError('Title is required.');
            } else {
                setSessionTitle(value);
                setSessionTitleError('');
            }
      };

    const [sessionObjective, setSessionObjective] = useState('');
    const [sessionObjectiveError, setSessionObjectiveError] = useState('');

    const handleSessionObjectiveChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                setSessionObjective('');
                setSessionObjectiveError('Objective is required.');
            } else {
                setSessionObjective(value);
                setSessionObjectiveError('');
            
            }
      };

     const [isSelfNominate, setIsSelfNominate]= useState(false);

      const handleCheckboxChange = (event) => {
        setIsSelfNominate(event.target.checked);
        };
    
    const [SelfNominationEndDays, SetselfNominationEndDays] =   useState('')

     const [isMandatory, setIsMandatory]= useState(false);

        const handleisMandatoryChange = (event) => {
            setIsMandatory(event.target.checked);
        };

     const [VirtualSessionUrl, setVirtualSessionUrl] = useState('');
     const [VirtualSessionUrlError, setVirtualSessionUrlError] = useState('');

     const handleVirtualSessionUrlChange = (e) => {
        const value = e.target.value;
        setVirtualSessionUrl(value);
        // Perform URL validation if needed
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // onSubmit();
        let isValid = true;
        const fieldErrors = {};

        setLoading(true);

        if (!sessionTitle.trim()) {
            fieldErrors.sessionTitle = 'Title is required.';
            setSessionTitleError('Title is required.');
            isValid = false;
        }

        if (!sessionObjective.trim()) {
            fieldErrors.sessionObjective = 'Objective is required.';
            setSessionObjectiveError('Objective is required.');
            isValid = false;
        }

        if (modeOfTraining === '' || (modeOfTraining === 'Virtual' && !VirtualSessionUrl.trim())) {
            fieldErrors.sessionObjective = 'Mode of Training and URL is required.';
            setModeOFTrainingError('Mode of Training and URL are required.');
            isValid = false;
        }

        if (isValid) {
            const formData = new FormData();
            formData.append("UserID", user.employeeID); //Login user id
            formData.append("Title", sessionTitle);
            formData.append("Objective", sessionObjective);
            formData.append("CourseID", courseId);
            formData.append("Type", modeOfTraining);
            formData.append("VirtualSessionUrl", VirtualSessionUrl);
            formData.append("SelfNominationEnabled", isSelfNominate);
            formData.append("IsMandatory", isMandatory);
            formData.append("SelfNominationEndDays", SelfNominationEndDays);
            formData.append("CertificateID", 0);
            formData.append("PreAssessmentID", 0);
            formData.append("PostAssessmentID", 0);
            formData.append("PreAssessmentValidityDays", 0);
            formData.append("PostAssessmentValidityDays", 0);
            formData.append("LastUpdatedBy",user.employeeID);
            formData.append("LaunchOn", '');

                
            axios.post(CREATE_TRAINING_SESSION, formData)
                .then(response => {         
                    const { data } = response.data;
                    // After successful submission, close the dialog
                    showSuccess(response.data.message);
                    setLoading(false);
                    dispatch(setSessionSuccessData(data));
                    onSubmit(); // Call onSubmit to move to the next step
                    
                }).catch(error => {
                    if (error.response) {
                        const { data } = error.response;
                        if (data.errors) {
                        const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                            `${key}: ${messages.join(', ')}`
                        ));
                            showError(formattedErrors);
                            setLoading(false);
                        }else {
                            showError(data.title || 'An error occurred.');
                            setLoading(false);
                        }
                        } else {
                            showError('Intenal Server Error.');
                            setLoading(false);
                        }
                });
        } else{
            console.log('Form submission blocked due to validation errors.');
            showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
            setLoading(false);
        }
    };

    const handleReset = () => {
        setSessionTitle('');
        setSessionObjective('');
        setModeOFTraining('');
        setCourseId('');
        setVirtualSessionUrl('');
        setIsSelfNominate(false);
        setIsMandatory(false);
        SetselfNominationEndDays('');

        setSessionTitleError('');
        setSessionObjectiveError('');
        setModeOFTrainingError('');
        setVirtualSessionUrlError('');

        onReset();
    };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Card className={`${sessionStyle.form_card_style} mt-2`}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
               <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{alignItems: 'center'}}>
                    
                    <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                id="outlined-title"
                                label="Title"
                                size='small'
                                name='title'
                                fullWidth
                                value={sessionTitle}
                                onChange={handleSessionTitleChange}
                                required
                                error={!!sessionTitleError}
                                helperText={sessionTitleError}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                id="outlined-objective"
                                label="Objective"
                                size='small'
                                name='objective'
                                multiline
                                rows={4}
                                fullWidth
                                value={sessionObjective}
                                onChange={handleSessionObjectiveChange}
                                required
                                error={!!sessionObjectiveError}
                                helperText={sessionObjectiveError}
                            />
                        </Grid> 
    
                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Course List"
                                name='course'
                                value={courseId}
                                select                            
                                size='small'
                                fullWidth
                                onChange={(e) => {setCourseId(e.target.value)}}
                                >
                                {courseList.map((c) => (
                                    <MenuItem value={c.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
    
                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Mode of Training"
                                name='course'
                                value={modeOfTraining}
                                select                            
                                size='small'
                                fullWidth
                                onChange={(e) => {handleModeOfTrainingChange(e)}}
                                required
                                error={!!modeOfTrainingError}
                                helperText={modeOfTrainingError}
                                >
                                <MenuItem value={'Virtual'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Virtual</MenuItem>
                                <MenuItem value={'Classroom'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',  marginTop: '8px' }}>Classroom</MenuItem>
                                
                            </TextField>
                        </Grid>

                        {modeOfTraining === 'Virtual' && (
                            <Grid item xs={12} md={6} xl={6}>
                                <TextField
                                    id="outlined-title"
                                    label="Virtual Session URL"
                                    size='small'
                                    name='VirtualSessionUrl'
                                    fullWidth
                                    value={VirtualSessionUrl}
                                    onChange={handleVirtualSessionUrlChange}
                                    required
                                    error={!!VirtualSessionUrlError}
                                    helperText={VirtualSessionUrlError}
                                />
                            </Grid>
                        )}
    
                       
    
                        <Grid item xs={12} md={6} xl={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={isSelfNominate} 
                                        onChange={handleCheckboxChange} 
                                    />
                                }
                                label="Can Self Nomimate?"
                                labelPlacement="start"
                            />
                        </Grid>
    
                        {isSelfNominate && (<Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Self Nomination Closes Days Prior to Training"
                                name='sef'
                                value={SelfNominationEndDays}                            
                                size='small'
                                fullWidth
                                onChange={(e) => {SetselfNominationEndDays(e.target.value)}}
                                >
                            </TextField>
                        </Grid>)}
    
                        <Grid item xs={12} md={6} xl={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={isMandatory} 
                                        onChange={handleisMandatoryChange} 
                                    />
                                }
                                label="is Mandatory?"
                                labelPlacement="start"
                            />
                        </Grid>
    

                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                {/* Back button aligned to the left */}
                                <Button
                                    onClick={onBack}
                                    sx={{ mt: 1, mr: 1 }}
                                    variant='contained'
                                >
                                    Back
                                </Button>

                                {/* Submit and Reset buttons aligned to the right */}
                                <Box>
                                    <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                    variant='contained'
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={(e) => { handleSubmit(e) }}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Submit
                                    </Button>
                                    
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
            </Box>
        </Card>
        </>
    );
}

export default GeneralInfoFormComponent;