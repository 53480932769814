import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './features/auth/authSlice';
import programReducer from './features/programData/programSlice';
import mappingReducer from './features/userMappingData/userMappingSlice';
import courseReducer from './features/courseData/courseSlice';
import trainingReducer from './features/trainingData/trainingSlice';

// Step 1: Create a persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // Optionally whitelist or blacklist reducers
  // whitelist: ['auth'], // Only persist auth reducer
  // blacklist: ['progData', 'mapData'], // Do not persist these reducers
};

// Step 2: Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  progData: programReducer,
  mapData: mappingReducer,
  courseData: courseReducer,
  trainingData: trainingReducer
});

// Step 3: Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Step 4: Create the Redux store using the persistedReducer
const store = configureStore({
  reducer: persistedReducer,
});

// Step 5: Create a persistor for the store
const persistor = persistStore(store);

export { store, persistor };