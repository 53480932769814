import { useTheme } from '@emotion/react';
import { Box, Breadcrumbs, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CATEGORY, CREATE_JOURNEY, CREATE_PROGRAM, EDIT_PROGRAM_POST, GET_JOURNEY, GET_JOURNEY_LIST, PROGRAM_CATEGORY } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import programStyles from './programStyles.module.css';
import journeyStyles from '../Journey/journeyStyles.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import Loader from '../Reusable/Loader';
import dayjs from 'dayjs';

const ResizableTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      '& textarea': {
        resize: 'both', // Allow resizing both horizontally and vertically
        overflow: 'auto', // Show scrollbars when necessary
        minHeight: '100px', // Set a minimum height for the textarea
        width: '100%'
      },
    },
  });

const EditProgramComponent = ({}) => {

    const editDetails = useSelector((state) => state.progData.data);
    const user = useSelector((state) => state.auth.user);

    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading ,setLoading] = useState(false);

    const toast = useRef(null);

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    const [programName, setProgramName] = useState('');
    const [journeyID, setJourneyID] = useState('');
    const [programDescription, setProgramDescription] = useState('');
    const [category, setCategory] = useState('');
    const [draftStatus, setDraftStatus] = useState('');
    const [publishedStatus, setPublishedStatus] = useState('');
    const [images, setImages] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [finalStatus, setFinalStatus] = useState('');
    const [publishedOn, setPublishedOn] = useState(null);
    const [publishedBy, setPubilshedBy] = useState(null);
    const [journeyList, setJourneyList] = useState([]);

    // Use useEffect to set the initial values when editDetails is available
        useEffect(() => {
            if (editDetails) {
            setProgramName(editDetails.name || '');
            setJourneyID(editDetails.journeyId || '');
            setProgramDescription(editDetails.description || '');
            setCategory(editDetails.category || '');
            setDraftStatus(editDetails.status === 'Draft' ? 'Draft' : '');
            setPublishedStatus(editDetails.status === 'Published' ? 'Published' : '');
            setFinalStatus(editDetails.status || '');
            setImages(editDetails.imageUrl || null);
            setUploadedImage(editDetails.imageUrl || null);
            setPubilshedBy(editDetails.publishedBy || null);
            setPublishedOn(editDetails.publishedOn || null);
            }
        }, [editDetails]);


    useEffect(()=>{
        axios.get(GET_JOURNEY_LIST)
              .then(response => {
               console.log(response);
               const {data} = response.data;
               setJourneyList(data);
              })
              .catch(error => {
              
              });
    },[]);


      const handleImageChange = (event) => {
        const file = event.target.files[0]; // Process only the first file
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setImages(imageURL);
            setUploadedImage(file);

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        }
    };

    const handleRemoveImage = () => {
        setImages(null);
        setUploadedImage(null);
    };


    const handleStatusChange = (e) =>{
        const value = e.target.value;
        if (value === 'Draft') {
            setDraftStatus('Draft');
            setPublishedStatus('');
            setFinalStatus('Draft');
            setPublishedOn('');
            setPubilshedBy('');
        } else {
            setPublishedStatus('Published');
            setDraftStatus('');
            setFinalStatus('Published');
            setPublishedOn(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
            setPubilshedBy(user.employeeID)
        }
      }



      const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);
         // Create a FormData object to handle the file upload
         const formData = new FormData();
         formData.append("journeyID", journeyID);
         formData.append("name", programName);
         formData.append("description", programDescription);
         formData.append("category", category);
         formData.append("imageUrl", ''); // If you have a URL to send
         formData.append("file", uploadedImage); // Append the file
         formData.append("updatedBy", user.employeeID);
         formData.append("status", finalStatus);
         formData.append("publishedOn", publishedOn === null ? '' : publishedOn);
         formData.append("publishedBy", publishedBy === null ? '' : publishedBy);

        axios.post(`${EDIT_PROGRAM_POST}?id=${editDetails.id}`, formData,)
            .then(response => {         
                // After successful submission, close the dialog
                // handleClose();
                setLoading(false);
                showSuccess(response.data.message);
                setTimeout(() => {
                    navigate('/program');
                }, 1000); // Delay for 1 seconds
            }).catch(error => {
                if (error.response) {
                    const { data } = error.response;
                    if (data.errors) {
                    const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                        `${key}: ${messages.join(', ')}`
                    ));
                        showError(formattedErrors);
                        setLoading(false);
                    }else {
                        showError(data.title || 'An error occurred.');
                        setLoading(false);
                    }
                } else {
                        showError('Intenal Server Error.');
                        setLoading(false);
                    }
            });
        
      };


      const handleReset =() =>{
        setImages(null);
        setUploadedImage(null);
        setProgramName('');
        setProgramDescription('');
        setJourneyID('');
        setCategory('');
        setDraftStatus('');
        setPublishedOn('');
        setPubilshedBy('');
        setPublishedStatus('');
        setFinalStatus('');
      };

      const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Link underline="hover" key="1" color="inherit" to="/program" onClick={handleClick}>
                Program
            </Link>
            <Typography key="2" color="primary">
                Edit Program
            </Typography>
        </Breadcrumbs>

        <Card className={`${programStyles.form_card_style} mt-2`}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={isMobile? 0 : 2} p={isMobile ? 0 : 3}>
                        <Grid item xs={12} md={6} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Program Name"
                        size='small'
                        name='name'
                        fullWidth
                        value={programName}
                        onChange={(e) => {
                            // if (/^\d*$/.test(e.target.value)) {
                            setProgramName(e.target.value)
                            // }
                        }}
                        
                        >
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Journey"
                        name='journeyID'
                        value={journeyID}
                        select
                        // helperText="Please select your currency"
                        size='small'
                        fullWidth
                        // required
                        onChange={(e) => {setJourneyID(e.target.value)}}
                        >
                        {journeyList.map((jL) => (
                            <MenuItem value={jL.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{jL.name}</MenuItem>
                        ))}
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Category"
                        name='category'
                        value={category}
                        select
                        // helperText="Please select your currency"
                        size='small'
                        fullWidth
                        required
                        onChange={(e) => {setCategory(e.target.value)}}
                        >
                        {PROGRAM_CATEGORY.map((c) => (
                            <MenuItem value={c.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                        ))}
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                        <ResizableTextField
                                label={'Description'}
                                value={programDescription}
                                onChange={(e)=>{ if (e.target.value.length <= 500) {
                                    setProgramDescription(e.target.value)
                                }}}
                                multiline
                                fullWidth
                                variant="outlined" // Use 'outlined' for better border control
                                
                                />
                                <Typography variant="body2" color={programDescription.length > 500 ? 'error' : 'text.secondary'} sx={{marginLeft: '7px'}}>
                                    {programDescription.length}/500
                                </Typography>
                        </Grid>

                        
                        <Grid item xs={12} md={12} xl={6}>
                        <RadioGroup
                            value={draftStatus === 'Draft' ? 'Draft' : publishedStatus}
                            onChange={(e) => {
                               handleStatusChange(e)
                            }}
                            name="radio-buttons-group"
                            style={{flexDirection: 'row'}}
                            >
                            <FormControlLabel
                                value="Draft"
                                control={<Radio size='small' required />}
                                label="Save As Draft"
                                inputProps={{ 'aria-label': 'Save As Draft' }}
                                sx={{
                                    '& .MuiFormControlLabel-asterisk':{
                                        color: 'red'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value="Published"
                                control={<Radio size='small' required />}
                                label="Publish Now"
                                inputProps={{ 'aria-label': 'Publish Now' }}
                                sx={{
                                    '& .MuiFormControlLabel-asterisk':{
                                        color: 'red'
                                    }
                                }}
                            />
                            </RadioGroup>
                            
                        </Grid>

                        <Grid item xs={12} md={4} xl={6}>
                            <div className={`px-2`}>
                                <Typography variant="body2">Upload Images <span style={{ color: 'red' }}>*</span></Typography>

                                {!images ? (
                                    <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                    >
                                        Upload Image
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                            Only PNG and JPEG files are allowed. Size limit 250KB
                                    </p>
                                    </>
                                ) : (
                                    <div>
                                        <img src={images} alt="Uploaded" style={{ maxWidth: '180px', maxHeight: '180px' }} />
                                        <IconButton
                                            onClick={handleRemoveImage}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </Grid>

                       

                        
                        
                    </Grid>
                    
                    <div className={`float-right m-2`}>
                        <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                        <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
                    </div>
                </Box>
        </Card>
        </>
    );
}

export default EditProgramComponent;