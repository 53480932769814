import { useTheme } from '@emotion/react';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CATEGORY, CREATE_JOURNEY } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { styled } from '@mui/system';


const ResizableTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      '& textarea': {
        resize: 'both', // Allow resizing both horizontally and vertically
        overflow: 'auto', // Show scrollbars when necessary
        minHeight: '100px', // Set a minimum height for the textarea
      },
    },
  });

const AddJourneyComponent = ({open, setOpen, setRunEffect, showSuccess, showError}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);
    
    const [error, setError] = useState('');
    

    const [journeyName, setJourneyName] = useState('');
    const [journeyDescription, setJourneyDescription] = useState('');
    const [category, setCategory] = useState('');
    const [images, setImages] = useState(null);
    const [uploadedImage, setUploadedImages] = useState(null);
    const [publishedOn, setPublishedOn] = useState(null);
    const [publishedBy, setPublishedBy] = useState(null);
    const [draftStatus, setDraftStatus] = useState('');
    const [publishedStatus, setPublishedStatus] = useState('');
    const [status, setStatus] = useState('');

   const handleClose = () =>{
    setOpen(false);
    handleReset();
   };
   
   
   const [journeyNameError, setJourneyNameError] = useState('');
   const [journeyDescriptionError, setJourneyDescriptionError] = useState('');
   const [categoryError, setCategoryError] = useState('');
   const [imagesError, setImagesError] = useState('');

   const handleJourneyNameChange = (e) => {
    const value = e.target.value;
        if (value.trim() === '') {
                setJourneyName('');
                setJourneyNameError('Journey Name is required.');
        } else {
                setJourneyName(value);
                setJourneyNameError('');
        }
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (value.trim() === '') {
        setCategory(value);
        setCategoryError('Category is required.');
    } else {
        setCategory(value);
        setCategoryError('');
    }
};


      const handleStatusChange = (e) =>{
        const value = e.target.value;
        if (value === 'Draft') {
            setDraftStatus('Draft');
            setPublishedStatus('');
            setStatus('Draft')
        } else {
            setPublishedStatus('Published');
            setDraftStatus('');
            setStatus('Published');
            setPublishedOn(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
            setPublishedBy(user.employeeID)
        }
      };

      const handleImageChange = (event) => {
        const file = event.target.files[0]; // Process only the first file
        if (file) {
            const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSize = 250 * 1024; // 250KB in byte

            // Check file type
            if (!allowedExtensions.includes(file.type)) {
                showError('Only PNG and JPEG files are allowed.');
                return;
            }

            // Check file size
            if (file.size > maxSize) {
                showError('File size exceeds 250KB.');
                return;
            }

            // Create a URL for the uploaded image
            const imageURL = URL.createObjectURL(file);
            setImages(imageURL);
            setUploadedImages(file);

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
            }
    };

    const handleRemoveImage = (index) => {
        setImages(null);
        setUploadedImages(null);
      };

      const handleSubmit = () =>{
        let isValid = true;
        const fieldErrors = {};

        if (journeyName.trim() === '') {
            fieldErrors.journeyName = 'Journey Name is required.';
            setJourneyNameError('Journey Name is required.');
            isValid = false;
        }

        if (category.trim() === '') {
            fieldErrors.category = 'Category is required.';
            setCategoryError('Category is required.');
            isValid = false;
        }

        if (journeyDescription.trim() === '') {
            fieldErrors.journeyDescription = 'Description is required.';
            setJourneyDescriptionError('Description is required.');
            isValid = false;
        }

        if (!uploadedImage) {
            fieldErrors.uploadedImage = 'Image is required.';
            setImagesError('Image is required.');
            isValid = false;
        }

        if (isValid) {
            // Create a FormData object to handle the file upload
            const formData = new FormData();
            formData.append("name", journeyName);
            formData.append("description", journeyDescription);
            formData.append("category", category);
            formData.append("imageUrl", ''); // If you have a URL to send
            formData.append("file", uploadedImage); // Append the file
            formData.append("updatedBy", user.employeeID);
            formData.append("status", status === '' ? 'Draft' : status);
            formData.append("publishedOn", publishedOn === null ? '' : publishedOn);
            formData.append("publishedBy", publishedBy === null ? '' : publishedBy);

            axios.post(CREATE_JOURNEY, formData)
                .then(response => {         
                    // After successful submission, close the dialog
                    showSuccess(response.data.message);
                    handleClose();
                    setRunEffect(true);
                    
                }).catch(error => {
                    if (error.response) {
                        const { data } = error.response;
                        if (data.errors) {
                        const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                            `${key}: ${messages.join(', ')}`
                        ));
                            showError(formattedErrors);
                            handleClose();
                        }else {
                            showError(data.title || 'An error occurred.');
                        }
                    } else {
                        showError('Intenal Server Error.');
                        
                    }
                });
            }else{
                console.log('Form submission blocked due to validation errors.');
                showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
            }
        
      };


      const handleReset =() =>{
        setJourneyName('');
        setJourneyDescription('');
        setCategory('');
        setImages(null);
        setUploadedImages(null);
        setPublishedBy(null);
        setPublishedOn(null);
        setDraftStatus('');
        setPublishedStatus('');
        setCategoryError('');
        setImagesError('');
        setJourneyDescriptionError('');
        setJourneyNameError('');
        
      };

    return(
        <>
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen} // Adjusts dialog size based on screen width
            maxWidth="sm" // Limits maximum width of the dialog
            fullWidth // Ensures dialog takes full width of the screen
        >
            <DialogTitle sx={{
                background: (theme) => theme.palette.primary.main,
                color: '#fff',
            }} >Add Journey
            </DialogTitle>

            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff',
            }}
            >
            <CloseIcon />
            </IconButton>

            <DialogContent>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Journey Name"
                        // helperText="Please select your currency"
                        size='small'
                        name='name'
                        fullWidth
                        value={journeyName}
                        onChange={(e) => {
                            handleJourneyNameChange(e)
                        }}
                        required
                        error={!!journeyNameError}
                        helperText={journeyNameError}
                        >
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                        <ResizableTextField
                            label={'Description'}
                            value={journeyDescription}
                            onChange={(e)=>{ if (e.target.value.length <= 500) {
                                setJourneyDescription(e.target.value)
                            }}}
                            multiline
                            fullWidth
                            variant="outlined" // Use 'outlined' for better border control
                            required
                            error={!!journeyDescriptionError}
                            helperText={journeyDescriptionError}
                            />
                            <Typography variant="body2" color={journeyDescription.length > 500 ? 'error' : 'text.secondary'} sx={{marginLeft: '7px'}}>
                                {journeyDescription.length}/500
                            </Typography>
                        </Grid>



                        <Grid item xs={12} md={12} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        label="Category"
                        name='category'
                        value={category}
                        select
                        // helperText="Please select your currency"
                        size='small'
                        fullWidth
                        required
                        onChange={(e) => {handleCategoryChange(e)}}
                        error={!!categoryError}
                        helperText={categoryError}
                        >
                        {CATEGORY.map((c) => (
                            <MenuItem value={c.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                        ))}
                        </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                        <RadioGroup
                            value={draftStatus === 'Draft' ? 'Draft' : publishedStatus}
                            onChange={(e) => {
                               handleStatusChange(e)
                            }}
                            name="radio-buttons-group"
                            style={{flexDirection: 'row'}}
                        
                            >
                            <FormControlLabel
                                value="Draft"
                                control={<Radio size='small' required/>}
                                label="Save As Draft"
                                inputProps={{ 'aria-label': 'Save As Draft' }}
                                sx={{
                                    '& .MuiFormControlLabel-asterisk':{
                                        color: 'red'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value="Published"
                                control={<Radio size='small' required />}
                                label="Publish Now"
                                inputProps={{ 'aria-label': 'Publish Now' }}
                                sx={{
                                    '& .MuiFormControlLabel-asterisk':{
                                        color: 'red'
                                    }
                                }}
                            />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                            <div className={`px-2`}>
                                <Typography variant="body2">Upload Images <span style={{ color: 'red' }}>*</span></Typography>

                                {!images ? (
                                    <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                    >
                                        Upload Image
                                        <input
                                            accept=".png, .jpeg, .jpg"
                                            type="file"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                            Only PNG and JPEG files are allowed.
                                    </p>
                                    {imagesError && (
                                        <FormHelperText error>{imagesError}</FormHelperText>
                                    )}
                                    </>
                                ) : (
                                    <div>
                                        <img src={images} alt="Uploaded" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                                        <IconButton
                                            onClick={handleRemoveImage}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </Grid>



                        

                    </Grid>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
            </DialogActions>          
        </Dialog>
        </>
    );

}

export default AddJourneyComponent;