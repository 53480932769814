import React, { useState } from 'react';
import { Box, Card, CardMedia, Chip, IconButton, Typography, Divider, CardContent, CardActions, Grid, Button, useMediaQuery, MenuItem } from '@mui/material';
import mappingStyles from './mappingStyles.module.css';
import { useTheme } from '@emotion/react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { CREATE_EMPLOYEE_MAPPING } from '../../constants';
import Loader from '../Reusable/Loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FormCardComponent = ({ employeeList, locationList, profileList, showSuccess, showError}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [selectedProfilesError, setSelectedProfilesError] = useState([]);
    const [selectedLocation , setSelectedLocation] = useState([]);
    const [selectedLocationError , setSelectedLocationError] = useState([]);
    const [employeeId , setEmployeeId] = useState('');
    const [employeeIdError, setEmployeeIdError] = useState('');

    const [profileId, setProfileId] = useState([]);
    const [locationId, setLocationId] = useState([]);

    const [loading ,setLoading] = useState(false);

    const user = useSelector((state) => state.auth.user);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) =>{
        e.preventDefault();
        let isValid = true;
        setIsSubmitted(true);

        setLoading(true);

        if (employeeId === '') {
            setEmployeeIdError('Field is required.');
            isValid = false;
        }

        if (selectedProfiles.length === 0) {
            setSelectedProfilesError('Field Is Required');
            isValid = false;
        }

        if (selectedLocation.length === 0) {
            setSelectedLocationError('Field Is Required');
            isValid = false;
        }

        if (isValid) {
        const formData = {
            "userID": user.employeeID,
            "employeeID": employeeId,
            "applicationProfileID": profileId,
            "locationID": locationId
        }
        axios.post(CREATE_EMPLOYEE_MAPPING,formData)
        .then(response => {
              showSuccess(response.data.message);
              setLoading(false);
              setTimeout(() => {
                navigate('/configuration/userMapping');
            }, 1000); // Delay for 1 seconds
        })
        .catch(error => {
            showError(error.response.data.message);
            setLoading(false);
        });
    } else{
        console.log('Form submission blocked due to validation errors.');
        setLoading(false);
        showError('Please fill all Mandatory Feilds');
    }
    };

    const handleReset = () => {
        setSelectedLocation([]);
        setSelectedProfiles([]);
        setEmployeeId('');
        setProfileId([]);
        setLocationId([]);
        setEmployeeIdError('');
        setSelectedLocationError('');
        setSelectedProfilesError('');
    };

    return(
        <>
        {loading && <Loader open={loading} />}
            <Card className={`${mappingStyles.form_card_style} mt-2`}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={isMobile? 0 : 2} p={isMobile ? 0 : 3}>
                        <Grid item xs={12} md={6} xl={6}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={profileList}
                                size='small'
                                getOptionLabel={(option) => option.name}
                                value={selectedProfiles}
                                onChange={(event, newValue) => {
                                    // Update the state with the new selected values
                                    setSelectedProfiles(newValue)
                                    const selectedIds = newValue.map(profile => profile.id);
                                    setProfileId(selectedIds)
                                    // handleProfileID(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Application Profile" placeholder="Profiles" required error={isSubmitted && !!selectedProfilesError} helperText={isSubmitted ? selectedProfilesError : ''} />
                                )}
                                
                                />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={locationList}
                                value={selectedLocation}
                                size='small'
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    // Update the state with the new selected values
                                    setSelectedLocation(newValue);
                                    const selectedIds = newValue.map(location => location.id);
                                    setLocationId(selectedIds)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Location" placeholder="Location" required error={isSubmitted && !!selectedLocationError} helperText={isSubmitted ? selectedLocationError : ''}  />
                                )}
                                
                                />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                            id="outlined-select-currency"
                            label="Employee"
                            name='employee'
                            value={employeeId}
                            select
                            // helperText="Please select your currency"
                            size='small'
                            fullWidth
                            required
                            error={!!employeeIdError}
                            helperText={employeeIdError}
                            onChange={(e) => {setEmployeeId(e.target.value)}}
                            >
                            {employeeList.map((jL) => (
                                <MenuItem value={jL.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{jL.name}</MenuItem>
                            ))}
                            </TextField>
                        </Grid>
                        
                        
                    </Grid>                   
                   
                    {/* Action Buttons */}
                    <div className={`float-right m-2`}>
                        <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                        <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
                    </div>
                </Box>
                </Card>
        </>
    );
}

export default FormCardComponent;

