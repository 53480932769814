import { Box, Breadcrumbs, CardHeader, Chip, Collapse, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import * as React from 'react';

const SortComponent = ({anchorSortEl, handleSortMenuClose, handleSortValue}) => {
    const SortMenuId = 'primary-sort-menu';
    const isSortMenuOpen = Boolean(anchorSortEl);

    return(
        <>
        <Menu
        anchorEl={anchorSortEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        id={SortMenuId}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={isSortMenuOpen}
        onClose={handleSortMenuClose}
        >
        <MenuItem onClick={(e) => handleSortValue('CreatedDate')} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px',marginTop: '8px',}}>By Created Date</MenuItem>
        <MenuItem onClick={(e) => handleSortValue('PublishedDate')} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px',marginTop: '8px',}}>By Published Date</MenuItem>
        <MenuItem onClick={(e) => handleSortValue('NameAsc')} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px',marginTop: '8px',}}>By Name Ascending</MenuItem>
        <MenuItem onClick={(e) => handleSortValue('NameDesc')}>By Name Descending</MenuItem>
        </Menu>
        </>
    );
}

export default SortComponent;