import { Typography, Button } from '@mui/material';
import * as React from 'react';
import './program.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PaginationComponent = ({totalPage, currentPage, setCurrentPage}) => {
    return(
        <>
        <div className="pagination">
            <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                <ArrowBackIcon />
            </Button>
            <Typography>{`Page ${currentPage} of ${totalPage}`}</Typography>
            <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPage}
                className="pagination-button"
            >
                <ArrowForwardIcon />
            </Button>
        </div>
        </>
    );
}

export default PaginationComponent;