import { Box, Breadcrumbs, Chip, Collapse, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import card_img from '../../Logo/card_img.jpg';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../Reusable/Loader';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { CATEGORY, EDIT_PROGRAM_GET, GET_PROGRAM, PROGRAM_CATEGORY, SORT, STATUS } from '../../constants';
import { Message } from 'primereact/message';
import LongTextComponent from '../Reusable/LongTextComponent';
import { setData } from '../../features/programData/programSlice';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './program.css';
import programStyles from './programStyles.module.css';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SortComponent from '../Journey/SortComponent';
import FilterComponent from './FilterComponent';
import CardComponent from './CardComponent';
import PaginationComponent from './PaginationComponent';
import { Toast } from 'primereact/toast';
dayjs.extend(customParseFormat);

const ProgramComponent = ({}) => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [openFilters, setOpenFilters] = useState(false); 
    const [openSort, setOpenSort] = useState(false); 
    const [searchQuery, setSearchQuery] = useState(''); 

    const user = useSelector((state) => state.auth.user);

    const toast = React.useRef(null);

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }

      const [programList, setProgramList] = useState([]);
      const [loading ,setLoading] = useState(false);
      const [currentCardId, setCurrentCardId] = useState(null);

      const handleAddClick = () =>{
        navigate('/program/add');
      };


      const [anchorSortEl, setAnchorSortEl] = useState(null);


      const handleSortMenuClose = () => {
        setAnchorSortEl(null);
      };

      const [editDetails, setEditDetails] = useState([]);

    const [filters, setFilters] = useState({
        status:'All',
        category: '',
        sort: '',
        FromDate: '',
        ToDate: '',
        userId: ''
      });

      const [runEffect, setRunEffect] = useState(false);

      useEffect(()=>{
        fetchDataApi()
      },[currentPage, runEffect]);

    
      const fetchDataApi = () =>{
        setLoading(true);
        // setCurrentPage(1);
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: filters.sort,
            Search: searchQuery,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();

        axios.get(`${GET_PROGRAM}?${query}`)
        .then(response => {
               const {data, pages} = response.data;
               setProgramList(data);
               setTotalPage(pages);
               setLoading(false);
        })
        .catch(error => {
        
        });
      };

      const content = (
        <div className="flex align-items-center">
            {/* <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" width="32" /> */}
            <div className="ml-2">No Programs Yet.</div>
        </div>
    );

    const handleApplyFilters = () => {
        fetchDataApi();
        setCurrentPage(1); // Reset to the first page when filters are applied
    };

    const handleToggleFilters = () => {
        setOpenFilters(prevOpen => !prevOpen);
        setOpenSort(false);
    };

    

    const handleSortValue = (value) =>{
        setCurrentPage(1);
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: value,
            Search: searchQuery,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();
        setLoading(true);
        axios.get(`${GET_PROGRAM}?${query}`)
        .then(response => {
            const {data, pages} = response.data;
            setProgramList(data);
               setLoading(false);
               setTotalPage(pages);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
        handleSortMenuClose();
    }

    const handleToggleSort = (event) =>{
        setAnchorSortEl(event.currentTarget);
        setOpenFilters(false);
    }

    const handleTextSearch = (e) =>{
        const value = e.target.value;

        setCurrentPage(1);
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: filters.sort,
            Search: value,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();
        setLoading(true);
        axios.get(`${GET_PROGRAM}?${query}`)
        .then(response => {
            const {data, pages} = response.data;
            setProgramList(data);
               setLoading(false);
               setTotalPage(pages);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
    };

    const handleFilterReset = () =>{
        setFilters({
            status:'All',
            category: '',
            sort: '',
            FromDate: '',
            ToDate: '',
            userId: ''
        });

        setCurrentPage(1);
        setLoading(true);
        const query = new URLSearchParams({
            Status: 'All',
            Page: currentPage,
            Category: '',
            Sort: '',
            Search: '',
            FromDate: '',
            ToDate: '',
            UserID: user.employeeID
        }).toString();

        axios.get(`${GET_PROGRAM}?${query}`)
        .then(response => {
            const {data, pages} = response.data;
            setProgramList(data);
            setTotalPage(pages);
               setLoading(false);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
    }

    return(
        <>
        <Toast ref={toast} />
        {loading && <Loader open={loading} />}
        <Box sx={{display:'flex', justifyContent:'space-between'}}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Typography key="2" color="primary">
                Program
            </Typography>
        </Breadcrumbs>

        <div>
            <Button variant='contained' startIcon={<AddIcon></AddIcon>} onClick={handleAddClick}>Add Program</Button>
        </div>
        </Box>

        <Box className={`flex mt-1`} sx={{ justifyContent: 'flex-end' }}>
            <TextField
                id="search-box"
                label="Search"
                variant="outlined"
                size="small"
                sx={{ marginRight: '5px' }}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value)
                    if (e.target.value.length > 3){
                        handleTextSearch(e)
                    } else if (e.target.value.length === 0){
                        handleTextSearch(e)
                    }
                }}
            />
            <IconButton
                onClick={handleToggleFilters}
                sx={{
                    border: '1px solid',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
                color='primary'
            >
                <FilterAltIcon color='primary' />
            </IconButton>

            <IconButton
                onClick={handleToggleSort}
                sx={{
                    border: '1px solid',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
                color='primary'
            >
                <SortIcon color='primary' />
            </IconButton>
        </Box>

        <SortComponent anchorSortEl={anchorSortEl} handleSortMenuClose={handleSortMenuClose} handleSortValue={handleSortValue}></SortComponent>
        {openFilters && (
            <>
                <FilterComponent filters={filters} setFilters={setFilters} handleApplyFilters={handleApplyFilters} handleFilterReset={handleFilterReset} ></FilterComponent>
            </>
        )}

        {programList.length === 0 ? (
            
            <Message
                style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                    color: '#696cff',
                    justifyContent: 'flex-start'
                }}
                className="border-primary w-full"
                severity="info"
                content={content}
            />
        
        ):
        (
            <CardComponent programList={programList} setEditDetails={setEditDetails} userId={user.employeeID} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} ></CardComponent>
        )}

        {totalPage !== 0 && (<PaginationComponent totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} ></PaginationComponent>)}

        {/* <AddJourneyComponent open={open} setOpen={setOpen}></AddJourneyComponent> */}
        </>
    );
}

export default ProgramComponent;