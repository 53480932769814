import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Reusable/Loader';
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BATCH_MASTER_EMPLOYEE_LIST, BATCH_MASTER_LIST, CREATE_TRAINING_BATCH, GET_COURSE_LIST, GET_LOCATION_LIST, GET_TRAINER, GET_TRAINING_DEPARTMENT, GET_TRAINING_DESIGNATION, GET_TRAINING_EMPLOYEES, GET_TRAINING_GRADE, GET_TRAINING_LOCATION, GET_TRAINING_ROLE, GET_TRAINING_STATE, GET_TRAINING_SUB_DEPARTMENT, GET_TRAINING_VENUE } from '../../constants';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import TransferListComponent from './TransferList';
import { setBatchData } from '../../features/trainingData/trainingSlice';
import BatchParticipantsComponent from './BatchParticipants';

const ScheduleFormComponent = ({onSubmit, onBack, onReset, showSuccess, showError }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    const TrainingSuccessData = useSelector((state) => state.trainingData.sessionSuccessData);
    console.log(TrainingSuccessData);

    const TrainingBatchData = useSelector((state) => state.trainingData.batchData);

    const [courseList, setCourseList] = useState([]);
    const [courseId,setCourseId] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [venue , setVenue] = useState([]);
    const [trainerList, setTrainerList] = useState([]);
    const [trainerId, setTrainerId] = useState('');
    const [venueId, setVenueId] = useState('');
    

    useEffect(()=>{

        axios.get(GET_LOCATION_LIST)
        .then(response => {
            const {data} = response.data;
            setLocationList(data);
        })
        .catch(error => {
        });

        
    },[]);

    const fetchVenueApi = (id) => {
        axios.get(`${GET_TRAINING_VENUE}?locationId=${id}`)
        .then(response => {
            const {data} = response.data;
            setVenue(data);
        })
        .catch(error => {
        });

        axios.get(`${GET_TRAINER}?userId=${user.employeeID}&locationId=${id}`)
        .then(response => {
            const {data} = response.data;
            setTrainerList(data);
        })
        .catch(error => {
        });
    };

    const [scheduleDate, setScheduleDate] = useState(''); // Assign a dummy value in the correct format
    const [scheduleDateError, setScheduleDateError] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const now = new Date();
    const formattedDateTime = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    const today = dayjs().startOf('day'); // Get today's date
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Function to validate the date field
    const validateDate = (value) => {
        if (!value || value === 'Invalid Date') {
            setScheduleDateError('Validity is required');
        } else if (dayjs(value).isBefore(dayjs().startOf('day'))) {
            setScheduleDateError('Enter a valid date'); // Error for dates before today
        } else {
            setScheduleDateError('');
        }
    };

      const [duration, setDuration] = useState('');
      const [durationError, setDurationError] = useState('');

      const handleDurationChange = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setDuration('');
            setDurationError('Duration is required.');
        } else {
            if (/^\d*$/.test(value)) {
                setDuration(value);
                setDurationError('');
            } else{
                setDuration('');
                setDurationError('Only Numeric Value is allowed.');
            }
        }
      }

    const [selectedLocation , setSelectedLocation] = useState([]);
    const [locationId, setLocationId] = useState([]);

    
    const [minParticipants, setMinParticipants]= useState('');
    const [maxParticipants, setMaxParticipants]= useState('');
    const [maxParticipantsError, setMaxParticipantsError]= useState('');

    const handleMaxPartocipantsChange = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setMaxParticipants('');
            setMaxParticipantsError('Field is required.');
        } else {
            if (/^\d*$/.test(value)) {
                setMaxParticipants(value);
                setMaxParticipantsError('');
            } else{
                setMaxParticipants('');
                setMaxParticipantsError('Only Numeric Value is allowed.');
            }
        }
      };

      const [splitParticipants, setSplitParticipants]= useState(false);

      const handleCheckboxChange = (event) => {
            setSplitParticipants(event.target.checked);
        };
     

    const [loading, setLoading] = useState(false);

    const [dropdownLoading, setDropdownLoading] = useState(false);

    const [locationDropdown,setLocationDropdown] = useState([]);
    const [stateDropdown,setStateDropdown] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [subDepartmentList, setSubDepartmentList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [roleList, setRoleList] = useState([]);

        const [stateData, setStateData] = useState(null); // Initialize with null or ''
        const [locationData, setLocationData] = useState(null);
        const [departmentData, setDepartmentData] = useState(null);
        const [subDeptData, setSubDeptData] = useState(null);
        const [designationData, setDesignationData] = useState(null);
        const [gradeData, setGradeData] = useState(null);
        const [roleData, setRoleData] = useState(null);

      const [empList, setEmpList] = useState([]);
      const [selectedEmpList, setSelectedEmpList] = useState([]); 

      const [left, setLeft] = useState([]);
      const [right, setRight] = useState([]);

      

     // Common function to fetch dropdown data
    const fetchDropdownData = async (url, setter, params = {}) => {
        setDropdownLoading(true);
        try {
        const response = await axios.get(url, { params });
        setter(response.data.data);
        } catch (error) {
        console.error('Error fetching data:', error);
        } finally {
            setDropdownLoading(false);
        }
    };

     // Function to build API params
     const buildParams = () => ({
        state: stateData ? stateData.state || stateData : null,
        location: locationData ? locationData.location || locationData : null,
        department: departmentData ? departmentData.department || departmentData : null,
        subDepartment: subDeptData ? subDeptData.subDepartment || subDeptData : null,
        designation: designationData ? designationData.designation || designationData : null,
        grade: gradeData ? gradeData.grade || gradeData : null,
        role: roleData ? roleData.role || roleData : null,
      });

    // Function to fetch the employee list based on selected dropdown values
  const fetchEmployeeList = async (params = {}) => {
    console.log('Params being sent:', params);
    setRight([]);
    setLoading(true);
    try {
      const response = await axios.get(GET_TRAINING_EMPLOYEES, { params });
      setEmpList(response.data.data);  // Save the employee list
      setLeft(response.data.data);     // Initially set employees to left list
    } catch (error) {
      console.error('Error fetching employee list:', error);
    } finally {
      setLoading(false);
    }
  };

    const [existingBatchList, setExistingBatchList]= useState([]);
    const [existingBatchData, setExistingBatchData] = useState(null);

  const fetchExistingBatchDropdownData = async (url, setter) => {
    setDropdownLoading(true);
    try {
    const response = await axios.get(url);
    setter(response.data.data);
    } catch (error) {
    console.error('Error fetching data:', error);
    } finally {
        setDropdownLoading(false);
    }
};

  // Function to fetch the employee list based on selected dropdown values
  const fetchExistingBatchEmployeeList = async () => {
    console.log(existingBatchData);
    setLoading(true);
    try {
      const response = await axios.get(`${BATCH_MASTER_EMPLOYEE_LIST}?batchCode=${existingBatchData.name}`);
      setEmpList(response.data.data);  // Save the employee list
      setLeft(response.data.data);     // Initially set employees to left list
    } catch (error) {
      console.error('Error fetching employee list:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // onSubmit();
    setLoading(true);
    const formData = new FormData();
    formData.append("UserID", user.employeeID); //Login user id
    formData.append("BatchCode", batchType === 'new' ? '' : existingBatchData.name);
    formData.append("MinParticipants", minParticipants);
    formData.append("MaxParticipants", maxParticipants);
    formData.append("TrainingDate", scheduleDate);
    formData.append("Duration", duration);
    formData.append("LocationId", locationId);
    formData.append("VenueId", venueId);
    formData.append("TrainerId", trainerId);
    formData.append("TrainingSessionId", TrainingSuccessData.id);
    formData.append("ParticipantsEnrolledCount", 0);
    formData.append("LastUpdatedBy",user.employeeID);
    formData.append("employeeIDs", right.length > 0 ? right.map(employee => employee.id) : []);
    formData.append("isSplitRequired",splitParticipants);
    formData.append("isNewBatch", true);

    axios.post(CREATE_TRAINING_BATCH, formData)
    .then(response => {         
        const { data } = response.data;
        // After successful submission, close the dialog
        showSuccess(response.data.message);
        setLoading(false);
        dispatch(setBatchData(data));
        // onSubmit(); // Call onSubmit to move to the next step
        
    }).catch(error => {
        if (error.response) {
            const { data } = error.response;
            if (data.errors) {
            const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                `${key}: ${messages.join(', ')}`
            ));
                showError(formattedErrors);
                setLoading(false);
            }else {
                showError(data.title || 'An error occurred.');
                setLoading(false);
            }
            } else {
                showError('Intenal Server Error.');
                setLoading(false);
            }
    });
  };

  console.log(right);

  const handleReset = () => {
     setScheduleDate('');
     setDuration('');
     setMinParticipants('');
     setMaxParticipants('');
     setLocationId('');
     setVenueId('');
     setTrainerId('');
     setStateData('');
     setLocationData('');
     setDepartmentData('');
     setSubDeptData('');
     setDesignationData('');
     setGradeData('');
     setRoleData('');

     setEmpList([]);
     setDurationError('');
     setMaxParticipants('');
     setLeft([]);
     setRight([]);

  };


    const [batchType, setBatchtype] = useState('new');

    const handleBatchTypeChange = (event, newbatchType) => {
        if (newbatchType !== null) {
            setBatchtype(newbatchType);
        }
    };
console.log(TrainingBatchData);
    return(
        <>
        {loading && <Loader open={loading} />}

        { TrainingBatchData && TrainingBatchData.length > 0 ? (
            <BatchParticipantsComponent TrainingBatchData={TrainingBatchData} onSubmit={onSubmit} showSuccess={showSuccess} showError={showError}></BatchParticipantsComponent>
        ) :
        (<Card sx={{ mb: 2 }}>
            
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{alignItems: 'center'}}>


                <Grid item xs={12} md={6} xl={6}>
                    <DatePicker
                            label="Schedule Date"
                            sx={{ 
                                width: '100%',
                                // Adjust the height of the input field
                                '& .MuiInputBase-root': {
                                    height: '40px', // Adjust the height as needed
                                    '& input': {
                                        height: '100%',
                                        padding: '0 16px', // Adjust padding as needed
                                    }
                                },
                            }}
                            value={dayjs(scheduleDate)}
                            onChange={(date) => {
                                   
                            const formattedDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : '';
                                setScheduleDate(formattedDate);

                                // Validate when user is interacting with the field
                                if (formSubmitted) {
                                    validateDate(formattedDate);
                                }
                                        }}
                                        minDate={today}
                                        slotProps={{
                                            field: { clearable: true },
                                            textField: {
                                                helperText: scheduleDateError,                                   
                                                error: formSubmitted && !!scheduleDateError, 
                                            },
                                        }}

                              renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Self Nomination End Date" 
                                    placeholder="Self Nomination End Date" 
                                    required 
                                    error={!!scheduleDateError && isTouched}
                                    helperText={isTouched ? scheduleDateError : ''}
                                />
                            )}
                        />
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Duration"
                            size='small'
                            name='duration'
                            fullWidth
                            value={duration}
                            onChange={handleDurationChange}
                            required
                            error={!!durationError}
                            helperText={durationError}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Minimum Participants"
                            size='small'
                            name='minParticipants'
                            fullWidth
                            value={minParticipants}
                            onChange={(e)=>{setMinParticipants(e.target.value)}}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Maximum Participants"
                            size='small'
                            name='duration'
                            fullWidth
                            value={maxParticipants}
                            onChange={handleMaxPartocipantsChange}
                            required
                            error={!!maxParticipantsError}
                            helperText={maxParticipantsError}
                        />
                    </Grid> 

                    <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Location"
                                name='location'
                                value={locationId}
                                select                            
                                size='small'
                                fullWidth
                                onChange={(e) => {
                                    setLocationId(e.target.value);
                                    fetchVenueApi(e.target.value);
                                }}
                                >
                                {locationList.map((c) => (
                                    <MenuItem value={c.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',  marginTop: '8px' }}>{c.name}</MenuItem>
                                ))}
                            </TextField>
                            
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-select-currency"
                            label="Venue"
                            name='venue'
                            value={venueId}
                            select                            
                            size='small'
                            fullWidth
                            onChange={(e) => {setVenueId(e.target.value)}}
                            >
                            {venue.map((c) => (
                            <MenuItem value={c.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    

                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-select-currency"
                            label="Trainer"
                            name='trainer'
                            value={trainerId}
                            select                            
                            size='small'
                            fullWidth
                            onChange={(e) => {
                                setTrainerId(e.target.value)
                            }}
                            >
                            {trainerList.map((c) => (
                                <MenuItem value={c.id} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginTop: '8px' }}>{c.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={splitParticipants} 
                                        onChange={handleCheckboxChange} 
                                    />
                                }
                                label="Split Participants?"
                                labelPlacement="start"
                            />
                        </Grid>
                  
                </Grid>
            </Box>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{alignItems: 'center'}}>
                
                <Grid item xs={12} md={12} xl={12}>
                <ToggleButtonGroup
                    value={batchType}
                    exclusive
                    onChange={handleBatchTypeChange}
                    aria-label="text alignment"
                    >
                    <ToggleButton value="new" aria-label="new">
                        New Batch
                    </ToggleButton>
                    <ToggleButton value="existing" aria-label="existing">
                        Existing Batch
                    </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                {batchType == 'new' ? (<>
                 {/* State Autocomplete */}
                 <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={stateData}
                        onChange={(event, newValue) => setStateData(newValue)}
                        onFocus={() => {
                        if (!stateDropdown.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_STATE, setStateDropdown, params); // Fetch on focus
                        }
                        }}
                        options={stateDropdown}
                        getOptionLabel={(option) => (option?.state ? option.state : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.state === value?.state} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                 {/* Location Autocomplete */}
                    <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={locationData}
                        onChange={(event, newValue) => setLocationData(newValue)}
                        onFocus={() => {
                        if (!locationDropdown.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_LOCATION, setLocationDropdown,params); // Fetch on focus
                        }
                        }}
                        options={locationDropdown}
                        getOptionLabel={(option) => (option?.location ? option.location : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.location === value?.location} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Location"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                    {/* Department Autocomplete */}
                    <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={departmentData}
                        onChange={(event, newValue) => setDepartmentData(newValue)}
                        onFocus={() => {
                        if (!departmentList.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_DEPARTMENT, setDepartmentList,params); // Fetch on focus
                        }
                        }}
                        options={departmentList}
                        getOptionLabel={(option) => (option?.department ? option.department : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.department === value?.department} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Department"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                    {/* SubDepartment Autocomplete */}
                    <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={subDeptData}
                        onChange={(event, newValue) => setSubDeptData(newValue)}
                        onFocus={() => {
                        if (!subDepartmentList.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_SUB_DEPARTMENT, setSubDepartmentList,params); // Fetch on focus
                        }
                        }}
                        options={subDepartmentList}
                        getOptionLabel={(option) => (option?.subDepartment ? option.subDepartment : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.subDepartment === value?.subDepartment} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sub-Department"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                     {/* Designation Autocomplete */}
                     <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={designationData}
                        onChange={(event, newValue) => setDesignationData(newValue)}
                        onFocus={() => {
                        if (!designationList.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_DESIGNATION, setDesignationList,params); // Fetch on focus
                        }
                        }}
                        options={designationList}
                        getOptionLabel={(option) => (option?.designation ? option.designation : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.designation === value?.designation} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Designation"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                    {/* Grade Autocomplete */}
                    <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={gradeData}
                        onChange={(event, newValue) => setGradeData(newValue)}
                        onFocus={() => {
                        if (!gradeList.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_GRADE, setGradeList,params); // Fetch on focus
                        }
                        }}
                        options={gradeList}
                        getOptionLabel={(option) => (option?.grade ? option.grade : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.grade === value?.grade} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Grade"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>

                    {/* Role Autocomplete */}
                    <Grid item xs={12} md={6} xl={6}>
                    <Autocomplete
                        value={roleData}
                        onChange={(event, newValue) => setRoleData(newValue)}
                        onFocus={() => {
                        if (!roleList.length) {
                            const params = buildParams(); // Generate params
                            fetchDropdownData(GET_TRAINING_ROLE, setRoleList,params); // Fetch on focus
                        }
                        }}
                        options={roleList}
                        getOptionLabel={(option) => (option?.role ? option.role : '')} // Prevent undefined from showing
                        isOptionEqualToValue={(option, value) => option?.role === value?.role} // Ensure proper equality
                        loading={dropdownLoading}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Role"
                            size="small"
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </>
                            ),
                            }}
                        />
                        )}
                    />
                    </Grid>


                <Grid item xs={12} md={6} xl={6}>
                <Button
                    onClick={() => {
                    const params = buildParams(); // Build API params
                    fetchEmployeeList(params); // Fetch the employee list based on the params
                    }}
                    variant="contained"
                >
                    Fetch Employees
                </Button>
                </Grid>

                <Grid item xs={12} md={12} xl={6} alignItems={'center'}>
                {empList.length > 0 && (
                    <>
                    <h4>Employees</h4>
                    <TransferListComponent
                    left={left}
                    setLeft={setLeft}
                    right={right}
                    setRight={setRight}
                    />
                    </>
                )}
                </Grid>
                </>) : (
                    <>
                     <Grid item xs={12} md={6} xl={6}>
                        <Autocomplete
                            value={existingBatchData}
                            onChange={(event, newValue) => setExistingBatchData(newValue)}
                            onFocus={() => {
                            if (!existingBatchList.length) {
                                fetchExistingBatchDropdownData(BATCH_MASTER_LIST, setExistingBatchList); // Fetch on focus
                            }
                            }}
                            options={existingBatchList}
                            getOptionLabel={(option) => (option?.name ? option.name : '')} // Prevent undefined from showing
                            isOptionEqualToValue={(option, value) => option?.name === value?.name} // Ensure proper equality
                            loading={dropdownLoading}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Existing Batch"
                                size="small"
                                InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                    {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </>
                                ),
                                }}
                            />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <Button
                            onClick={() => {
                                fetchExistingBatchEmployeeList(); // Fetch the employee list based on the params
                            }}
                            variant="contained"
                        >
                            Fetch Employees
                        </Button>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6} alignItems={'center'}>
                        {empList.length > 0 && (
                            <>
                            <h4>Employees</h4>
                            <TransferListComponent
                            left={left}
                            setLeft={setLeft}
                            right={right}
                            setRight={setRight}
                            />
                            </>
                        )}
                        </Grid>
                    </>
                )}

                    <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                {/* Back button aligned to the left */}
                                <Button
                                    onClick={onBack}
                                    sx={{ mt: 1, mr: 1 }}
                                    variant='contained'
                                >
                                    Back
                                </Button>

                                {/* Submit and Reset buttons aligned to the right */}
                                <Box>
                                    <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                    variant='contained'
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={(e) => { handleSubmit(e) }}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Submit
                                    </Button>
                                    
                                </Box>
                            </Box>
                        </Grid>

                </Grid>
            </Box>
        </Card>)}

        
        </>
    );
}

export default ScheduleFormComponent;