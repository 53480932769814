import { Box, Breadcrumbs, Button, Chip, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContentCardViewComponent from './UploadedContentCardView';
import AddContentDialog from './AddContentDialog';
import UploadedContentCardViewComponent from './UploadedContentCardView';
import { Toast } from 'primereact/toast';
import Loader from '../Reusable/Loader';
import { GET_COURSE_CONTENT } from '../../constants';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { clearSuccessData } from '../../features/courseData/courseSlice';


const CourseContentComponent = ({setValue}) => {

    const [content, setContent] = useState([]);

    const user = useSelector((state) => state.auth.user);

    const successData =  useSelector((state) => state.courseData.successData);

    const [open, setOpen] = useState(false);
    const [loading ,setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toast = React.useRef(null);

      const showSuccess = (content) => {
          toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
      }
  
      const showError = (content) => {
          toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
      }

    const handleAddContentClick = () =>{
        setOpen(true);
    };

    const handleSubmit = () =>{
        dispatch(clearSuccessData());
        // setValue(2);
        setTimeout(() => {
            navigate('/courses');
        }, 1000); // Delay for 1 seconds
    };

    const [runEffect, setRunEffect] = useState(false);

    useEffect(()=>{
        if (successData) {
            // Clear the content state before fetching new data
            setContent([]);
        axios.get(`${GET_COURSE_CONTENT}?courseID=${successData?.data?.id}&UserID=${user.employeeID}`)
        .then(response => {
               const {data} = response.data;
               setContent(data)
                setLoading(false);
        })
        .catch(error => {
            setLoading(false);
        });
    }
    },[runEffect]);
    
    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} xl={6}>
                    <UploadedContentCardViewComponent content={content}></UploadedContentCardViewComponent>
                </Grid>
                <Grid item xs={12} md={4} xl={6}>
                    <Grid container  alignItems="center" >
                        <Button variant='contained' onClick={handleAddContentClick} style={{marginRight: '5px'}} > + Add Content</Button>
                        
                        <Button variant='contained' onClick={handleSubmit} >Submit</Button>
                        
                    </Grid>
                </Grid>
            </Grid>

            <AddContentDialog open={open} setOpen={setOpen} setLoading={setLoading} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} ></AddContentDialog>
        </>
    );
}

export default CourseContentComponent;
