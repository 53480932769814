import React, { useState } from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Checkbox, TextField, Grid, FormControlLabel } from '@mui/material';

// Function to generate string avatar (First letters of first and last name)
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name), // Set background color based on name
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`, // First letters of first and last name
  };
}

// Function to convert name to color
function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

const SelectableUserListWithSearch = ({ users,selectedUsers, setSelectedUsers }) => {
  
  const [filteredUsers, setFilteredUsers] = useState(users); // State to store filtered users
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  

  // Handle search filtering
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredUsers(users.filter((user) => user.employeeName.toLowerCase().includes(value)));
  };

  // Handle selection of users
  const handleToggle = (id) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Search Box */}
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        size="small"
        value={searchTerm}
        onChange={handleSearch}
        sx={{ marginBottom: '16px' }}
      />

      {/* User List */}
      <List>
        <Grid container spacing={2}>
          {filteredUsers.map((user) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={user.employeeID}>
              <ListItem
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={() => handleToggle(user.employeeID)} // Toggle selection on click
                button
              >
                {/* Avatar */}
                <ListItemAvatar>
                  <Avatar {...stringAvatar(user.employeeName)} />
                </ListItemAvatar>

                {/* Name */}
                <ListItemText primary={user.employeeName} sx={{ minWidth: '120px' }} />

                {/* Checkbox */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedUsers.includes(user.employeeID)}
                      onChange={() => handleToggle(user.employeeID)} // Toggle selection
                    />
                  }
                  label="" // No label for the checkbox
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
    </div>
  );
};

export default SelectableUserListWithSearch;