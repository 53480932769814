import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Typography, StepContent, Breadcrumbs } from '@mui/material';
import GeneralInfoFormComponent from './GeneralInfoFormComponent';
import Loader from '../Reusable/Loader';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import ScheduleFormComponent from './ScheduleFormComponent';
import AssessmentFormComponent from './AssessmentForm';




const AddTrainingSessionComponent = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading , setLoading] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const toast = React.useRef(null);

  const showSuccess = (content) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: content, life: 3000 });
  };

  const showError = (content) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: content, life: 3000 });
  };

  const steps = [
    {
      label: 'General Info',
      content: (handleNext, handleBack, handleReset) => (
          <GeneralInfoFormComponent
            onSubmit={handleNext}
            onBack={handleBack}
            onReset={handleReset}
            showSuccess={showSuccess}
            showError={showError}
          />
        ),
    },
    {
      label: 'Schedule & Batch',
      content: (handleNext, handleBack, handleReset) => (
          <ScheduleFormComponent 
            onSubmit={handleNext}
            onBack={handleBack}
            onReset={handleReset}
            showSuccess={showSuccess}
            showError={showError}
          />
        ),
    },
    {
        label: 'Assessment',
        content: (handleNext, handleBack, handleReset) => (
          <AssessmentFormComponent 
          onSubmit={handleNext}
          onBack={handleBack}
          onReset={handleReset}
          showSuccess={showSuccess}
          showError={showError}
        />
          ),
      },
    {
      label: 'FeedBack',
      content: (handleNext, handleBack, handleReset) => (
          <Box sx={{ mt: 2 }}>
            <Typography>Content for Step 3: Create your ad content and finalize.</Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}
            >
              Finish
            </Button>
            <Button
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Back
            </Button>
          </Box>
        ),
    }
  ];

  const handleClick = (event) => {
    // event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <Toast ref={toast} />
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{marginBottom: 2}}>
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Link underline="hover" key="3" color="inherit" to="/trainingSession" onClick={handleClick}>
                Training Session
            </Link>
            <Typography key="4" color="primary">
                Add Session
            </Typography>
        </Breadcrumbs>

      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box sx={{ mt: 2 }}>
            <Typography>All steps completed - you&apos;re finished!</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            {/* Step Content */}
            {steps[activeStep].content(handleNext, handleBack, handleReset)}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AddTrainingSessionComponent;
