import { useTheme } from '@emotion/react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import 'primeicons/primeicons.css';
import ContentTypeCard from './ContentTypeCard';
import ContentUploadFormComponent from './ContentUploadForm';
import { useSelector } from 'react-redux';
import EditContentUploadFormComponent from './EditContentUploadForm.jsx';

const EditContentDialog = ({open, setOpen,setRunEffect, pickedType, showSuccess, showError, setLoading,courseId, showForm}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoadingState] = useState(false);

    const handleClose = (event, reason) =>{
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
            // handleReset();

            // Reset showContentForm state with a delay to allow the dialog to close first
            setTimeout(() => {
                setShowContentForm(false);
                setSelectedType('');
            }, 300); // 300ms delay (adjust according to your dialog transition time)
        }
    };

       console.log(showForm)
    const [showContentForm, setShowContentForm] = useState(false);
    const [selectedType, setSelectedType] = useState('');


    return(
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown
                fullScreen={fullScreen} // Adjusts dialog size based on screen width
                maxWidth={showContentForm ? "md" : "lg"} // Limits maximum width of the dialog
                fullWidth // Ensures dialog takes full width of the screen
            >
                <DialogTitle sx={{
                    background: (theme) => theme.palette.primary.main,
                    color: '#fff',
                }} >Add Content
                </DialogTitle>

                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#fff',
                }}
                >
                <CloseIcon />
                </IconButton>

                {showContentForm ? (
                    <EditContentUploadFormComponent setOpen={setOpen} setShowContentForm={setShowContentForm} setRunEffect={setRunEffect} selectedType={selectedType} showSuccess={showSuccess} showError={showError} setLoadingState={setLoadingState} courseId={courseId} ></EditContentUploadFormComponent>
                    
                ) : (
                    <ContentTypeCard setShowContentForm={setShowContentForm} setSelectedType={setSelectedType}></ContentTypeCard>
                )}

                
            </Dialog>

            {loading && (
                <Box
                    sx={{
                        position: 'fixed', // Or 'absolute' depending on your layout
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: (theme) => theme.zIndex.modal + 1, // Higher than dialog z-index
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </>
    );

}

export default EditContentDialog;