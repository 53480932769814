import { Box, Breadcrumbs, CardHeader, Chip, Collapse, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddJourneyComponent from './AddJourneyComponent';
import axios from 'axios';
import { CATEGORY, EDIT_JOURNEY, EDIT_JOURNEY_GET, GET_JOURNEY, SORT, STATUS } from '../../constants';
import Loader from '../Reusable/Loader';
import { Toast } from 'primereact/toast';
import EditJourneyComponent from './EditJourneyComponent';
import './journey.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import journeyStyles from './journeyStyles.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import { Message } from 'primereact/message';
import { useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FilterComponent from './FilterComponent';
import SortComponent from './SortComponent';
import CardComponent from './CardComponent';
import PaginationComponent from './PaginationComponent';
dayjs.extend(customParseFormat);

const JourneyComponent = ({}) => {

    const [open, setOpen] = useState(false);
    const [loading ,setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [openFilters, setOpenFilters] = useState(false); 
    const [openSort, setOpenSort] = useState(false); 
    const [searchQuery, setSearchQuery] = useState('');

    const user = useSelector((state) => state.auth.user);
    
    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }

      const toast = React.useRef(null);

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }
    
      const [anchorSortEl, setAnchorSortEl] = useState(null);
      
      const handleSortMenuClose = () => {
        setAnchorSortEl(null);
      };
      
      const [editDetails, setEditDetails] = useState([]);
      const [openEdit, setOpenEdit] = useState(false);

      
    
      const handleAddClick = () =>{
        setOpen(true);
      };

      const [journeyList, setJourneyList] = useState([]);
      const [runEffect, setRunEffect] = useState(false);
      const [filters, setFilters] = useState({
        status:'All',
        category: '',
        sort: '',
        FromDate: '',
        ToDate: '',
        userId: ''
      });


      useEffect(()=>{
        fetchDataApi();
      },[runEffect, currentPage])


      

      const fetchDataApi = () =>{
        setLoading(true);
        
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: filters.sort,
            Search: searchQuery,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();

        axios.get(`${GET_JOURNEY}?${query}`)
        .then(response => {
            const {data, pages} = response.data;
            setJourneyList(data);
               setLoading(false);
               setTotalPage(pages); // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
      };

      const handleApplyFilters = () => {
        fetchDataApi();
        setCurrentPage(1); // Reset to the first page when filters are applied
    };

    const handleToggleFilters = () => {
        setOpenFilters(prevOpen => !prevOpen);
        setOpenSort(false);
    };


    const handleSortValue = (value) =>{
        setCurrentPage(1);
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: value,
            Search: searchQuery,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();
        setLoading(true);
        axios.get(`${GET_JOURNEY}?${query}`)
        .then(response => {
            const {data} = response.data;
            setJourneyList(data);
               setLoading(false);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
        handleSortMenuClose();
    }

    const handleToggleSort = (event) =>{
        setAnchorSortEl(event.currentTarget);
        setOpenFilters(false);
    }

    const content = (
        <div className="flex align-items-center">
            {/* <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" width="32" /> */}
            <div className="ml-2">No Journey Yet.</div>
        </div>
    );

    const handleTextSearch = (e) =>{
        const value = e.target.value;

        setCurrentPage(1);
        const query = new URLSearchParams({
            Status: filters.status,
            Page: currentPage,
            Category: filters.category,
            Sort: filters.sort,
            Search: value,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
            UserID: user.employeeID
        }).toString();
        setLoading(true);
        axios.get(`${GET_JOURNEY}?${query}`)
        .then(response => {
            const {data} = response.data;
            setJourneyList(data);
               setLoading(false);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
    };

    const handleFilterReset = () =>{

        setFilters({
            status:'All',
            category: '',
            sort: '',
            FromDate: '',
            ToDate: '',
            userId: ''
        });

        setCurrentPage(1);
        setLoading(true);
        const query = new URLSearchParams({
            Status: 'All',
            Page: currentPage,
            Category: '',
            Sort: '',
            Search: '',
            FromDate: '',
            ToDate: '',
            UserID: user.employeeID
        }).toString();

        axios.get(`${GET_JOURNEY}?${query}`)
        .then(response => {
            const {data} = response.data;
            setJourneyList(data);
               setLoading(false);
            //    totalPages = data.totalPages; // or data.totalItems / itemsPerPage
        })
        .catch(error => {
            setLoading(false);
        });
    }

    return(
        <>
        <Toast ref={toast} style={{ zIndex: 9999 }} />
        {loading && <Loader open={loading} />}
        <Box sx={{display:'flex', justifyContent:'space-between'}}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Typography key="2" color="text.primary">
                Journey
            </Typography>
        </Breadcrumbs>

        <div>
            <Button variant='contained' startIcon={<AddIcon></AddIcon>} onClick={handleAddClick}>Add Journey</Button>
        </div>
        </Box>

        <Box className={`flex mt-1`} sx={{ justifyContent: 'flex-end' }}>
            <TextField
                id="search-box"
                label="Search"
                variant="outlined"
                size="small"
                sx={{ marginRight: '5px' }}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value)
                    if (e.target.value.length > 3){
                        handleTextSearch(e)
                    } else if (e.target.value.length === 0){
                        handleTextSearch(e)
                    }
                }}
            />
            <IconButton
                onClick={handleToggleFilters}
                sx={{
                    border: '1px solid',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
                color='primary'
            >
                <FilterAltIcon color='primary' />
            </IconButton>

            <IconButton
                onClick={handleToggleSort}
                sx={{
                    border: '1px solid',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
                color='primary'
            >
                <SortIcon color='primary' />
            </IconButton>
        </Box>

        <SortComponent anchorSortEl={anchorSortEl} handleSortMenuClose={handleSortMenuClose} handleSortValue={handleSortValue}></SortComponent>
        {openFilters && (
            <>
                <FilterComponent filters={filters} setFilters={setFilters} handleApplyFilters={handleApplyFilters} handleFilterReset={handleFilterReset} ></FilterComponent>
            </>
        )}

        {journeyList.length === 0 ? (
            <Message
                style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                    color: '#696cff',
                    justifyContent: 'flex-start',
                    marginTop: '5px'
                }}
                className="border-primary w-full"
                severity="info"
                content={content}
            />
        ) : (
            <CardComponent journeyList={journeyList} setOpenEdit={setOpenEdit} setEditDetails={setEditDetails} userId={user.employeeID} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} ></CardComponent>
        )}
       
        <AddJourneyComponent open={open} setOpen={setOpen} setRunEffect={setRunEffect} showSuccess={showSuccess} showError={showError} />
         
        <EditJourneyComponent
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            editDetails={editDetails}
            setRunEffect={setRunEffect}
            showSuccess={showSuccess}
            showError={showError}
        />
        
            {totalPage !== 0 && (<PaginationComponent totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} ></PaginationComponent>)}
        </>
    );
}

export default JourneyComponent;