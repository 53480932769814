import { Box, Breadcrumbs, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import Loader from '../Reusable/Loader';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import mappingStyles from './mappingStyles.module.css';
import { useTheme } from '@emotion/react';
import { GET_APPLICATION_PROFILE, GET_EMPLOYEE_LIST, GET_LOCATION_LIST } from '../../constants';
import axios from 'axios';
import EditFormComponent from './EditFormComponent';

const EditMappingComponent = ({}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading ,setLoading] = useState(false);

    const navigate = useNavigate();
    const toast = useRef(null);

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      };

    const [employeeList, setEmployeeList] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [locationList, setLocationList] = useState([]);

    useEffect(()=>{
            axios.get(GET_EMPLOYEE_LIST)
            .then(response => {
                    const {data} = response.data;
                    setEmployeeList(data);
            })
            .catch(error => {
            });

            axios.get(GET_LOCATION_LIST)
            .then(response => {
                    const {data} = response.data;
                    setLocationList(data);
            })
            .catch(error => {
            });

            axios.get(GET_APPLICATION_PROFILE)
            .then(response => {
                    const {data} = response.data;
                    setProfileList(data);
            })
            .catch(error => {
            });
    },[]);

    

    return(
    <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Typography key="2">
                Configurations
            </Typography>
            <Link underline="hover" key="3" color="inherit" to="/configuration/userMapping" onClick={handleClick}>
                User Profile Mapping
            </Link>
            <Typography key="4" color="primary">
                Edit Mapping
            </Typography>
        </Breadcrumbs>

        <EditFormComponent employeeList={employeeList} locationList={locationList} profileList={profileList} showSuccess={showSuccess} showError={showError}></EditFormComponent>
    </>
    );

}

export default EditMappingComponent;