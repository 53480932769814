import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Reusable/Loader';
import { Autocomplete, Box, Button, Card, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import courseStyles from './courseStyles.module.css';
import axios from 'axios';
import { CREATE_COURSE, PROGRAM_CATEGORY, CATEGORY, CREATE_EMPLOYEE_MAPPING, GET_JOURNEY_LIST, GET_LOCATION_LIST, GET_PROGRAM_LIST, GET_RECORDED_SESSION_LIST } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const FormCardComponent = ({ showSuccess, showError, onSubmit, formSubmitted1 }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [loading ,setLoading] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const successData =  useSelector((state) => state.courseData.successData);

    const [journeyList, setJourneyList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [recordedSessionList, setRecordedSessionList] = useState([]);
    const [locationList, setLocationList] = useState([]);

    useEffect(()=>{
        axios.get(GET_JOURNEY_LIST)
        .then(response => {
         const {data} = response.data;
         setJourneyList(data);
        })
        .catch(error => {
        });

        axios.get(`${GET_RECORDED_SESSION_LIST}?UserID=${user.employeeID}`)
        .then(response => {
         const {data} = response.data;
         setRecordedSessionList(data);
        })
        .catch(error => {
        });

        axios.get(GET_LOCATION_LIST)
        .then(response => {
               const {data} = response.data;
               setLocationList(data);
        })
        .catch(error => {
        });

        axios.get(GET_PROGRAM_LIST)
        .then(response => {
                const {data} = response.data;         
                setProgramList(data);            
        })
        .catch(error => {
        
        });

    },[]);

   

    
    const [courseTitle, setCourseTitle] = useState(successData?.data.name || '');
    const [courseTitleError, setCourseTitleError] = useState('');
    const [courseLocationId, setCourseLocationId] = useState('');
    const [courseLocationIdError, setCourseLocationIdError] = useState('');
   
    const handleCourseTitleChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                    setCourseTitle('');
                    setCourseTitleError('Title is required.');
            } else {
                    setCourseTitle(value);
                    setCourseTitleError('');
            }
      };

    const [courseObjective, setCourseObjective] = useState(successData?.data.objective || '');
    const [courseObjectiveError, setCourseObjectiveError] = useState('');

    const handleCourseObjectiveChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                    setCourseObjective('');
                    setCourseObjectiveError('Objective is required.');
            } else {
                    setCourseObjective(value);
                    setCourseObjectiveError('');
            }
      };

    //   const [journeyID, setJourneyID] = useState('');
    //   const [journeyIDError, setJourneyIDError] = useState('');

    //   const handleJourneyChange = (e) => {
    //     const value = e.target.value;
    
    //     if (value === '') {
    //         setJourneyID('');
    //         setJourneyIDError('Journey is required.');
    //     } else {
    //         setJourneyID(value);
    //         setJourneyIDError('');
    //         handleFetchProgramApi(value);
    //     }
    //};

    const [programID, setProgramID] = useState(successData?.data.programId || '');
    const [programIDError, setProgramIDError] = useState('');

    const handleProgramChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setProgramID('');
            setProgramIDError('Program is required.');
        } else {
            setProgramID(value);
            setProgramIDError('');
        }
    };

    const [recordedSessionID, setRecordedSessionID] = useState(successData?.data.recordedSessionId || '');

    const [isGlobal, setIsGlobal] = useState(successData?.data.isGlobal || false);
    
    const handleCheckboxChange = (event) => {
        setIsGlobal(event.target.checked);
    };

    const [selectedLocation , setSelectedLocation] = useState([]);
    const [locationId, setLocationId] = useState([]);
    const [category, setCategory] = useState(successData?.data.category || '');
    const [level, setLevel] = useState(successData?.data.level || '');
    const [images, setImages] = useState(successData?.data.imageUrl || null);
    const [uploadedImage, setUploadedImages] = useState(null);
    const [imagesError, setImagesError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [levelError, setLevelError] = useState('');
   
    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Process only the first file
        if (file) {
            const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxSize = 250 * 1024; // 250KB in byte
            // Check file type
            if (!allowedExtensions.includes(file.type)) {
                showError('Only PNG and JPEG files are allowed.');
                return;
            }

            // Check file size
            if (file.size > maxSize) {
                showError('File size exceeds 250KB.');
                return;
            }

            // Create a URL for the uploaded image
            const imageURL = URL.createObjectURL(file);
            setImages(imageURL);
            setUploadedImages(file);

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        }
    };

    const handleRemoveImage = (index) => {
        setImages(null);
        setUploadedImages(null);
      };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        if (value.trim() === '') {
            setCategory(value);
            setCategoryError('Category is required.');
        } else {
            setCategory(value);
            setCategoryError('');
        }
    };

    const handleValidityChange = (e) => {
        const value = e.target.value;
        if (value.trim() === '') {
            setValidity(value);
            setValidityError('Validity is required.');
        } else {
            setValidity(value);
            setValidityError('');
        }
    };

    const handleLevelChange = (e) => {
        const value = e.target.value;
        if (value.trim() === '') {
            setLevel(value);
            setLevelError('Level is required.');
        } else {
            setLevel(value);
            setLevelError('');
        }
    };
    const [validity, setValidity] = useState(successData?.data.validity || ''); // Assign a dummy value in the correct format
    const [validityError, setValidityError] = useState( '');
    const [isTouched, setIsTouched] = useState(false);
    const now = new Date();
    const formattedDateTime = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = (e) =>{
        setFormSubmitted(true);
        e.preventDefault();
        setLoading(true);        
        let isValid = true;
        const fieldErrors = {};

        if (courseTitle.trim() === '') {
            fieldErrors.courseTitle = 'Title is required.';
            setCourseTitleError('Title is required.');
            isValid = false;
        }

        // if ((selectedLocation.length === 0) && !isGlobal) {
        //     setCourseLocationIdError('Location is required.');
        //     isValid = false;
        // }

        if (courseObjective.trim() === '') {
            fieldErrors.courseObjective = 'Objective is required.';
            setCourseObjectiveError('Objective is required.');
            isValid = false;
        }

        if (category.trim() === '') {
            fieldErrors.category = 'Category is required.';
            setCategoryError('Category is required.');
            isValid = false;
        }

        if (level.trim() === '') {
            fieldErrors.level = 'Level is required.';
            setLevelError('Level is required.');
            isValid = false;
        }
        // if (journeyID === '') {
        //     setJourneyIDError('Journey is required.');
        //     isValid = false;
        // }


        if (programID === '') {
            fieldErrors.programID = 'Program is required.';
            setProgramIDError('Program is required.');
            isValid = false;
        }

        if (!validity) {
            fieldErrors.validity = 'Date is required.';
            setValidityError('Date is required.');
            //setIsTouched(true); // Ensure the error is shown
            isValid = false;
        }

        if (!uploadedImage) {
            fieldErrors.uploadedImage = 'Image is required.';
            setImagesError('Image is required.');
            isValid = false;
        }
        if(isValid)
        {
        const formData = new FormData();
            formData.append("UserID", user.employeeID); //Login user id
            formData.append("JourneyID", 0);
            formData.append("ProgramID", programID);
            formData.append("RecordedSessionID", recordedSessionID);
            formData.append("Name", courseTitle);
            formData.append("Objective", courseObjective);
            formData.append("Category", category);
            formData.append("Level", level);
            formData.append("ImageUrl", null);
            formData.append("ContentID", null);
            formData.append("IsGlobal", isGlobal);
            formData.append("Validity",validity);
            formData.append("UpdatedBy", user.employeeID);
            formData.append("Status", 'Published');
            formData.append("PublishedOn", formattedDateTime);
            formData.append("PublishedBy", user.employeeID);
            formData.append("file",uploadedImage);


        axios.post(CREATE_COURSE,formData)
        .then(response => {
              showSuccess(response.data.message);
              setLoading(false);
              onSubmit(response.data);
             
        })
        .catch(error => {
            if (error.response) {
                const { data } = error.response;
                if (data.errors) {
                const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                    `${key}: ${messages.join(', ')}`
                ));
                    showError(formattedErrors);
                    setLoading(false);
                }else {
                    showError(data.title || 'An error occurred.');
                    setLoading(false);
                }
                } else {
                    showError('Intenal Server Error.');
                    setLoading(false);
                }
        });
    }
    else{
        console.log('Form submission blocked due to validation errors.');
        setLoading(false);
        showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
    }
    };

    const handleSaveAsDraftSubmit = (e) =>{
        setFormSubmitted(true);
        e.preventDefault();
        let isValid = true;
        const fieldErrors = {};
        setLoading(true);

        if (courseTitle.trim() === '') {
            fieldErrors.courseTitle = 'Title is required.';
            setCourseTitleError('Title is required.');
            isValid = false;
        }

        // if ((selectedLocation.length === 0) && !isGlobal) {
        //     setCourseLocationIdError('Location is required.');
        //     isValid = false;
        // }

        if (courseObjective.trim() === '') {
            fieldErrors.courseObjective = 'Objective is required.';
            setCourseObjectiveError('Objective is required.');
            isValid = false;
        }

        if (category.trim() === '') {
            fieldErrors.category = 'Category is required.';
            setCategoryError('Category is required.');
            isValid = false;
        }

        if (level.trim() === '') {
            fieldErrors.level = 'Level is required.';
            setLevelError('Level is required.');
            isValid = false;
        }
        // if (journeyID === '') {
        //     setJourneyIDError('Journey is required.');
        //     isValid = false;
        // }

        if (programID === '') {
            fieldErrors.programID = 'Program is required.';
            setProgramIDError('Program is required.');
            isValid = false;
        }

        if (!validity) {
            fieldErrors.validity = 'Date is required.';
            setValidityError('Date is required.');
            //setIsTouched(true); // Ensure the error is shown
            isValid = false;
        }

        if (!uploadedImage) {
            fieldErrors.uploadedImage = 'Image is required.';
            setImagesError('Image is required.');
            isValid = false;
        }
        if (isValid) {
        const formData = new FormData();
            formData.append("UserID", user.employeeID); //Login user id
            formData.append("JourneyID", 0);
            formData.append("ProgramID", programID);
            formData.append("RecordedSessionID", recordedSessionID);
            formData.append("Name", courseTitle);
            formData.append("Objective", courseObjective);
            formData.append("Category", category);
            formData.append("Level", level);
            formData.append("ImageUrl", '');
            formData.append("ContentID", 0);
            formData.append("IsGlobal", isGlobal);
            formData.append("Validity",validity);
            formData.append("UpdatedBy", user.employeeID);
            formData.append("Status", 'Draft');
            formData.append("PublishedOn", '');
            formData.append("PublishedBy", '');
            formData.append("file",uploadedImage);

            console.log(formData);

            axios.post(CREATE_COURSE, formData)
                .then(response => {         
                    // After successful submission, close the dialog
                    showSuccess(response.data.message);
                    setLoading(false);

                    onSubmit(response.data);
                    // setTimeout(() => {
                    //     navigate('/courses');
                    // }, 1000); // Delay for 1 seconds
                    
                }).catch(error => {
                    if (error.response) {
                        const { data } = error.response;
                        if (data.errors) {
                        const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                            `${key}: ${messages.join(', ')}`
                        ));
                            showError(formattedErrors);
                            setLoading(false);
                        }else {
                            showError(data.title || 'An error occurred.');
                            setLoading(false);
                        }
                        } else {
                            showError('Intenal Server Error.');
                            setLoading(false);
                        }
                });

        } else{
            console.log('Form submission blocked due to validation errors.');
            setLoading(false);
            showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
        }


    };

    const handlePublishSubmit = () =>{};
    const today = dayjs().startOf('day'); // Get today's date
    
    // Function to validate the date field
    const validateValidity = (value) => {
        if (!value || value === 'Invalid Date') {
            setValidityError('Validity is required');
        } else if (dayjs(value).isBefore(dayjs().startOf('day'))) {
            setValidityError('Enter a valid date'); // Error for dates before today
        } else {
            setValidityError('');
        }
    };
    const handleReset = () => {
        setFormSubmitted(false);
        setCourseTitleError('');
        setCourseLocationIdError('');
        setCourseObjectiveError('');
        setCategoryError('');
        setLevelError('');
        setLevel('');
        setCategory('');
        setRecordedSessionID('');
        //setJourneyIDError('');
        setProgramIDError('');
        setValidityError('');
        setIsTouched(false);
        setImagesError('');
        setSelectedLocation([]);
        setIsGlobal(false);
        setImages(null);
        setUploadedImages(null);
        setCourseTitle('');
        setCourseObjective('');
        //setJourneyID('');
        setProgramID('');
        setValidity('');
        setCourseLocationIdError('');
    };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Card className={`${courseStyles.form_card_style} mt-2`}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{alignItems: 'center'}}>
                    {/* Title and Objective */}
                    <Grid item xs={12} md={12} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Title"
                            size='small'
                            name='title'
                            fullWidth
                            value={courseTitle}
                            onChange={handleCourseTitleChange}
                            required
                            error={!!courseTitleError}
                            helperText={courseTitleError}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <TextField
                            id="outlined-objective"
                            label="Objective"
                            size='small'
                            name='objective'
                            multiline
                            rows={4}
                            fullWidth
                            value={courseObjective}
                            onChange={handleCourseObjectiveChange}
                            required
                            error={!!courseObjectiveError}
                            helperText={courseObjectiveError}
                            inputProps={{ maxLength: 500 }}
                        />
                    </Grid>   

                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-select-currency"
                            label="Category"
                            name='category'
                            value={category}
                            select                            
                            size='small'
                            fullWidth
                            required
                            onChange={(e) => {handleCategoryChange(e)}}
                            error={!!categoryError}
                            helperText={categoryError}
                            >
                            {CATEGORY.map((c) => (
                                <MenuItem value={c.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                        
                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-select-level"
                            label="Level"
                            name='level'
                            value={level}
                            select                          
                            size='small'
                            fullWidth
                            required
                            onChange={(e) => {handleLevelChange(e)}}
                            error={!!levelError}
                            helperText={levelError}
                            >
                            {PROGRAM_CATEGORY.map((c) => (
                                <MenuItem value={c.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* Journey and Program */}
                    {/* <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-journey"
                            label="Journey"
                            name='journeyID'
                            value={journeyID}
                            select
                            size='small'
                            fullWidth
                            required
                            error={!!journeyIDError}
                            helperText={journeyIDError}
                            onChange={handleJourneyChange}
                        >
                            {journeyList.map((jL) => (
                                <MenuItem key={jL.id} value={jL.id}>
                                    {jL.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>  */}
                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-program"
                            label="Program"
                            name='programID'
                            value={programID}
                            select
                            size='small'
                            fullWidth
                            required
                            error={!!programIDError}
                            helperText={programIDError}
                            onChange={handleProgramChange}
                        >
                            {programList.map((jL) => (
                                <MenuItem key={jL.id} value={jL.id}>
                                    {jL.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* Recorded Session and Validity */}
                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-recorded-session"
                            label="Recorded Session"
                            name='recordedSession'
                            value={recordedSessionID}
                            select
                            size='small'
                            fullWidth
                            onChange={(e) => setRecordedSessionID(e.target.value)}
                        >
                            {recordedSessionList.map((jL) => (
                                <MenuItem key={jL.id} value={jL.id}>
                                    {jL.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <DatePicker
                            label="Validity"
                            sx={{ 
                                width: '100%',
                                // Adjust the height of the input field
                                '& .MuiInputBase-root': {
                                    height: '40px', // Adjust the height as needed
                                    '& input': {
                                        height: '100%',
                                        padding: '0 16px', // Adjust padding as needed
                                    }
                                },
                            }}
                            value={dayjs(validity)}
                            onChange={(date) => {
                                    const formattedDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : '';
                                    setValidity(formattedDate);

                                    // Validate when user is interacting with the field
                                    if (formSubmitted) {
                                        validateValidity(formattedDate);
                                    }
                            }}
                            minDate={today}
                            slotProps={{
                                field: { clearable: true },
                                textField: {
                                    helperText: validityError,                                   
                                    error: formSubmitted && !!validityError, 
                                  },
                              }}

                              renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Validity" 
                                    placeholder="Validity" 
                                    required 
                                    error={!!validityError && isTouched}
                                    helperText={isTouched ? validityError : ''}
                                />
                            )}
                        />
                    </Grid>

                    {/* Is Global and Location */}
                    <Grid item xs={12} md={6} xl={6}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={isGlobal} 
                                    onChange={handleCheckboxChange} 
                                />
                            }
                            label="Is Global?"
                            labelPlacement="start"
                        />
                    </Grid>
                    
                    {/* {!isGlobal && (
                        <Grid item xs={12} md={6} xl={6}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={locationList}
                                value={selectedLocation}
                                size='small'
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) =>{ setSelectedLocation(newValue)
                                    const selectedIds = newValue.map(location => location.id);
                                    setLocationId(selectedIds)
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Location" 
                                        placeholder="Location" 
                                        required 
                                        //error={selectedLocation.length === 0} 
                                        //helperText={selectedLocation.length === 0 ? 'This field is required' : ''} 
                                        error={formSubmitted && selectedLocation.length === 0} 
                                        helperText={formSubmitted && selectedLocation.length === 0 ? 'Location is required' : ''} 
                                   
                                    />
                                )}
                            />
                        </Grid>
                    )} */}

                    {/* Upload Images */}
                     <Grid item xs={12} md={6} xl={6}>
                            <div className={`px-2`}>
                                <Typography variant="body2">Upload Images <span style={{ color: 'red' }}>*</span></Typography>

                                {!images ? (
                                    <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                        required
                                        error={!!imagesError}
                                    >
                                        Upload Image
                                        <input
                                            accept=".png, .jpeg, .jpg"
                                            type="file"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                            Only PNG and JPEG files are allowed. Size limit 250KB
                                    </p>
                                    {imagesError && (
                                        <FormHelperText error>{imagesError}</FormHelperText>
                                    )}
                                    </>
                                ) : (
                                    <div>
                                        <img src={images} alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <IconButton
                                            onClick={handleRemoveImage}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </Grid>

                   
                </Grid>

                {/* Action Buttons */}
                {!formSubmitted1 && (<div className={`float-right m-2`}>
                    <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                    <Button type="submit" onClick={handleSaveAsDraftSubmit} variant='contained' color="warning" sx={{marginLeft: '0.5rem'}}>Save as Draft</Button>
                    <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Publish Now</Button>
                </div>)}
            </Box>
        </Card>

        </>
    );
}

export default FormCardComponent;