import React from 'react';
import { useSelector } from 'react-redux';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './course.css';
import { Divider } from 'primereact/divider'; 
import { Tag } from 'primereact/tag';
import moment from 'moment';
import { Box, Breadcrumbs, Button, colors, Grid, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import LongTextComponent from '../Reusable/LongTextComponent';
import { useTheme } from '@emotion/react';

const CoursePreviewComponent = () => {

    const previewDetails = useSelector((state) => state.courseData.previewData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.between('sm', 'md')); // Tablet (between 600px and 960px)

    // Function to extract file extension from URL
    const getFileExtension = (type,url, link) => {
        if(link != null){
            if ( type == "youtube"){
                return 'youtube'
            } else{
                return 'link'
            }
        } else if (url != null){
            const parts = url.split('.');
            return parts.length > 1 ? parts.pop().toLowerCase() : 'unknown';
        }
    };

    const eventsArray = previewDetails.courseContents.map(content => {
        const fileExt = getFileExtension(content.contentType,content.contentUrl, content.externalLink);
        return {
            title: content.title,
            description: content.description,
            noOfPages: content.noOfPages,
            duration: content.duration,
            contentType: content.contentType,
            contentUrl: content.contentUrl,
            externalLink: content.externalLink,
            fileExt: fileExt,
            url:  content.contentUrl 
        }
    });

 

    const totalDuration = eventsArray.reduce((total, content) => {
        // Assuming content.duration is a number representing the duration in minutes
        return total + (content.duration || 0);  // Default to 0 if duration is not available
    }, 0);
    
    console.log("Total Duration:", totalDuration, "minutes");

    const iconType = [
        {type: 'pdf', icon: 'pi pi-file-pdf', color: "#d50032"},
        {type: 'excel', icon: 'pi pi-file-excel', color: "#4caf50"},
        {type: 'word', icon: 'pi pi-file-word', color: "#1976d2"},
        {type: 'ppt', icon: 'pi pi-file-powerpoint', color: "#e64a19"},
        {type: 'video', icon: 'pi pi-video', color: "#E85C0D"},
        {type: 'youtube', icon: 'pi pi-youtube', color: "#C7253E"},
        {type: 'link', icon: 'pi pi-link', color: "#4535C1"},
        {type: 'default', icon: 'pi pi-file', color: "#9e9e9e"} // Default icon for unsupported types
    ];

    

    // Function to get the icon class based on file extension
    const getIconByExtension = (ext) => {
     
        const iconMapping = {
            'pdf': 'pi pi-file-pdf',
            'xls': 'pi pi-file-excel',
            'xlsx': 'pi pi-file-excel',
            'doc': 'pi pi-file-word',
            'docx': 'pi pi-file-word',
            'ppt': 'pi pi-file-powerpoint',
            'pptx': 'pi pi-file-powerpoint',
            'mp4': 'pi pi-video',
            'avi': 'pi pi-video',
            'jpg': 'pi pi-image',
            'jpeg': 'pi pi-image',
            'png': 'pi pi-image',
            'gif': 'pi pi-image',
            'link': 'pi pi-link',
            'youtube': 'pi pi-youtube'
        };
        return iconMapping[ext] || 'pi pi-file'; // Default icon
    };


    const getColorByExtension = (ext) => {
        console.log(ext)
        const colorMapping = {
            'pdf': '#D32F2F',
            'xls': '#388E3C',
            'xlsx': '#388E3C',
            'doc': '#1976D2',
            'docx': '#1976D2',
            'ppt': '#F57C00',
            'pptx': '#F57C00',
            'mp4': '#E85C0D',
            'avi': '#E85C0D',
            'jpg': '#FFC107',
            'jpeg': '#FFC107',
            'png': '#FFC107',
            'gif': '#FFC107',
            'link': '#4535C1',
            'youtube': '#C7253E'
        };
        return colorMapping[ext] || 'grey'; // Default color
    };

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
                <i className='pi pi-circle'  
                style={{
                    color: 'lightblue',
                    fontSize: '1.5rem',
                    border: '3px solid lightblue',
                    borderRadius: '50%',
                }}
                >
                </i>
            </span>
        );
    };

    const customizedContent = (item) => {
        const hasContent = item && item.title && item.contentUrl;

        return (
            <Card 
                style={{ 
                    marginBottom: '20px', 
                    borderLeft: '8px solid',  
                    borderLeftColor: getColorByExtension(item.fileExt), 
                    borderRadius: '5px',
                    padding: '16px',
                    position: 'relative' // Ensures the date can be positioned relative to the card
                }}
            >
                <Grid 
                    container 
                    spacing={2} 
                    direction={isMobile ? 'column' : 'row'} 
                    alignItems={isMobile ? 'flex-start' : 'center'}
                >
                    {/* Date Section - Positioned at top right */}
                    <Grid 
                        item 
                        xs={12} 
                        style={{ 
                            position: 'absolute', 
                            top: 0, 
                            right: 16, 
                            fontSize: '0.9rem', 
                            color: 'gray',
                            textAlign: 'right',
                            marginBottom: isMobile ? '16px' : '0' // Adds space in mobile view
                        }}
                    >
                        <i className='pi pi-calendar mr-2' style={{color: 'green'}}></i> 
                        {formatDate(item.createdOn)}
                    </Grid>

                    {/* Icon Section */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={2} 
                        md={2} 
                        style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start',  marginTop: isMobile ? '10px' : '0' }}
                    >
                        <i
                            className={getIconByExtension(item.fileExt)}
                            style={{ fontSize: '3rem', color: getColorByExtension(item.fileExt) }}
                        />
                    </Grid>
                    
                    {/* Content Section */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={7}
                    >
                        <div>
                            <Typography variant="h6" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom sx={{wordBreak: 'break-all', width: '80%'}}>
                                <LongTextComponent text={item.description} maxLength={20} />
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <i className='pi pi-clock mr-2' style={{color: 'green'}}></i> 
                                {item.duration} Mins
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <i className='pi pi-hashtag mr-2' style={{color: 'green'}}></i> 
                                {item.noOfPages} Pages
                            </Typography>
                        </div>
                    </Grid>

                    {/* Button Section */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={4} 
                        md={3} 
                        style={{ 
                            textAlign: isMobile ? 'center' : isTablet ? 'left' : 'right', 
                            marginTop: isMobile ? '10px' : '0' 
                        }}
                    >
                        {(item.contentUrl || item.externalLink) && (
                            <Button 
                                variant='contained' 
                                color='primary'
                                onClick={() =>{const link = item.contentUrl || item.externalLink;
                                    const validLink = link.startsWith('http') ? link : `https://${link}`;
                                    window.open(validLink, '_blank');}}
                            >
                                <i className='pi pi-external-link mr-2'></i> View
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Card>


        );
    };

    const formatDate = (dateString) => moment(dateString).format('MMM D, YYYY');

    const handleClick = (event) => {
        console.info('You clicked a breadcrumb.');
    };
console.log(previewDetails)
    return (
        <>
            <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                    Home
                </Link>
                <Link underline="hover" key="3" color="inherit" to="/courses" onClick={handleClick}>
                    Course
                </Link>
                <Typography key="4" color="primary">
                    View Course
                </Typography>
            </Breadcrumbs>

            <Card style={{ marginBottom: '20px' }}>
            <Grid container spacing={2}>
                {/* Image Section */}
                <Grid item xs={12} sm={3}>
                    <img
                        src={previewDetails.imageUrl}
                        alt={previewDetails.name}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
                {/* Content Section */}
                <Grid item xs={12} sm={9}>
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="h4" component="div" gutterBottom>
                            {previewDetails.name}
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ marginBottom: 1}}>
                            <LongTextComponent text={previewDetails.objective} maxLength={50}></LongTextComponent>
                        </Typography>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 2 }}>
                                        Objective:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {previewDetails.objective}
                                    </Typography>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Category:
                                    </Typography>
                                    <Tag severity="success" value={previewDetails.category} style={{ width: 'fit-content' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Level:
                                    </Typography>
                                    <Tag severity="info" value={previewDetails.level} style={{ width: 'fit-content' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Expiry Date:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {formatDate(previewDetails.validity)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Duration:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {/* Duration data goes here */}
                                        {totalDuration} Mins
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Created By:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {previewDetails.createdBy}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Created On:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {formatDate(previewDetails.createdOn)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Published By:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {previewDetails.publishedBy}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Published On:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {formatDate(previewDetails.publishedOn)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', marginRight: 1 }}>
                                        Status:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                    <Tag severity="success" value={previewDetails.status} style={{ width: 'fit-content' }} />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Card>

            <div className="timeline-wrapper">
                {eventsArray.length === 0 ? (
                    <p style={{ textAlign: 'center', fontSize: '1.5rem', margin: '20px' }}>No Content Uploaded yet.</p>
                ) : (
                    <Timeline value={eventsArray} align="left" marker={customizedMarker} content={customizedContent} className="timeline" />
                )}
            </div>
        </>
    );
}

export default CoursePreviewComponent;
