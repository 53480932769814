import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Reusable/Loader';
import { Autocomplete, Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import sessionStyle from './sessionStyles.module.css';
import { Add, Delete } from '@mui/icons-material';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  } from '@mui/material';
import { ASSESSMENT_LIST } from '../../constants';
import axios from 'axios';

const AssessmentFormComponent = ({onSubmit, onBack, onReset, showSuccess, showError }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading ,setLoading] = useState(false);

    const [preAssessmentList, setPreAssessmentList] = useState('');
    const [postAssessmentList, setPostAssessmentList] = useState('');

    const [entries, setEntries] = useState([{ assessmentType: '', assessmentName: '', validity: '' }]);

        const handleAddEntry = () => {
            setEntries([...entries, { assessmentType: '', assessmentName: '', validity: '' }]);
        };

        const handleRemoveEntry = (index) => {
            const updatedEntries = entries.filter((_, i) => i !== index);
            setEntries(updatedEntries);
        };

        const handleChange = (index, field, value) => {
            const updatedEntries = entries.map((entry, i) =>
            i === index ? { ...entry, [field]: value } : entry
            );
            setEntries(updatedEntries);
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            // Handle the form submission here (e.g., send data to an API)
            console.log('Form data:', entries);
        };
        
        const [dropdownLoading, setDropdownLoading] = useState(false);
        const [assessmentDropdown, setAssessmentDropdown] = useState([]);
         // Common function to fetch dropdown data
        const fetchDropdownData = async (url, setter) => {
            setDropdownLoading(true);
            try {
            const response = await axios.get(url);
            setter(response.data.data);
            } catch (error) {
            console.error('Error fetching data:', error);
            } finally {
                setDropdownLoading(false);
            }
        };

    return(
        <>
         {loading && <Loader open={loading} />}
         <form onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell align="center">Assessment Type</TableCell>
                    <TableCell align="center">Asessment Name</TableCell>
                    <TableCell align="center">Validity</TableCell>
                    <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entries.map((entry, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">
                        <TextField
                            value={entry.assessmentType}
                            onChange={(e) => handleChange(index, 'assessmentType', e.target.value)}
                            placeholder="Assessment Type"
                            fullWidth
                        />
                        </TableCell>
                        <TableCell align="center">
                        <TextField
                            value={entry.assessmentName}
                            onChange={(e) => handleChange(index, 'assessmentName', e.target.value)}
                            placeholder="Name"
                            fullWidth
                        />
                        <Autocomplete
                            value={entry.assessmentName}
                            onChange={(event, newValue) =>  handleChange(index, 'assessmentName', newValue)}
                            onFocus={() => {
                            if (!assessmentDropdown.length) {
                                fetchDropdownData(ASSESSMENT_LIST, assessmentDropdown); // Fetch on focus
                            }
                            }}
                            options={assessmentDropdown}
                            getOptionLabel={(option) => (option?.state ? option.state : '')} // Prevent undefined from showing
                            isOptionEqualToValue={(option, value) => option?.state === value?.state} // Ensure proper equality
                            loading={dropdownLoading}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="State"
                                size="small"
                                InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                    {dropdownLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    </>
                                ),
                                }}
                            />
                            )}
                        />
                        </TableCell>
                        <TableCell align="center">
                        <TextField
                            value={entry.validity}
                            onChange={(e) => handleChange(index, 'validity', e.target.value)}
                            fullWidth
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        </TableCell>
                        <TableCell align="center">
                        <IconButton
                            onClick={() => handleRemoveEntry(index)}
                            disabled={entries.length === 1}
                        >
                            <Delete />
                        </IconButton>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
                <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddEntry}
                    startIcon={<Add />}
                >
                    Add Entry
                </Button>
                </Grid>
                <Grid item>
                <Button type="submit" variant="contained" color="secondary">
                    Submit
                </Button>
                </Grid>
            </Grid>
        </form>
        
        </>
    );
}

export default AssessmentFormComponent;