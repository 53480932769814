import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const SideMenuComponent = ({ anchorEl, handleMenuClose, onEditClick, onDeleteClick , onViewClick}) => {
    const isMenuOpen = Boolean(anchorEl);

    return (
        <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ '& .MuiPaper-root': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' } }} // Reduced shadow
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
             <MenuItem onClick={onViewClick} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px',marginTop: '8px',}}>
            <VisibilityIcon className={`mr-2`}></VisibilityIcon>
            View</MenuItem>
            <MenuItem onClick={onEditClick} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px',marginTop: '8px',}}>
            <CreateIcon className={`mr-2`}></CreateIcon>
            Edit</MenuItem>
            <MenuItem onClick={onDeleteClick}>
            <DeleteIcon className={`mr-2`}></DeleteIcon>
            Delete</MenuItem>
        </Menu>
    );
};

export default SideMenuComponent;
