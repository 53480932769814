export const CATEGORY = [
    {id: 1, label: 'Functional'},
    {id: 2, label: 'Behavioural'},
    {id: 3, label: 'Technical'}
];

export const PROGRAM_CATEGORY = [
    {id: 1, label: 'Beginner'},
    {id: 2, label: 'Intermediate'},
    {id: 3, label: 'Advanced'},
    {id: 4, label: 'Expert'}
];

export const STATUS = [
    {id: 1, label: 'All'},
    {id: 2, label: 'Draft'},
    {id: 3, label: 'Published'}
];

export const SORT = [
    {id: 1, label: 'CreatedDate'},
    {id: 2, label: 'PublishedDate'},
    {id: 2, label: 'NameAsc'},
    {id: 2, label: 'NameDesc'},
];


export const BASE_URL = 'https://lms-api-qa.abisaio.com/api'

export const AUTH_API = BASE_URL + '/v1/Login';
//Journey API
export const CREATE_JOURNEY = BASE_URL + '/v1/Journey/CreateJourney';
export const GET_JOURNEY = BASE_URL + '/v1/Journey/GetJourney';
export const GET_JOURNEY_LIST = BASE_URL + '/v1/Journey/GetJourneyList';
export const EDIT_JOURNEY_GET = BASE_URL + '/v1/Journey/EditJourney';
export const EDIT_JOURNEY_POST = BASE_URL + '/v1/Journey/EditJourney';
export const DELETE_JOURNEY = BASE_URL + '/v1/Journey/DisableJourney';
//  export const GET_JOURNEY = '/lms-api/v1/Journey/GetJourney';
//Program API
export const CREATE_PROGRAM = BASE_URL + '/v1/Program/CreateProgram';
export const GET_PROGRAM = BASE_URL + '/v1/Program/GetProgram';
export const EDIT_PROGRAM_GET = BASE_URL + '/v1/Program/EditProgram';
export const EDIT_PROGRAM_POST = BASE_URL + '/v1/Program/EditProgram';
export const DELETE_PROGRAM = BASE_URL + '/v1/Program/DisableProgram';
export const GET_PROGRAM_LIST = BASE_URL + '/v1/Program/GetProgramList';
//Courses API
export const GET_COURSES = BASE_URL + '/v1/Course/GetCourse';
export const CREATE_COURSE = BASE_URL + '/v1/Course/CreateCourse';
export const CREATE_COURSE_CONTENT = BASE_URL + '/v1/Course/CreateCourseContent';
export const GET_COURSE_CONTENT = BASE_URL + '/v1/Course/GetCourseContent';
export const GET_EDIT_COURSE = BASE_URL + '/v1/Course/EditCourse';
export const POST_EDIT_COURSE = BASE_URL + '/v1/Course/EditCourse';
export const GET_EDIT_COURSE_CONTENT = BASE_URL + '/v1/Course/EditCourseContent';
export const POST_EDIT_COURSE_CONTENT = BASE_URL + '/v1/Course/EditCourseContent';
export const GET_COURSE_LIST = BASE_URL + '/v1/Course/GetCourseList';
export const GET_RECORDED_SESSION_LIST = BASE_URL + '/v1/Course/GetRecordedSessionList';
export const DELETE_COURSE = BASE_URL + '/v1/Course/DisableCourse';
export const DELETE_COURSE_CONTENT = BASE_URL + '/v1/Course/DisableCourseContent';
export const EDIT_COURSE_CONTENT = BASE_URL + '/v1/Course/EditCourseContent';

//User Mapping API
export const GET_USER_PROFILE_MAPPING = BASE_URL + '/v1/ApplicationProfile/GetEmployeeProfileMapping';
export const GET_APPLICATION_PROFILE = BASE_URL + '/v1/ApplicationProfile/GetApplicationProfileList';
export const GET_LOCATION_LIST = BASE_URL + '/v1/ApplicationProfile/GetLocationsList';
export const GET_EMPLOYEE_LIST = BASE_URL + '/v1/ApplicationProfile/GetEmployeesList';
export const CREATE_EMPLOYEE_MAPPING = BASE_URL + '/v1/ApplicationProfile/CreateEmployeeProfileMapping';
export const GET_EDIT_PROFILE_MAPPING = BASE_URL + '/v1/ApplicationProfile/EditEmployeeProfileMapping';
export const POST_EDIT_PROFILE_MAPPING = BASE_URL + '/v1/ApplicationProfile/EditEmployeeProfileMapping';
export const DELETE_USER_MAPPING = BASE_URL + '/v1/ApplicationProfile/DisableEmployeeProfileMapping';

//Training Session
export const GET_TRAINING_SESSION = BASE_URL + '/v1/TrainingSession/GetTrainingSession';
export const CREATE_TRAINING_SESSION = BASE_URL + '/v1/TrainingSession/CreateTrainingSession';
export const GET_TRAINING_LOCATION = BASE_URL + '/v1/TrainingSession/GetTrainingLocation';
export const GET_TRAINING_STATE = BASE_URL + '/v1/TrainingSession/GetTrainingState';
export const GET_TRAINING_DESIGNATION =BASE_URL + '/v1/TrainingSession/GetTrainingDesignation';
export const GET_TRAINING_GRADE = BASE_URL + '/v1/TrainingSession/GetTrainingGrade';
export const GET_TRAINING_DEPARTMENT = BASE_URL + '/v1/TrainingSession/GetTrainingDepartment';
export const GET_TRAINING_SUB_DEPARTMENT = BASE_URL + '/v1/TrainingSession/GetTrainingSubDepartment';
export const GET_TRAINING_ROLE = BASE_URL + '/v1/TrainingSession/GetTrainingRole';
export const EDIT_TRAINING_SESSION_GET = BASE_URL + '/v1/TrainingSession/EditTrainingSession';
export const EDIT_TRAINING_SESSION_POST = BASE_URL + '/v1/TrainingSession/EditTrainingSession';
export const GET_TRAINING_VENUE = BASE_URL + '/v1/TrainingSession/GetTrainingVenue';
export const GET_TRAINER = BASE_URL + '/v1/TrainingSession/GetTrainer';
export const GET_TRAINING_EMPLOYEES = BASE_URL + '/v1/TrainingSession/GetTrainingEmployees';
export const CREATE_TRAINING_BATCH = BASE_URL + '/v1/TrainingSession/CreateTrainingSessionBatch';
export const ASSESSMENT_LIST = BASE_URL + '/v1/Assessment/GetAssessmentListAsync';
export const BATCH_MASTER_LIST = BASE_URL + '/v1/TrainingSession/GetBatchMasterList';
export const BATCH_MASTER_EMPLOYEE_LIST = BASE_URL + '/v1/TrainingSession/GetBatchMasterEmployeesList';
export const UPDATE_BATCH_PARTICIPANTS = BASE_URL + '/v1/TrainingSession/UpdateBatchParticipants';
export const GET_BATCH_PARTICIPANT_LIST = BASE_URL + '/v1/TrainingSession/GetBatchParticipantsList';


export const CREATE_ASSESSMENT = BASE_URL + '/v1/Assessment/CreateWithQuestions';
export const GET_ASSESSMENT_LIST = BASE_URL + '/v1/Assessment/GetAssessmentListAsync';
