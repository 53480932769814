import { Box, Breadcrumbs, Card, CardContent, Chip, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import courseStyles from './courseStyles.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { DELETE_COURSE_CONTENT, GET_EDIT_COURSE, GET_EDIT_COURSE_CONTENT } from '../../constants';
import axios from 'axios';
import { setEditData } from '../../features/courseData/courseSlice';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit'; 
import LongTextComponent from '../Reusable/LongTextComponent';


const UploadedContentCardDesignComponent = ({ content , userId, showSuccess, showError, type, openDetails, setOpenDetails, setPickedType, SetshowForm, setEditContentData, setLoading}) => {

    
    const dispatch = useDispatch();



    console.log(content)

    const iconType = [
        {type: 'document', icon: 'pi pi-file'},
        {type:'video' , icon: 'pi pi-video'},
        {type: 'youtube', icon: 'pi pi-youtube'},
        {type: 'link', icon: 'pi pi-link'}
    ];

    // Function to get the icon based on type
        const getIconByType = (type) => {
            const iconObj = iconType.find(item => item.type === type);
            return iconObj ? iconObj.icon : null;
        };

    
    const fetchContent = () => {
            axios.get(`${GET_EDIT_COURSE}?courseID=${content[0].courseID}`)
            .then(response => {
                const { data } = response.data;
                dispatch(setEditData(data));
                
            })
            .catch(error => {
                console.error("Error fetching journey details:", error);
            });
        };

        const onDeleteClick = (id) =>{
            axios.post(`${DELETE_COURSE_CONTENT}?CourseContentId=${id}&UserID=${userId}`)
            .then(response => {
                const { data, message } = response.data;
                showSuccess(message);
                
                fetchContent();
                
            })
            .catch(error => {
                console.error("Error fetching journey details:", error);
            });
        };

        const onEditClick = (id, contentType) =>{
            setOpenDetails(true);
            SetshowForm(true)
            // setLoading(true);
            setPickedType(contentType);
            fetchEditData(id);
        };

        const fetchEditData = (id) => {
            setLoading(true);
            axios.get(`${GET_EDIT_COURSE_CONTENT}?CourseContentId=${id}`)
            .then(response => {
                const { data, message } = response.data;
                setEditContentData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching journey details:", error);
                setLoading(false);
            });
        };

    return(
        <>
        <Grid container spacing={2} sx={{marginTop: '10px'}}>
            {content.map((ct, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                <Card sx={{ position: 'relative', boxShadow: 3 }}>
                    <CardContent sx={{ padding: '16px 24px' }}>
                    <Box display="flex" alignItems="center">
                        {/* Icon Section */}
                        <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '64px', // Ensure consistent icon sizing
                            height: '64px',
                            mr: 2,
                        }}
                        >
                        <i
                            className={getIconByType(ct.contentType)}
                            style={{ fontSize: '2.5rem', color: '#4caf50' }} // Adjust icon size and color
                        ></i>
                        </Box>

                        {/* Content Section */}
                        <Box sx={{ flex: 1 }}>
                        <Typography gutterBottom variant="h6" component="div">
                            {ct.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{wordBreak: 'break-all', width: '80%'}}>
                            <LongTextComponent text={ct.description} maxLength={10}></LongTextComponent>
                        </Typography>

                        <Box
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                            {ct.noOfPages} Pages
                            </Typography>
                            <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1, height: '20px' }}
                            />
                            <Typography variant="body2" color="text.secondary">
                            {ct.duration} Mins
                            </Typography>
                        </Box>
                        </Box>

                        {type === 'edit' && (<IconButton onClick={()=> onEditClick(ct.id, ct.contentType)}>
                            <EditIcon></EditIcon>
                        </IconButton>)}
                        <IconButton onClick={() => onDeleteClick(ct.id)}>
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Box>
                    </CardContent>
                </Card>
                </Grid>
            ))}
            </Grid>

           

        
        </>
    );

}

export default UploadedContentCardDesignComponent;