import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import LoginAppBar from './LoginAppBar';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import PersonIcon from '@mui/icons-material/Person';
import { Box,  Card, CardContent, IconButton, Stack, useMediaQuery } from '@mui/material';
import { Password } from 'primereact/password';
import { styled } from '@mui/material/styles';
import { Button } from 'primereact/button';
import ib_logo from '../../Logo/IB_logo.png';
import loginStyles from './loginStyles.module.css';
import './login.css';
import { Link } from 'react-router-dom';
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { AUTH_API} from '../../constants';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Loader from '../Reusable/Loader';

const LoginComponent = ({}) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [loading ,setLoading] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const showSuccess = (content) => {
        toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
    }

    const showError = (content) => {
        toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
       
    const [checked, setChecked] = useState(false);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleLoginSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);
        const userData ={
            username: username , 
            password: password
        };
        
        axios.post(AUTH_API, userData)
        .then(response => {    
            const { data } = response.data;    
            // After successful submission, close the dialog
            // handleClose();
            showSuccess('Login Successful !!');
            dispatch(login(data));
            
            navigate('/dashboard');
            setLoading(false);
                    
        }).catch(error => {
            console.log(error.response.data.message);
            showError(error.response.data.message);
            setLoading(false);
        });
        
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLoginSubmit(event);
        }
    };

    const handleLogout = () => {
        dispatch(logout());
      };

 return(
    <>
    {/* <LoginAppBar></LoginAppBar> */}

    <Toast ref={toast} />
    {loading && <Loader open={loading} />}
    <Box 
        sx={{
            // height: 'calc(100vh - 64px)',
            height: '100vh',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            
        }}
        className={`${loginStyles.background_image}`}
    >
    <div className={`flex ${isMobile? `${loginStyles.margin_login_mobile}` : `${loginStyles.margin_login_desktop}`}`}>
    <Stack spacing={3} direction="column" className={`py-4 px-4`}>
    <form onSubmit={handleLoginSubmit} onKeyDown={handleKeyDown}>
    <div className={`flex place-content-center h-48 w-auto`}>
        <img
            src={ib_logo}
            loading="lazy"
            alt='onCall'
            className={`w-48`}
        />
    </div>
    <div className={`mt-2`}>
    <IconField iconPosition="left" style={{marginBottom: '1rem'}}>
        <InputIcon className="pi pi-user" style={{paddingLeft: '0.5rem'}} />
        <InputText  placeholder="Username" value={username} onChange={(e)=> setUserName(e.target.value)}
        />
    </IconField>

    <IconField iconPosition="left" style={{marginTop: '1rem'}}>
        <InputIcon className="pi pi-key" style={{paddingLeft: '0.5rem'}} />
        <InputText type={passwordVisible ? 'text' : 'password'}  placeholder="Password" value={password} onChange={(e)=> setPassword(e.target.value)} />
        <IconButton
        onClick={togglePasswordVisibility}
        style={{ position: 'absolute', right: '10px', top: '10px', color: 'white' }}
        >
            <i className={passwordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'} />
        </IconButton>
      </IconField>
    </div>

    <div className="flex flex-row justify-between">
        <div>
        <Checkbox inputId="check" name="checkbox" value="" onChange={e => setChecked(e.checked)} checked={checked}/>
        <label htmlFor="check" className="ml-2" style={{color: '#fff'}}>Remember Me</label>
        </div>
        <div>
        <Link 
            className={`text-end`} 
            variant='caption'
            style={{marginTop: '12px', color: '#fff'}}
            >
            Forgot Password?
        </Link>
        </div>
    </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Button label="LOGIN" type='submit' style={{ width: '100%' }}/>
        </div>
    </form>
    </Stack>
   
    </div>
    </Box>
    </>
 );
}

export default LoginComponent;
