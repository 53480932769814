import { Box, Breadcrumbs, Button, Step, StepLabel, Stepper, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Reusable/Loader';
import { useTheme } from '@emotion/react';
import { Toast } from 'primereact/toast';
import InfoIcon from '@mui/icons-material/Info';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FormCardComponent from './FormCardComponent';
import CourseContentComponent from './CourseContentComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessData } from '../../features/courseData/courseSlice';
import AssessmentComponent from './AssessmentComponent';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={isMobile ? 1 : 3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const AddCourseComponent = ({ }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading ,setLoading] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const toast = useRef(null);
 
     const showSuccess = (content) => {
         toast.current.show({severity:'success', summary: 'Success', detail:content, life: 3000});
     }
 
     const showError = (content) => {
         toast.current.show({severity:'error', summary: 'Error', detail: content, life: 3000});
     }

     const [value, setValue] = useState(0);
     const [formSubmitted1, setFormSubmitted1] = useState(false); // Track if the form has been submitted
     
     

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFormSubmit = (data) => {
        // Handle form submission, store success data, and navigate to next tab
        dispatch(setSuccessData(data));
        setFormSubmitted1(true); // Mark form as submitted
        setValue(1); // Switch to the next tab
      };

    const handleClick = (event) => {
        // event.preventDefault();
        console.info('You clicked a breadcrumb.');
      };

    return(
        <>
        {loading && <Loader open={loading} />}
        <Toast ref={toast} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/dashboard" onClick={handleClick}>
                Home
            </Link>
            <Link underline="hover" key="3" color="inherit" to="/courses" onClick={handleClick}>
                Course
            </Link>
            <Typography key="4" color="primary">
                Add Course
            </Typography>
        </Breadcrumbs>

        <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant={isMobile ? 'scrollable' : 'standard'} // Scrollable tabs on mobile
        scrollButtons="auto"
        sx={{
          flexGrow: 1,
          '& .MuiTab-root': {
            minWidth: isMobile ? 80 : 100, // Adjust min-width for tabs on mobile
            fontSize: '0.875rem',
            padding: '6px 12px',
          },
          
        }}
      >
        <Tab label={`Course Info`} id="tab-0" icon={<InfoIcon />} iconPosition="start" aria-controls="tabpanel-1" />
        <Tab label={`Content`} id="tab-1" icon={<FilePresentIcon />} iconPosition="start" aria-controls="tabpanel-1" disabled={!formSubmitted1} />
      </Tabs>

    <TabPanel value={value} index={0}>
      <FormCardComponent showSuccess={showSuccess} showError={showError} onSubmit={handleFormSubmit} formSubmitted1={formSubmitted1}  setLoading={setLoading}></FormCardComponent>
    </TabPanel>
    <TabPanel value={value} index={1}>
        <CourseContentComponent setValue={setValue}></CourseContentComponent>
    </TabPanel>

    
    
        </>
    );
}

export default AddCourseComponent;
