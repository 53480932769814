import { useTheme } from '@emotion/react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';

const ContentTypeCard = ({setShowContentForm, setSelectedType}) => {

    const contentType = [
        {title: 'Documents and Slides', type: 'document', desc: 'Add word docs, PDFs, images & presentations as quick reference tools.', icon: 'pi pi-file'},
        {title: 'Videos', type:'video' , desc: 'Videos are a great way to teach learners. You can also add pop up questions to increase interactivity & retention. ', icon: 'pi pi-video'},
        {title: 'Youtube Videos', type: 'youtube', desc: 'A seamless experience playing youtube videos without opening in a new tab', icon: 'pi pi-youtube'},
        {title: 'Web Links', type: 'link', desc: 'Add any link from the web that you want to suggest to your learners', icon: 'pi pi-link'}
    ];

    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardSelect = (ct) => {
        setSelectedCard(ct.title);
        setSelectedType(ct.type);
      };

      const handleContinue = () =>{
            setShowContentForm(true);
      }

    return(
        <>
        <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                {contentType.map((ct, index) => (
                    <Grid item xs={12} sm={6} md={3} key={`card_${index}`}>
                    <Card 
                    sx={{
                        height: '100%', // Ensure card takes full height available in the grid item
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        border: selectedCard === ct.title
                                    ? '2px solid blue'
                                    : '1px solid #ddd',
                      }}
                      onClick={() => handleCardSelect(ct)}
                      >
                        <CardMedia 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100px', // Set a fixed height for media section
                            
                          }}>
                            <i className={`${ct.icon}`} style={{fontSize: '2rem'}}></i>
                        </CardMedia>
                        <CardContent sx={{textAlign: 'center'}}>
                            <Typography variant='h5'>{ct.title}</Typography>
                            <Typography variant='body1'>{ct.desc}</Typography>
                        </CardContent>
                    </Card>
                    </Grid>
                ))}
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button 
                        variant='contained'
                        color="primary"
                        sx={{ marginTop: '20px' }}
                        disabled={!selectedCard}
                        onClick={handleContinue}
                        > 
                        Continue
                        </Button>
                    </Box>
                </DialogActions>
        </>
    );

}

export default ContentTypeCard;
