import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Reusable/Loader';
import { Box, Button, Card, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import assessmentStyles from './assessmentStyles.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CREATE_ASSESSMENT } from '../../constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ResizableTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      '& textarea': {
        resize: 'both', // Allow resizing both horizontally and vertically
        overflow: 'auto', // Show scrollbars when necessary
        minHeight: '100px', // Set a minimum height for the textarea
        width: '100%'
      },
    },
  });

const FormCardComponent = ({  showSuccess, showError, setLoading }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [assessmentName, setAssessmentName] = useState('');
    const [assessmentNameError, setAssessmentNameError] = useState('');

    const handleAssessmentName = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                    setAssessmentName('');
                    setAssessmentNameError('Assessment Name is required.');
            } else {
                    setAssessmentName(value);
                    setAssessmentNameError('');
            }
    };

    const [assessmentDescription, setAssessmentDescription] = useState('');
    const [assessmentDescriptionError, setAssessmentDescriptionError] = useState('');

    const [isSequential, setIsSequential] = useState(false);
    
    const handleCheckboxChange = (event) => {
        setIsSequential(event.target.checked);
    };

    const [duration, setDuration] = useState('');
    const [durationError, setDurationError] = useState('');

    const handleDurationChange = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setDuration('');
            setDurationError('Duration is required.');
        } else {
            if (/^\d*$/.test(value)) {
                setDuration(value);
                setDurationError('');
            } else{
                setDuration('');
                setDurationError('Only Numeric Value is allowed.');
            }
        }
    };

    const [passScore, setPassScore] = useState('');
    const [passScoreError, setPassScoreError] = useState('');

    const handlePassScore = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setPassScore('');
            setPassScoreError('Pass Score is required.');
        } else {
            if (/^\d*$/.test(value)) {
                setPassScore(value);
                setPassScoreError('');
            } else{
                setPassScore('');
                setPassScoreError('Only Numeric Value is allowed.');
            }
        }
    };

    const [noOfAttempts, setNoOfAttempts] = useState('');
    const [noOfAttemptsError, setNoOfAttemptsError] = useState('');

    const handleNoOfAttempts = (e) => {
        const value = e.target.value;

        // Check if the value is a number and greater than or equal to 1
        if (!value || isNaN(value) || Number(value) < 1) {
        setNoOfAttemptsError('The number of attempts must be at least 1');
        } else {
        setNoOfAttemptsError('');
        }

        // Update the state regardless of whether it's valid for display
        setNoOfAttempts(value);
    };


    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileError, setFileError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Process only the first file
        if (file) {
          const allowedExtensions = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      
          // Check file type
          if (!allowedExtensions.includes(file.type)) {
            showError('Only .xls and .xlsx files are allowed.');
            return;
          }
      
          // Check file size
          if (file.size > maxSize) {
            showError('File size exceeds 2MB.');
            return;
          }
      
          // Store the uploaded file
          setUploadedFile(file);
      
          // Reset the input field to allow re-uploading the same file
          event.target.value = null;
        }
      };
      
      const handleRemoveFile = () => {
        setUploadedFile(null); // Reset the file
      };
      

      const handleReset = (e) => {
        setAssessmentName('');
        setAssessmentDescription('');
        setIsSequential(false);
        setPassScore('');
        setDuration('');
        setNoOfAttempts('');
        setUploadedFile(null);
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const fieldErrors = {};

        let isValid = true;

        if (assessmentName.trim() === '') {
            fieldErrors.assessmentName = 'Assessment Name is required.';
            setAssessmentNameError('Assessment Name is required.');
            isValid = false;
        }

        if (assessmentDescription.trim() === '') {
            fieldErrors.assessmentDescription = 'Assessment Description is required.';
            setAssessmentDescriptionError('Assessment Description is required.');
            isValid = false;
        }

        if (passScore.trim() === '') {
            fieldErrors.passScore = 'Pass Score is required.';
            setPassScoreError('Pass Score is required.');
            isValid = false;
        }

        if (duration.trim() === '') {
            fieldErrors.duration = 'Duration is required.';
            setDurationError('Duration is required.');
            isValid = false;
        }

        if (noOfAttempts.trim() === '') {
            fieldErrors.noOfAttempts = 'No of Attempts is required.';
            setNoOfAttemptsError('No of Attempts is required.');
            isValid = false;
        }

        if (!uploadedFile) {
            fieldErrors.uploadedFile = 'File is required.';
            setFileError('File is required.');
            isValid = false;
        }

        if (isValid) {
            const formData = new FormData();
            formData.append("Name", assessmentName);
            formData.append("Description", assessmentDescription);
            formData.append("IsSequential", isSequential);
            formData.append("PassScore", passScore);
            formData.append("NoOfAttempts", noOfAttempts);
            formData.append("Duration", duration);
            formData.append("file", uploadedFile);

            axios.post(CREATE_ASSESSMENT, formData,)
            .then(response => {         
                // After successful submission
                // handleClose();
                setLoading(false);
                showSuccess(response.data.message);
                setTimeout(() => {
                    navigate('/assessment');
                }, 1000); // Delay for 1 seconds
            }).catch(error => {
                if (error.response) {
                const { data } = error.response;
                if (data.errors) {
                const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                    `${key}: ${messages.join(', ')}`
                ));
                    showError(formattedErrors);
                    setLoading(false);
                }else {
                    showError(data.title || 'An error occurred.');
                    setLoading(false);
                }
                } else {
                    showError('Intenal Server Error.');
                    setLoading(false);
                }
            });

        } else{
            console.log('Form submission blocked due to validation errors.');
            showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
            setLoading(false);
        }
      };

    return(
        <>
        <Card className={`${assessmentStyles.form_card_style}`} style={{marginTop: '1rem'}}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={isMobile? 0 : 2} p={isMobile ? 0 : 3}>
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                id="outlined-select-currency"
                                label="Assessment Name"
                                size='small'
                                name='name'
                                fullWidth
                                value={assessmentName}
                                onChange={(e) => {
                                    handleAssessmentName(e)
                                }}
                                required
                                error={!!assessmentNameError}
                                helperText={assessmentNameError}
                                inputProps={{ maxLength: 100 }}
                                >
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} xl={6}>
                            <ResizableTextField
                                label={'Description'}
                                value={assessmentDescription}
                                onChange={(e)=>{ if (e.target.value.length <= 500) {
                                    setAssessmentDescription(e.target.value)
                                }}}
                                multiline
                                fullWidth
                                variant="outlined" // Use 'outlined' for better border control
                                required
                                error={!!assessmentDescriptionError}
                                helperText={assessmentDescriptionError}
                                />
                                <Typography variant="body2" color={assessmentDescription.length > 500 ? 'error' : 'text.secondary'} sx={{marginLeft: '7px'}}>
                                    {assessmentDescription.length}/500
                                </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={isSequential} 
                                        onChange={handleCheckboxChange} 
                                    />
                                }
                                label="Is Sequential?"
                                labelPlacement="start"
                            />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-title"
                                label="Pass Score"
                                size='small'
                                name='passScore'
                                fullWidth
                                value={passScore}
                                onChange={handlePassScore}
                                required
                                error={!!passScoreError}
                                helperText={passScoreError}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-title"
                                label="Duration"
                                size='small'
                                name='duration'
                                fullWidth
                                value={duration}
                                onChange={handleDurationChange}
                                required
                                error={!!durationError}
                                helperText={durationError}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6}>
                            <TextField
                                id="outlined-title"
                                label="No. of Attempts"
                                size='small'
                                name='noOfAttempts'
                                fullWidth
                                value={noOfAttempts}
                                onChange={handleNoOfAttempts}
                                required
                                error={!!noOfAttemptsError}
                                helperText={noOfAttemptsError || 'Minimum value is 1'}
                                InputProps={{ inputProps: { min: 1 } }} // HTML5 validation for min value
                            />
                        </Grid>

                       {/* Upload Excel File */}
                        <Grid item xs={12} md={6} xl={6}>
                        <div className={`px-2`}>
                            <Typography variant="body2">Upload Excel File <span style={{ color: 'red' }}>*</span></Typography>

                            {!uploadedFile ? (
                            <>
                                <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                startIcon={<AddIcon />}
                                sx={{
                                    width: '60%',
                                    marginTop: '8px',
                                    textTransform: 'none',
                                    border: '2px dashed black',
                                    backgroundColor: 'lightgray',
                                    '&:hover': {
                                    backgroundColor: 'gray',
                                    },
                                }}
                                required
                                error={!!fileError}
                                >
                                Upload Excel File
                                <input
                                    accept=".xls, .xlsx"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                </Button>
                                <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                Only .xls and .xlsx files are allowed. Size limit 2MB
                                </p>
                                {fileError && (
                                <FormHelperText error>{fileError}</FormHelperText>
                                )}
                            </>
                            ) : (
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                {uploadedFile.name} ({(uploadedFile.size / 1024).toFixed(2)} KB)
                                </Typography>
                                <IconButton
                                onClick={handleRemoveFile}
                                aria-label="delete"
                                size="small"
                                >
                                <DeleteIcon />
                                </IconButton>
                            </div>
                            )}
                        </div>
                        </Grid>


                    </Grid>

                    <div className={`float-right m-2`}>
                        <Button onClick={handleReset} variant="outlined" color="error">Reset</Button>                    
                        <Button type="submit" onClick={handleSubmit} variant='contained' sx={{marginLeft: '0.5rem'}}>Save</Button>
                    </div>
                </Box>
        </Card>
        </>
    );

}

export default FormCardComponent;