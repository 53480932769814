import { useTheme } from '@emotion/react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState, useContext ,useEffect, useRef} from 'react';
import courseStyles from './courseStyles.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { CREATE_COURSE_CONTENT, GET_EDIT_COURSE } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setEditData } from '../../features/courseData/courseSlice';

const ContentUploadFormComponent = ({setOpen, setShowContentForm,setRunEffect, selectedType, showSuccess, showError, setLoadingState, setLoading, courseId}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector((state) => state.auth.user);

    const successData =  useSelector((state) => state.courseData.successData);

    const dispatch = useDispatch();

    const [contentTitle, setContentTitle] = useState('');
    const [contentTitleError, setContentTitleError] = useState('');

    const handleContentTitleChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                    setContentTitle('');
                    setContentTitleError('Title is required.');
            } else {
                    setContentTitle(value);
                    setContentTitleError('');
            }
      };

    const [contentDescription, setContentDescription] = useState('');
    const [contentDescriptionError, setContentDescriptionError] = useState('');

    const handleContentDescriptionChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                    setContentDescription('');
                    setContentDescriptionError('Description is required.');
            } else {
                    setContentDescription(value);
                    setContentDescriptionError('');
            }
      };

      const [noOfPages, setNoOfPages] = useState('');
      const [noOfPagesError, setNoOfPagesError] = useState('');

      const handleNoOfpagesChange = (e) => {
        const value = e.target.value;
            if (value.trim() === '') {
                setNoOfPages('');
                setNoOfPagesError('No of Pages is required.');
            } else {
                if (/^\d*$/.test(value)) {
                    setNoOfPages(value);
                    setNoOfPagesError('');
                } else{
                    setNoOfPages('');
                    setNoOfPagesError('Only Numeric Value is allowed.');
                }
            }
      }

      const [duration, setDuration] = useState('');
      const [durationError, setDurationError] = useState('');

      const handleDurationChange = (e) => {
        const value = e.target.value;
    
        // Check if the value is empty
        if (value.trim() === '') {
            setDuration('');
            setDurationError('Duration is required.');
        } 
        // Allow only numeric values with a maximum of 3 digits
        else if (/^\d{0,3}$/.test(value)) {
            setDuration(value);
            setDurationError('');
        } 
        // Show error if input contains non-numeric characters
        else {
            setDurationError('Only 3 characters and numeric values are allowed.');
        }
    };

    

    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        
        if (selectedFile) {
            const allowedExtensions = ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
            const maxSize = 10 * 1024 * 1024; // 10MB in bytes
            // Check file type
            if (!allowedExtensions.includes(selectedFile.type)) {
                showError('Only PDF, JPG, JPEG, PNG, DOC, DOCX, PPT and PPTX files are allowed.');
                return;
            }

            // Check file size
            if (selectedFile.size > maxSize) {
                showError('File size exceeds 10MB.');
                return;
            }

            setFile(selectedFile);
            setFileError('');

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        }
    };

    const handleVideoFileChange = (event) => {
        const selectedFile = event.target.files[0];
        
        if (selectedFile) {
            const allowedExtensions = ['video/mp4'];
            const maxSize = 100 * 1024 * 1024; // 100MB in bytes
           // Check file type
           if (!allowedExtensions.includes(selectedFile.type)) {
            showError('Only MP4 files are allowed.');
            return;
            }

            // Check file size
            if (selectedFile.size > maxSize) {
                showError('File size exceeds 10MB.');
                return;
            }

            setFile(selectedFile);
            setFileError('');

            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };


    const [youtubeLink, setYoutubeLink] = useState('');
    const [youtubeLinkError, setYoutubeLinkError] = useState('');

    const handleYoutubeLinkChange = (e) => {
        const value = e.target.value;

        if (value.trim() === '') {
            setYoutubeLink('');
            setYoutubeLinkError('Link is required.');
        } else {
            setYoutubeLink(value);
            setYoutubeLinkError('');
        }
      }

      const [webLink, setWebLink] = useState('');
      const [webLinkError, setWebLinkError] = useState('');

        const handleWebLinkChange = (e) => {
            const value = e.target.value;

            if (value.trim() === '') {
                setWebLink('');
                setWebLinkError('Link is required.');
            } else {
                setWebLink(value);
                setWebLinkError('');
            }
        }

        const handleSubmitCLick = (e) => {
            e.preventDefault();
            setLoadingState(true);        
            let isValid = true;
            const fieldErrors = {};

            if (contentTitle.trim() === '') {
                fieldErrors.contentTitle = 'Title is required.';
                setContentTitleError('Title is required.');
                isValid = false;
            }

            if (contentDescription.trim() === '') {
                fieldErrors.contentDescription = 'Description is required.';
                setContentDescriptionError('Description is required.');
                isValid = false;
            }

            if(selectedType === 'document'){
                if (noOfPages === '') {
                    fieldErrors.noOfPages = 'No of Pages is required.';
                    setNoOfPagesError('No of Pages is required.');
                    isValid = false;
                }
            }

            if (duration === '') {
                fieldErrors.duration = 'Duration is required.';
                setDurationError('Duration is required.');
                isValid = false;
            }


            if(isValid){
            
                const formData = new FormData();
                formData.append("UserID", user.employeeID); //Login user id
                formData.append("CourseID", successData?.data?.id || courseId);
                formData.append("CourseContentID", '');
                formData.append("Title", contentTitle);
                formData.append("Description", contentDescription);
                formData.append("ContentType", selectedType);
                formData.append("NoOfPages", noOfPages == '' ? 0 : noOfPages);
                formData.append("DisplayOrder", 1);
                formData.append("Duration", duration);
                formData.append("File", file);
                formData.append("UpdatedBy", user.employeeID);
                formData.append("ExternalLink", selectedType === 'youtube' ? youtubeLink : webLink);
                
    
            axios.post(CREATE_COURSE_CONTENT,formData)
            .then(response => {
                  showSuccess(response.data.message);
                  setLoadingState(false);
                  setShowContentForm(false);
                  setRunEffect(prev => !prev); 
                  setOpen(false);
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;
                    if (data.errors) {
                    const formattedErrors = Object.entries(data.errors).map(([key, messages]) => (
                        `${key}: ${messages.join(', ')}`
                    ));
                        showError(formattedErrors);
                        setLoadingState(false);
                    }else {
                        showError(data.title || 'An error occurred.');
                        setLoadingState(false);
                    }
                } else {
                    showError('Intenal Server Error.');
                    
                }
                setOpen(false);
            });
            }
            else{
                    console.log('Form submission blocked due to validation errors.');
                    setLoadingState(false);
                    showError('Please fill all mandatory fields: ' + Object.values(fieldErrors).join(' '));
                }
        };

        

    return(
        <>
        <DialogContent>
        <Card className={`${courseStyles.form_card_style} mt-2`}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3} sx={{alignItems: 'center'}}>
                    <Grid item xs={12} md={12} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Title"
                            size='small'
                            name='title'
                            fullWidth
                            value={contentTitle}
                            onChange={handleContentTitleChange}
                            required
                            error={!!contentTitleError}
                            helperText={contentTitleError}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                        <TextField
                            id="outlined-objective"
                            label="Description"
                            size='small'
                            name='objective'
                            multiline
                            rows={4}
                            fullWidth
                            value={contentDescription}
                            onChange={handleContentDescriptionChange}
                            required
                            error={!!contentDescriptionError}
                            helperText={contentDescriptionError}
                            inputProps={{ maxLength: 500 }}
                        />
                    </Grid>

                    {selectedType === 'document' && (<Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Number Of Pages"
                            size='small'
                            name='noofpages'
                            fullWidth
                            value={noOfPages}
                            onChange={handleNoOfpagesChange}
                            required
                            error={!!noOfPagesError}
                            helperText={noOfPagesError}
                        />
                    </Grid>)}

                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Duration (in Minutes)"
                            size='small'
                            name='duration'
                            fullWidth
                            value={duration}
                            onChange={handleDurationChange}
                            required
                            error={!!durationError}
                            helperText={durationError}
                            inputProps={{
                                maxLength: 3, // Restrict to three characters
                            }}
                        />
                    </Grid>


                    {selectedType === 'document' && (<Grid item xs={12} md={6} xl={6}>
                        <div className={`px-2`}>
                            <Typography variant="body2">
                                Upload Document <span style={{ color: 'red' }}>*</span>
                            </Typography>

                            {!file ? (
                                <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                    >
                                        Upload Document
                                        <input
                                            accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .ppt, .pptx"
                                            type="file"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                        PDF, JPG, JPEG, PNG, DOC, DOCX, PPT and PPTX files are allowed. Size Limit 10MB
                                    </p>
                                    {fileError && (
                                        <FormHelperText error>{fileError}</FormHelperText>
                                    )}
                                </>
                            ) : (
                                <div>
                                    <Typography variant="body2">{file.name}</Typography>
                                    {file.type.startsWith('image/') && (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="Uploaded"
                                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                                        />
                                    )}
                                    <IconButton
                                        onClick={handleRemoveFile}
                                        aria-label="delete"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    </Grid>)}

                    {selectedType === 'video' && (
                        <Grid item xs={12} md={6} xl={6}>
                        <div className={`px-2`}>
                            <Typography variant="body2">
                                Upload Video <span style={{ color: 'red' }}>*</span>
                            </Typography>
            
                            {!file ? (
                                <>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: '60%',
                                            marginTop: '8px',
                                            textTransform: 'none',
                                            border: '2px dashed black',
                                            backgroundColor: 'lightgray',
                                            '&:hover': {
                                                backgroundColor: 'gray',
                                            },
                                        }}
                                    >
                                        Upload Video
                                        <input
                                            accept=".mp4"
                                            type="file"
                                            onChange={handleVideoFileChange}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    <p style={{ color: 'gray', fontSize: '0.875rem' }}>
                                        MP4 files are allowed. Size Limit 100MB
                                    </p>
                                    {fileError && (
                                        <FormHelperText error>{fileError}</FormHelperText>
                                    )}
                                </>
                            ) : (
                                <div>
                                    <Typography variant="body2">{file.name}</Typography>
                                    {file.type.startsWith('video/') && (
                                        <video
                                            controls
                                            src={URL.createObjectURL(file)}
                                            style={{ maxWidth: '300px', maxHeight: '200px' }}
                                        />
                                    )}
                                    <IconButton
                                        onClick={handleRemoveFile}
                                        aria-label="delete"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    </Grid>
                    )}

                    {selectedType === 'youtube' && (<Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Youtube Link"
                            size='small'
                            name='youtubelink'
                            fullWidth
                            value={youtubeLink}
                            onChange={handleYoutubeLinkChange}
                            required
                            error={!!youtubeLinkError}
                            helperText={youtubeLinkError}
                        />
                    </Grid>)}

                    {selectedType === 'link' && (<Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-title"
                            label="Web Link"
                            size='small'
                            name='weblink'
                            fullWidth
                            value={webLink}
                            onChange={handleWebLinkChange}
                            required
                            error={!!webLinkError}
                            helperText={webLinkError}
                        />
                    </Grid>)}

                </Grid>
            </Box>
        </Card>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' onClick={handleSubmitCLick}>Submit</Button>
        </DialogActions>
        </>
    );

}

export default ContentUploadFormComponent;
